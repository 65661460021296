import { CommonModule } from '@angular/common';
import { NgModule, ModuleWithProviders } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { HttpResponseInterceptor } from './http.interceptor';

@NgModule({
  imports: [CommonModule, HttpClientModule]
})
export class HttpServiceModule {
  static forRoot(): ModuleWithProviders<any> {
    return {
      ngModule: HttpServiceModule,
      providers: [
           { provide: HTTP_INTERCEPTORS, useClass: HttpResponseInterceptor, multi: true }
      ]
    };
  }
}
