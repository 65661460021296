<div class="row g-2">
    <div class="col-md-12">
        <div class="card h-100">
            <div class="card-body">
                <div class="row mb-3 gx-3 align-items-center">
                    <div class="col-md-4">
                        <div class="row align-items-center gx-2">
                            <div class="col-md-auto">
                                <div class="form-check mb-0 form-check-inline me-0">
                                    <input class="form-check-input" type="checkbox" id="inlineCheckbox1"
                                        value="option1">
                                    <label class="form-check-label mb-0" for="inlineCheckbox1">Exclude Branches?</label>
                                </div>
                            </div>
                            <div class="col-md">
                                <input type="text" class="form-control" placeholder="Search by Broker Name or Code">
                            </div>
                        </div>
                    </div>
                    <div class="col-md-2">
                        <kendo-combobox class="w-100" placeholder="Agent Verified?">
                        </kendo-combobox>
                    </div>
                    <div class="col-md-auto">
                        <div class="mb-0 form-check form-check-inline">
                            <input class="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1">
                            <label class="form-check-label mb-0" for="inlineCheckbox1">Active Agents Only</label>
                        </div>
                        <div class="mb-0 form-check form-check-inline">
                            <input class="form-check-input" type="checkbox" id="inlineCheckbox2" value="option2">
                            <label class="form-check-label mb-0" for="inlineCheckbox2">Inactive Agents Only</label>
                        </div>
                    </div>
                    <div class="col-md-auto col-auto">
                        <button type="button" class="btn btn-primary">
                            Search
                        </button>
                        <button type="button" class="btn btn-secondary ms-2">
                            Reset
                        </button>
                    </div>
                    <div class="col-md-auto col-auto ms-auto ">
                        <button class="btn btn-sm btn-outline-secondary w-100">Total Count: 7</button>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <kendo-grid [data]="gridData" [kendoGridBinding]="gridData" [sortable]="true">
                            <kendo-grid-column field="agentCode" title="Agent Code" [headerStyle]="{ width: '100px' }"
                            [style]="{ width: '100px' }">
                                <ng-template kendoGridCellTemplate let-dataItem>
                                    <a>{{dataItem?.agentCode}}</a>
                                </ng-template>
                            </kendo-grid-column>
                            <kendo-grid-column field="agentName" title="Agent Name"></kendo-grid-column>
                            <kendo-grid-column field="agentContact" title="Agent Contact" [headerStyle]="{ width: '120px' }"
                            [style]="{ width: '120px' }"></kendo-grid-column>
                            <kendo-grid-column field="agentLive" title="Agent Live" [headerStyle]="{ width: '80px' }"
                            [style]="{ width: '80px' }"></kendo-grid-column>
                            <kendo-grid-column field="verified" title="Verification Status" [headerStyle]="{ width: '130px' }"
                            [style]="{ width: '130px' }">
                                <ng-template kendoGridCellTemplate let-dataItem>
                                    <div class="badge bg-success" *ngIf="dataItem.verifiedID ===1">
                                        {{dataItem?.verified}}
                                    </div>
                                    <div class="badge bg-info" *ngIf="dataItem.verifiedID ===2">
                                        {{dataItem?.verified}}
                                    </div>
                                    <div class="badge bg-danger" *ngIf="dataItem.verifiedID ===3">
                                        {{dataItem?.verified}}
                                    </div>
                                </ng-template>
                            </kendo-grid-column>
                            <kendo-grid-column field="complianceOfficer" title="Compliance Officer?" [headerStyle]="{ width: '140px' }"
                            [style]="{ width: '140px' }">
                                <ng-template kendoGridCellTemplate let-dataItem>
                                    <span class="d-flex material-symbols-outlined text-success">
                                        done
                                    </span>
                                </ng-template>
                            </kendo-grid-column>
                            <ng-template kendoGridDetailTemplate let-dataItem>
                                <section>
                                    <p><strong>Agent Address:</strong> {{ dataItem.agentAddress }}</p>
                                    <p><strong>Legal Name:</strong> {{ dataItem.legalName }}</p>
                                    <p><strong>CBI:</strong> {{ dataItem.cbi }}</p>
                                    <p><strong>Entity Type:</strong> {{ dataItem.entityType }}</p>
                                    <p><strong>CRO:</strong> {{ dataItem.cro }} </p>
                                </section>
                            </ng-template>
                        </kendo-grid>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>