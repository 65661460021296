import { Component, Input, Optional } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { InsuranceService } from '@app/services/insurance.service';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationDialogService } from '@shared/components/confirmation-dialog/confirmation-dialog.service';
import { getExactDate, getExactDateWithoutTime } from '@shared/utility';
import { ToasterService } from '@shared/utility/toaster.service';
import { DatePipe } from '@angular/common';
import { Subscription } from 'rxjs';
import { TransactionDetailComponent } from '../product/transaction-detail/transaction-detail.component';
enum PaymentStatus {
  BrokerPaid = 'Broker Paid',
  SubBrokerPaid = 'Sub-Broker Paid',
  InsurerPaid = 'Insurer Paid',
  OfficeAccountPaid = 'Office Account Paid',
  None = 'None'
}

@Component({
  selector: 'app-payment-info',
  templateUrl: './payment-info.component.html',
  styleUrl: './payment-info.component.css'
})
export class PaymentInfoComponent {
  transactionList: any;
  acPaymentId: 0;
  paymentDetail: any;
  backBtnPath :any;
  backBtnText :any;
  subscription = new Subscription();
  transactionForm: FormGroup;
  isEdit = false;
  gridTitle = '';
  amountLabel = '';
  amountReconcil = 0;
  isSystemAgent = false;
  isupdated = false;
  constructor(public datepipe: DatePipe, private fb: FormBuilder, private insuranceService: InsuranceService, private activatedRoute: ActivatedRoute, private toasterService: ToasterService, private confirMationDialogService: ConfirmationDialogService,
    private modalService: NgbModal,
    private router: Router) { }

  ngOnInit(): void {
    var userDetail = JSON.parse(window.sessionStorage.getItem('authentication'));
    this.isSystemAgent = userDetail.isSystemAgent;
    if (this.activatedRoute.snapshot.queryParams['data']) {
      this.activatedRoute.queryParams.subscribe(params => {
        const decryptparams = this.insuranceService.decryptAndParseQueryString(params.data);
        if (decryptparams !== undefined && decryptparams !== null
          && decryptparams.backBtnText !== null && decryptparams.backBtnText !== undefined && decryptparams.backBtnText !== 0) {
          this.backBtnText = decryptparams.backBtnText;
        }
        if (decryptparams !== undefined && decryptparams !== null
          && decryptparams.backBtnPath !== null && decryptparams.backBtnPath !== undefined && decryptparams.backBtnPath !== 0) {
          this.backBtnPath = decryptparams.backBtnPath;
        }
        if (decryptparams !== undefined && decryptparams !== null
          && decryptparams.acPaymentId !== null && decryptparams.acPaymentId !== undefined && decryptparams.acPaymentId !== 0) {
          this.acPaymentId = decryptparams.acPaymentId;
          this.getPaymentDetail();
        } else {
          this.acPaymentId = 0;
        }
      });
    } else {
      this.acPaymentId = 0;
    }
  }

  getPaymentDetail() {
    var obj = Object.assign({});
    obj.pageIndex = 0;
    obj.acPaymentId = this.acPaymentId;
    this.subscription.add(
      this.insuranceService.GetPaymentAndTransactionsDetailsReport(obj).subscribe((data: any): void => {
        if (data != null) {
          this.paymentDetail = data?.items[0];
          if (this.paymentDetail != null && this.paymentDetail != undefined) {
            if (this.paymentDetail?.transactions?.length > 0) {
              this.amountReconcil = this.paymentDetail?.transactions.reduce((sum, x) =>
                sum + (+x.payment ?? 0),
                0
              );
              this.paymentDetail?.transactions.push({
                payment: this.amountReconcil,
                actualPaid: this.paymentDetail?.transactions.reduce((currentValue, x) => +(x.actualPaid ?? 0) + +currentValue, 0),
                writeOff: this.paymentDetail?.transactions.reduce((currentValue, x) => +(x.writeOff ?? 0) + +currentValue, 0),
                isTotal: true
              });
              this.paymentDetail.transactions.sort((a, b) => b.acTransactionId - a.acTransactionId);
              // this.paymentDetail.transactions = .paymentDetail.transactions.sort((a, b) => {
              //   return a.acTransactionId - b.acTransactionId;
              // });
            }
          }
          this.setlabel();
        }
      }));
  }

  setlabel() {
    if (this.paymentDetail?.paymentTypeId == 1) {
      this.gridTitle = 'Broker Payment Transactions';
      this.amountLabel = 'Paid To Optis';
    }
    else if (this.paymentDetail?.paymentTypeId == 2) {
      this.gridTitle = 'SubBroker payment transactions';
      this.amountLabel = 'Paid to Sub Broker';
    }
    else if (this.paymentDetail?.paymentTypeId == 3) {
      this.gridTitle = 'Insurer payment transactions';
      this.amountLabel = 'Paid to Insurer';
    }
    else if (this.paymentDetail?.paymentTypeId == 4) {
      this.gridTitle = 'Section 30 transfer transactions ';
      this.amountLabel = 'Transferred to Office';
    }  
  }

  openTransactions(dataItem: any) {
    const modalref = this.modalService.open(TransactionDetailComponent, {
      backdrop: "static",
      windowClass: "",
      centered: true,
      size: "lg",
    });
    modalref.componentInstance.transactionId = dataItem.acTransactionId;
    modalref.componentInstance.proposer = dataItem.proposer;
    modalref.componentInstance.policyNo = dataItem.policyNumber;
  }

  openDetail(paymentDetail: any) {
    let encryptedqueryparams: {accontType: any,bankId:number};
    encryptedqueryparams = {accontType:'CL',bankId : paymentDetail?.bankID}
    const url = this.router.serializeUrl(
      this.router.createUrlTree(["report/bank-payment-detail"], {
        queryParams: {
          data: this.insuranceService.encryptData(encryptedqueryparams),
        },
        relativeTo: this.activatedRoute.root,
      })
    );
    window.open(url, '_blank');
  }

  back() {
    this.router.navigate([this.backBtnPath]);
  }
}
