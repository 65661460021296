import { Component, OnInit } from '@angular/core';

import { Observable } from 'rxjs';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss']
})
export class NavComponent implements OnInit {

   

  constructor(
  ) {}

  ngOnInit() {
  }

  toggleTheme(checked: boolean) {
  }
}
