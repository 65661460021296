import { Component } from '@angular/core';

@Component({
  selector: 'app-agent-list',
  templateUrl: './agent-list.component.html',
  styleUrls: ['./agent-list.component.scss']
})
export class AgentListComponent {

  public gridData = [
    {
      agentCode: 'BS07010',
      agentName: "Fitzgerald and Harnett Insurance brokers",
      agentContact: "018787586",
      agentLive: 'Yes',
      verified: 'Signed off',
      verifiedID: 1,
      complianceOfficer: '',
      agentAddress:'The Arc Drinagh Business Park Rosslare Road Co. Wexford, Y35 RR92',
      legalName:'Arthur J. Gallagher Insurance Brokers (Ireland) Limited',
      cbi:'C1645',
      entityType:'Limited Company',
      cro:'22380',
    },
    {
      agentCode: 'BS07010',
      agentName: "AOR Insurances (Cavan) Limited",
      agentContact: "018787586",
      agentLive: 'Yes',
      verified: 'Signed off',
      verifiedID: 1,
      complianceOfficer: '',
      agentAddress:'The Arc Drinagh Business Park Rosslare Road Co. Wexford, Y35 RR92',
      legalName:'Arthur J. Gallagher Insurance Brokers (Ireland) Limited',
      cbi:'C1645',
      entityType:'Limited Company',
      cro:'22380',
    },
    {
      agentCode: 'BS07010',
      agentName: "Wright Group Brokers Ltd t/a Wright Insurance Brokers",
      agentContact: "018787586",
      agentLive: 'Yes',
      verified: 'Verified by Optis',
      verifiedID: 2,
      complianceOfficer: '',
      agentAddress:'The Arc Drinagh Business Park Rosslare Road Co. Wexford, Y35 RR92',
      legalName:'Arthur J. Gallagher Insurance Brokers (Ireland) Limited',
      cbi:'C1645',
      entityType:'Limited Company',
      cro:'22380',
    },
    {
      agentCode: 'BS07010',
      agentName: "Arachas Corporate Brokers Limited",
      agentContact: "018787586",
      agentLive: 'Yes',
      verified: 'Signed off',
      verifiedID: 1,
      complianceOfficer: '',
      agentAddress:'The Arc Drinagh Business Park Rosslare Road Co. Wexford, Y35 RR92',
      legalName:'Arthur J. Gallagher Insurance Brokers (Ireland) Limited',
      cbi:'C1645',
      entityType:'Limited Company',
      cro:'22380',
    },
    {
      agentCode: 'BS07020',
      agentName: "Goggin Insurance Brokers Limited",
      agentContact: "018787586",
      agentLive: 'Yes',
      verified: 'Signed off',
      verifiedID: 1,
      complianceOfficer: '',
      agentAddress:'The Arc Drinagh Business Park Rosslare Road Co. Wexford, Y35 RR92',
      legalName:'Arthur J. Gallagher Insurance Brokers (Ireland) Limited',
      cbi:'C1645',
      entityType:'Limited Company',
      cro:'22380',
    },
    {
      agentCode: 'BS07030',
      agentName: "Deanspoint Insurance Brokers Ltd t/a Abbey Murphy Insurance",
      agentContact: "018787586",
      agentLive: 'Yes',
      verified: 'Pending',
      verifiedID: 3,
      complianceOfficer: '',
      agentAddress:'The Arc Drinagh Business Park Rosslare Road Co. Wexford, Y35 RR92',
      legalName:'Arthur J. Gallagher Insurance Brokers (Ireland) Limited',
      cbi:'C1645',
      entityType:'Limited Company',
      cro:'22380',
    },
    {
      agentCode: 'BS07010',
      agentName: "Andrew Bermingham t/a Bermingham & Co. Insurance Brokers",
      agentContact: "018787586",
      agentLive: 'Yes',
      verified: 'Signed off',
      verifiedID: 1,
      complianceOfficer: '',
      agentAddress:'The Arc Drinagh Business Park Rosslare Road Co. Wexford, Y35 RR92',
      legalName:'Arthur J. Gallagher Insurance Brokers (Ireland) Limited',
      cbi:'C1645',
      entityType:'Limited Company',
      cro:'22380',
    },
  ];
}
