import { Component } from '@angular/core';

@Component({
  selector: 'app-silent-renew',
  templateUrl: './silent-renew.component.html',
  styleUrl: './silent-renew.component.css'
})
export class SilentRenewComponent {

}
