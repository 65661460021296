import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AboutInsurerComponent } from '@app/dashboard/about-insurer/about-insurer.component';
import { InsuranceService } from '@app/services/insurance.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationDialogService } from '@shared/components/confirmation-dialog/confirmation-dialog.service';
import { ToasterService } from '@shared/utility/toaster.service';
import { data } from 'jquery';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-agent-dashboard',
  templateUrl: './agent-dashboard.component.html',
  styleUrls: ['./agent-dashboard.component.scss']
})
@AutoUnsubscribe({ arrayName: "subscription" })
export class AgentDashboardComponent implements OnInit {
  private subscription = new Subscription();

  activePolicy: number;
  referredPolicy: number;
  clearReferredPolicy :number;
  renewDateCount: number;

  activePolicyData: any;
  referredPolicyPolicyData: any;
  clearReferredPolicyData: any;
  renewDateCountData: any;
  activePolicyDataClone: any;
  
  phoneNumber: string;
  contactTime: string;activeInsurer: any;
  announcementList: [];

  constructor(private modalService: NgbModal,
    private insuranceService: InsuranceService,
    private toasterService: ToasterService,
    private confirMationDialogService: ConfirmationDialogService,    
    private router: Router) {
  }
  ngOnInit(): void {
    this.GetDashboardSummary();
    this.GetCompanyContact();
    this.GetActiveAnnouncement();
    this.GetActiveInsurer();
  }
  ngOnDestroy() {

  }

  GetActiveInsurer() {
    this.insuranceService.GetActiveInsurer().subscribe((response: any): void => {
      this.activeInsurer = response;
    });
  }

  GetActiveAnnouncement() {
    this.insuranceService.GetActiveNotification().subscribe((response: any): void => {
      if (response && response?.length > 0) {
        this.announcementList = response;
      }
    });
  }

  GetDashboardSummary(): void {
    this.insuranceService.GetDashboardSummary().subscribe((data:any): void => {
      this.activePolicyData = data.activePolicy;
      this.referredPolicyPolicyData = data.referredPolicy;
      this.clearReferredPolicyData = data.clearReferredPolicy;
      this.renewDateCount = data.renewDateCount;
      this.activePolicyDataClone = Object.assign([], data);
    });
  }

  navigateToPolicyStatus(statusId: number): void {
    const encryptedQueryParams = this.insuranceService.encryptData({ policystatusId: statusId });
    this.router.navigate(['product/search-policy'], { queryParams: { data: encryptedQueryParams } });
  }

  GetCompanyContact() {
    this.insuranceService.getCompanyContact().subscribe(config => {
      this.phoneNumber = config.phoneNumber;
      this.contactTime = config.time;
    });
  }

  navigateToPolicyActivity() {
    this.router.navigate(['/policy-activity']);
  }

  aboutInsurere() {
    const modalref = this.modalService.open(AboutInsurerComponent, {
      backdrop: "static",
      windowClass: "",
      centered: true,
      size: "lg",
    });
  }

}