import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { InsuranceService } from '@app/services/insurance.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationDialogService } from '@shared/components/confirmation-dialog/confirmation-dialog.service';
import { ToasterService } from '@shared/utility/toaster.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-configuration',
  templateUrl: './configuration.component.html',
  styleUrl: './configuration.component.css'
})
export class ConfigurationComponent implements OnInit {
  @ViewChild('editModal') editModal: TemplateRef<any>;
  public subscription = new Subscription();
  configTest: FormGroup;
  editForm: FormGroup;
  configData: any[];

  constructor(
    private insuranceService: InsuranceService,
    private toasterService: ToasterService,
    private confirMationDialogService: ConfirmationDialogService,
    private fb: FormBuilder,
    private modalService: NgbModal
  ) { }

  ngOnInit(): void {
    this.loadConfigurationGroup();
    this.initForm();

  }

  initForm() {
    this.configTest = this.fb.group({
      configurationId: [null],
      // configKey: [null],
      configValue: [null],
    });
  }

  loadConfigurationGroup() {
    const companyId = 0;
    this.subscription.add(
      this.insuranceService.GetConfigurationListOnlyEditable(companyId).subscribe(
        (data: any[]) => {
          this.configData = data.map(m => ({
            ...m,
            isActive:  m.isActive ?? false
          }));
          console.log('Configuration : ', this.configData);
        })
    )
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  openEditModal(dataItem): void {
    this.configTest.patchValue({
      configurationId : dataItem.configurationId,
      // configKey: dataItem.configKey,
      configValue : dataItem.configValue,
    });
    this.modalService.open(this.editModal);
  }

  closeModal(): void {
    this.modalService.dismissAll();
  }

  toggleArchive(dataItem: any) {
    dataItem.isActive = dataItem.isActive
    this.subscription.add(
      this.insuranceService.UpdateConfigurationList(dataItem).subscribe(() => {
        this.toasterService.success("IsActive status updated successfully.");
        this.loadConfigurationGroup();
      })
    );
    console.log('Active :', dataItem.isActive);
  }

  updateData() {
    if (this.configTest.valid) {
      const objSave = this.configTest.value;
      this.subscription.add(
        this.insuranceService.UpdateConfigurationList(objSave).subscribe(() => {
          this.toasterService.success('Data updated successfully.');
          this.loadConfigurationGroup();
          this.closeModal();
        })
      );
    }
  }
}

