import { Component, Input } from '@angular/core';
import { InsuranceService } from '@app/services/insurance.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-announcement-popup',
  templateUrl: './announcement-popup.component.html',
  styleUrl: './announcement-popup.component.css'
})
export class AnnouncementPopupComponent {
  @Input()
  messagedata;

  subject = '';
  message = '';

  private subscription = new Subscription();

  constructor(
    private activeModal: NgbActiveModal,
    private insuranceService: InsuranceService,
    private toastrService: ToastrService
  ) { }

  ngOnInit(): void {
    this.subject = this.messagedata.subject;
    this.message = this.messagedata.message;
  }

  close() {
    this.activeModal.close();
  }
}
