<div class="modal-header">
    <div class="row justify-content-between align-items-center">
        <div class="col-md-auto">
            <div class="mb-0">Your Quote Reference Number is <b>842442</b>
                <span class="small-text ms-3">Created on
                    08/08/2023 13:09:09</span>
            </div>
        </div>
        <div class="col-md-auto ms-auto">
            <div class="d-flex align-items-center">
                <span class="me-2">Insurer:</span>
                <span>
                    <img src="../../assets/images/axa.png" style="max-height: 30px; border-radius: 5px;" alt="Axa">
                </span>
            </div>
        </div>
        <div class="col-md-auto">
            <span class="material-symbols-outlined cursor-pointer d-flex" (click)="closeDialog()">
                close
            </span>
        </div>
    </div>
</div>
<div class="modal-body">
    <div class="g-2 row">
        <div class="col-md-auto">
            <div class="alert fs-14 py-1 alert-secondary">
                Inception Date : <span class="font-weight-semibold">02/08/2023</span>
            </div>
        </div>
        <div class="col-md">
            <div class="alert fs-14 py-1 alert-danger">
                Your quote terms expired on 02/08/2023 - please recalculate with the current rates.
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12 recent-quote-grid">
            <kendo-grid [data]="gridData" [pageable]="false" [sortable]="true" [resizable]="true">
                <kendo-grid-column field="quotedocument" title="Documents" [headerStyle]="{ width: '240px' }"
                    [style]="{ width: '240px' }">
                    <ng-template kendoGridCellTemplate let-dataItem>
                        <div class="row g-1 align-items-center">
                            <div class="col-md-auto">
                                <span class="btn btn-sm btn-outline-primary">Quote Schedule</span>
                            </div>
                            <div class="col-md-auto">
                                <span class="btn btn-sm btn-outline-primary">Wording</span>
                            </div>
                            <div class="col-md-auto">
                                <span class="btn btn-sm btn-outline-primary">IPID</span>
                            </div>
                        </div>
                    </ng-template>
                </kendo-grid-column>
                <kendo-grid-column field="Ref" title="Ref" [headerStyle]="{ width: '70px' }"
                    [style]="{ width: '70px' }">
                    <ng-template kendoGridCellTemplate let-dataItem>
                        {{dataItem?.Ref}}
                    </ng-template>
                </kendo-grid-column>
                <kendo-grid-column field="Proposer" title="Proposer">
                    <ng-template kendoGridCellTemplate let-dataItem>
                        {{dataItem?.Proposer}}
                    </ng-template>
                </kendo-grid-column>
                <kendo-grid-column field="Inception" title="Inception" [headerStyle]="{ width: '100px' }"
                    [style]="{ width: '100px' }">
                </kendo-grid-column>
                <kendo-grid-column field="twosixindemnity" title="2.6 Indemnity" [headerStyle]="{ width: '140px' }"
                    [style]="{ width: '140px' }">
                    <ng-template kendoGridCellTemplate let-dataItem>
                        <div class="row g-1 justify-content-between align-items-center">
                            <div class="col-md-auto">
                                {{dataItem?.twosixindemnity}}
                            </div>
                            <div class="col-md-auto">
                                <span class="btn btn-sm btn-primary">Get Cover</span>
                            </div>
                        </div>
                    </ng-template>
                </kendo-grid-column>
                <kendo-grid-column field="sixfiveindemnity" title="6.5 Indemnity" [headerStyle]="{ width: '140px' }"
                    [style]="{ width: '140px' }">
                    <ng-template kendoGridCellTemplate let-dataItem>
                        <div class="row g-1 justify-content-between align-items-center">
                            <div class="col-md-auto">
                                {{dataItem?.sixfiveindemnity}}
                            </div>
                            <div class="col-md-auto">
                                <span class="btn btn-sm btn-primary">Get Cover</span>
                            </div>
                        </div>
                    </ng-template>
                </kendo-grid-column>
                <kendo-grid-column field="SanctionsCheck" title="Sanctions" [headerStyle]="{ width: '80px' }"
                    [style]="{ width: '80px' }">
                    <ng-template kendoGridCellTemplate let-dataItem>
                        <span class="badge bg-success">{{dataItem?.SanctionsCheck}}</span>
                    </ng-template>
                </kendo-grid-column>
                <kendo-grid-column field="dateSaved" title="Date Saved" [headerStyle]="{ width: '90px' }"
                    [style]="{ width: '90px' }">
                </kendo-grid-column>
                <kendo-grid-column field="action" title="Action" [headerStyle]="{ width: '60px' }"
                    [style]="{ width: '60px' }">
                    <ng-template kendoGridCellTemplate let-dataItem>
                        <div class="dot-menu">
                            <div class="dropdown">
                                <button class="btn-grid dropdown-toggle pb-0" type="button" id="dropdownMenuButton1"
                                    data-bs-toggle="dropdown" aria-expanded="false">
                                    <span class="material-symbols-outlined">
                                        expand_circle_down
                                    </span>
                                </button>
                                <ul class="dropdown-menu dropdown-menu1 dropdown-menu-right"
                                    aria-labelledby="dropdownMenuButton1">
                                    <li><span class="dropdown-item" (click)="editQuote()">Edit Quote</span></li>
                                    <li><span class="dropdown-item">Add Note</span></li>
                                    <li><span class="dropdown-item">Add Special Term</span></li>
                                    <li><span class="dropdown-item">View Breakdown</span></li>
                                    <li><span class="dropdown-item">Amend Terms</span></li>
                                    <li><span class="dropdown-item">Amend History</span></li>
                                </ul>
                            </div>
                        </div>
                    </ng-template>
                </kendo-grid-column>
            </kendo-grid>
        </div>
    </div>
    <div class="row mt-3">
        <div class="col-md-12">
            <div class="accordion" id="accordionExample">
                <div class="accordion-item">
                    <h2 class="accordion-header" id="headingOne">
                        <button class="accordion-button" type="button" data-bs-toggle="collapse"
                            data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                            Acceptance Criteria
                        </button>
                    </h2>
                    <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne"
                        data-bs-parent="#accordionExample">
                        <div class="accordion-body">
                            <p class="font-weight-semibold mb-1">
                                Has the proposer or any Partner or Director either personally or in connection
                                with any business they have been involved with:
                            </p>
                            <div class="underwriter-repeater">
                                <div class="row justify-content-between align-items-center">
                                    <div class="col-md-10">
                                        <p class="mb-0">Ever been declined or refused insurance cover or had cover
                                            cancelled
                                            or
                                            subject to special terms in respect of any covers to which this
                                            insurance relates?</p>
                                    </div>
                                    <div class="col-md-auto">
                                        <div class="btn-group" role="group"
                                            aria-label="Basic radio toggle button group">
                                            <input type="radio" class="btn-check" name="No" id="No" autocomplete="off"
                                                checked>
                                            <label class="btn toggle-btn-sm btn-outline-primary mb-0"
                                                for="No">No</label>
                                            <input type="radio" class="btn-check" name="Yes" id="Yes"
                                                autocomplete="off">
                                            <label class="btn toggle-btn-sm btn-outline-primary mb-0"
                                                for="Yes">Yes</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="underwriter-repeater">
                                <div class="row justify-content-between align-items-center">
                                    <div class="col-md-10">
                                        <p class="mb-0">Ever been convicted of or charged with (but not yet tried) a
                                            criminal offence other than a motoring offence?</p>
                                    </div>
                                    <div class="col-md-auto">
                                        <div class="btn-group" role="group"
                                            aria-label="Basic radio toggle button group">
                                            <input type="radio" class="btn-check" name="No" id="No" autocomplete="off"
                                                checked>
                                            <label class="btn toggle-btn-sm btn-outline-primary mb-0"
                                                for="No">No</label>
                                            <input type="radio" class="btn-check" name="Yes" id="Yes"
                                                autocomplete="off">
                                            <label class="btn toggle-btn-sm btn-outline-primary mb-0"
                                                for="Yes">Yes</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="underwriter-repeater">
                                <div class="row justify-content-between align-items-center">
                                    <div class="col-md-10">
                                        <p class="mb-0">Suffered any losses or made any claims in the past five years
                                            for any of the perils or contingencies to which this insurance applies? </p>
                                    </div>
                                    <div class="col-md-auto">
                                        <div class="btn-group" role="group"
                                            aria-label="Basic radio toggle button group">
                                            <input type="radio" class="btn-check" name="No" id="No" autocomplete="off"
                                                checked>
                                            <label class="btn toggle-btn-sm btn-outline-primary mb-0"
                                                for="No">No</label>
                                            <input type="radio" class="btn-check" name="Yes" id="Yes"
                                                autocomplete="off">
                                            <label class="btn toggle-btn-sm btn-outline-primary mb-0"
                                                for="Yes">Yes</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="underwriter-repeater">
                                <div class="row justify-content-between align-items-center">
                                    <div class="col-md-10">
                                        <p class="mb-0">Ever been declared bankrupt or are the subject of any bankruptcy
                                            proceedings or any voluntary or mandatory insolvency or winding up
                                            procedures or been the subject of a recovery action by Revenue
                                            Commissioners? </p>
                                    </div>
                                    <div class="col-md-auto">
                                        <div class="btn-group" role="group"
                                            aria-label="Basic radio toggle button group">
                                            <input type="radio" class="btn-check" name="No" id="No" autocomplete="off"
                                                checked>
                                            <label class="btn toggle-btn-sm btn-outline-primary mb-0"
                                                for="No">No</label>
                                            <input type="radio" class="btn-check" name="Yes" id="Yes"
                                                autocomplete="off">
                                            <label class="btn toggle-btn-sm btn-outline-primary mb-0"
                                                for="Yes">Yes</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="underwriter-repeater">
                                <div class="row justify-content-between align-items-center">
                                    <div class="col-md-10">
                                        <p class="mb-0">Ever been prosecuted or served a prohibition order under Health
                                            and Safety legislation within the last 5 years? </p>
                                    </div>
                                    <div class="col-md-auto">
                                        <div class="btn-group" role="group"
                                            aria-label="Basic radio toggle button group">
                                            <input type="radio" class="btn-check" name="No" id="No" autocomplete="off"
                                                checked>
                                            <label class="btn toggle-btn-sm btn-outline-primary mb-0"
                                                for="No">No</label>
                                            <input type="radio" class="btn-check" name="Yes" id="Yes"
                                                autocomplete="off">
                                            <label class="btn toggle-btn-sm btn-outline-primary mb-0"
                                                for="Yes">Yes</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <h6 class="mt-3 mb-2">Business & Employee Details</h6>
    <div class="row input-without-lbl-gray">
        <div class="col-md-6">
            <div class="gray-input-box d-flex justify-content-between">
                <label>Business Name :</label>
                <label class="font-weight-semibold">Peter's 11:23:00</label>
            </div>
            <div class="gray-input-box d-flex justify-content-between">
                <label>Status :</label>
                <label class="font-weight-semibold">Sole Trader</label>
            </div>
            <div class="gray-input-box d-flex justify-content-between">
                <label>Annual Payments to bona fide sub-contractors :</label>
                <label class="font-weight-semibold">€0</label>
            </div>
            <div class="gray-input-box d-flex justify-content-between">
                <label>Full time manual employees :</label>
                <label class="font-weight-semibold">3</label>
            </div>
            <div class="gray-input-box d-flex justify-content-between">
                <label>Annual payments to labour only sub-contractors :</label>
                <label class="font-weight-semibold">3</label>
            </div>
        </div>
        <div class="col-md-6">
            <div class="gray-input-box d-flex justify-content-between">
                <label>Occupations :</label>
                <label class="font-weight-semibold">Mobile Catering Van/Trailer</label>
            </div>
            <div class="gray-input-box d-flex justify-content-between">
                <label>Projected Annual Turnover :</label>
                <label class="font-weight-semibold">€26,000</label>
            </div>
            <div class="gray-input-box d-flex justify-content-between">
                <label>Clerical employees :</label>
                <label class="font-weight-semibold">0</label>
            </div>
            <div class="gray-input-box d-flex justify-content-between">
                <label>Part time manual employees :</label>
                <label class="font-weight-semibold">1</label>
            </div>
        </div>
    </div>
</div>
<div class="modal-footer">

</div>