<div class="card h-100">
    <div class="card-body dash-icons">
        <div class="row mt-0">
            <div class="col-md-8">
                <div class="policy-box">
                    <div class="row gy-4">
                        <div class="col-xl col-md-6 col-12">
                            <div class="card">
                                <div class="card-body">
                                    <div class="align-items-center row cursor-pointer" (click)="navigateToPolicyStatus(3)">
                                        <div class="col">
                                            <h6 class="text-secondary mb-2">Active Policy</h6>
                                            <span class="h3 mb-0">{{dashboardSummary?.activePolicy}}</span>
                                        </div>
                                        <div class="col-auto">
                                            <span class="material-symbols-outlined">
                                                check_circle
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl col-md-6 col-12">
                            <div class="card">
                                <div class="card-body">
                                    <div class="align-items-center row cursor-pointer" (click)="navigateToPolicyStatus(1)">
                                        <div class="col">
                                            <h6 class="text-secondary mb-2">New Quote in last 15 Days
                                            </h6>
                                            <span class="h3 mb-0">{{dashboardSummary?.newQuoteCount}}</span>
                                        </div>
                                        <div class="col-auto">
                                            <span class="material-symbols-outlined">
                                                add_circle
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl col-md-6 col-12">
                            <div class="card">
                                <div class="card-body">
                                    <div class="align-items-center row cursor-pointer" (click)="navigateToPolicyStatus(3)">
                                        <div class="col">
                                            <h6 class="text-secondary mb-2">Referred Policy</h6>
                                            <span class="h3 mb-0">{{dashboardSummary?.referredPolicy}}</span>
                                        </div>
                                        <div class="col-auto">
                                            <span class="material-symbols-outlined">
                                                policy
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl col-md-6 col-12">
                            <div class="card">
                                <div class="card-body">
                                    <div class="align-items-center row  cursor-pointer" (click)="navigateToPolicyStatus(8)">
                                        <div class="col">
                                            <h6 class="text-secondary mb-2">Renewal List</h6><span
                                                class="h3 mb-0">{{dashboardSummary?.renewDateCount}}</span>
                                        </div>
                                        <div class="col-auto">
                                            <span class="material-symbols-outlined">
                                                autorenew
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <div class="policy-box mt-3">
                    <div class="row">
                        <div class="col-md-6 col-12">
                            <div class="card">
                                <div class="card-body">
                                    <div class="align-items-center row">
                                        <div class="col">
                                            <h6 class="text-secondary mb-2">Some Text</h6><span
                                                class="h3 mb-0">29</span>
                                        </div>
                                        <div class="col-auto"><button type="button"
                                                class="btn btn-primary btn-sm">Action</button></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 col-12">
                            <div class="card">
                                <div class="card-body">
                                    <div class="align-items-center row">
                                        <div class="col">
                                            <h6 class="text-secondary mb-2">Some Text
                                            </h6>
                                            <span class="h3 mb-0">7,500</span>
                                        </div>
                                        <div class="col-auto"><button type="button"
                                                class="btn btn-primary btn-sm">Action</button></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> -->
                <div class="policy-box mt-3">
                    <div class="card">
                        <div class="card-header">
                            <h6 class="card-header-title mb-0">Diary List</h6>
                        </div>
                        <div class="table-responsive optis-grid">
                            <kendo-grid [data]="dairyDataList | async" [pageSize]="dairyState.take"
                                [skip]="dairyState.skip" [sort]="dairyState.sort"
                                (dataStateChange)="dataStateChangeDairy($event)" [pageable]="true" [sortable]="true"
                                [resizable]="true">

                                <kendo-grid-column field="diaryStatus" title="Status" [headerStyle]="{ width: '100px' }"
                                    [style]="{ width: '100px' }">
                                    <!-- <ng-template kendoGridCellTemplate let-dataItem>
                                       <a (click)="getQuote()">{{dataItem?.Ref}}</a>
                                    </ng-template> -->

                                    <ng-template kendoGridCellTemplate let-dataItem>
                                        <span class="badge bg-danger" *ngIf="dataItem.diaryStatusId==1">Outstanding</span>
                                        <span class="badge bg-success" *ngIf="dataItem.diaryStatusId==2">SignedOff</span>
                                        <span class="badge bg-secondary" *ngIf="dataItem.diaryStatusId==3">n.a.</span>
                                    </ng-template>
                                </kendo-grid-column>
                                <kendo-grid-column field="fullName" title="Action By" [headerStyle]="{ width: '100px' }"
                                    [style]="{ width: '100px' }">

                                </kendo-grid-column>
                                <kendo-grid-column field="createddate" title="Created On"
                                    [headerStyle]="{ width: '100px' }" [style]="{ width: '100px' }">
                                    <ng-template kendoGridCellTemplate let-dataItem>
                                        {{ dataItem.createdDate | date: "dd/MM/yyyy" }}
                                    </ng-template>
                                </kendo-grid-column>
                                <kendo-grid-column field="assignUserName" title="Assigned" [headerStyle]="{ width: '100px' }"
                                    [style]="{ width: '100px' }">
                                </kendo-grid-column>
                                <kendo-grid-column field="diaryId" title="Diary Id" [headerStyle]="{ width: '100px' }"
                                    [style]="{ width: '100px' }">
                                    <ng-template kendoGridCellTemplate let-dataItem>
                                        <a (click)="editDiary(dataItem?.diaryId)"> {{dataItem?.diaryId}}</a>

                                    </ng-template>
                                </kendo-grid-column>
                                <kendo-grid-column field="tradingName" title="Agent" [headerStyle]="{ width: '100px' }"
                                    [style]="{ width: '100px' }">
                                </kendo-grid-column>
                                <kendo-grid-column field="productName" title="Product"
                                    [headerStyle]="{ width: '100px' }" [style]="{ width: '100px' }">
                                </kendo-grid-column>

                                <kendo-grid-column field="notes" title="Risk Info" [headerStyle]="{ width: '200px' }"
                                    [style]="{ width: '200px' }">
                                    <ng-template kendoGridCellTemplate let-dataItem>
                                        Quote : <a (click)="goToQuote(dataItem?.policyId,dataItem?.productCode)">
                                            {{dataItem?.quoteRefNo}}</a><br />
                                        {{dataItem?.businessName}}<br />
                                        Inception : {{dataItem?.inceptionDate |date}}
                                    </ng-template>
                                </kendo-grid-column>

                                <kendo-grid-column field="referInfo" title="Refer Info" class="text-truncate"
                                    [headerStyle]="{ width: '250px' }" [style]="{ width: '250px' }">

                                    <ng-template kendoGridCellTemplate let-dataItem>
                                        {{dataItem?.handlerName}} | {{dataItem?.mobile}}|
                                        {{dataItem?.email}}
                                    </ng-template>
                                </kendo-grid-column>
                            </kendo-grid>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="policy-box">
                    <h6 class="font-weight-semibold mb-1">
                        Confirmation of New Underwriter
                    </h6>
                    <img class="insurer-logo mt-3" src="{{activeInsurer?.logoPath}}">
                    <div class="mt-3">
                        <a (click)="aboutInsurere()" class="cursor-pointer text-primary text-decoration-underline">
                            Find out more >
                        </a>
                    </div>

                    <div class="card card-gray mt-3">
                        <div class="card-body">
                            <h3>Call: {{ phoneNumber }}</h3>
                            <p class="mb-0">{{ contactTime }}</p>
                        </div>
                    </div>
                </div>
                <div class="policy-box mt-3 announcement-focus">
                    <div class="pp-animation">
                        <span class="pp-animation__line pp-animation__line_left"></span>
                        <span class="pp-animation__line pp-animation__line_right"></span>
                        <span class="pp-animation__line pp-animation__line_top"></span>
                        <span class="pp-animation__line pp-animation__line_bottom"></span>
                    </div>
                    <h6 class="font-weight-semibold mb-1">
                        Announcements
                    </h6>
                    <div class="underwriter-repeater position-relative z-3">
                        <div class="row justify-content-between align-items-center overflow-scrollable" *ngIf="announcementList?.length>0">
                            <div class="text-black policy-box mb-2 notification-block"
                                *ngFor="let item of announcementList;">
                                <span class="font-weight-600">Subject : {{item.subject}}</span><br> <br>
                                <span class="font-weight-600">Message :</span>
                                <div class="col-md col" [innerHTML]="item.message">
                                </div>
                            </div>
                        </div>
                        <div class="row justify-content-between align-items-center"
                            *ngIf="!(announcementList?.length>0)">
                            <div class="col-md">
                                <div class="text-black policy-box mb-2 notification-block">
                                    <p class="mb-0">
                                        No Announcement
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>