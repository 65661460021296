<div class="modal-header">
    <div class="row justify-content-between align-items-center">
        <div class="col-md-auto">
            <div class="mb-0 page-title font-weight-semibold">Today's Notification
            </div>
        </div>
        <div class="col-md-auto">
            <span class="material-symbols-outlined cursor-pointer" (click)="close()">
                close
            </span>
        </div>
    </div>
</div>
<div class="modal-body">
    <div class="text-black policy-box mb-2 notification-block" *ngFor="let item of messagedata;">
        <span class="font-weight-600">Subject : {{item.subject}}</span><br> <br>
        <span class="font-weight-600">Message :</span>
        <div class="col-md col" [innerHTML]="item.message">

        </div>

    </div>

</div>
<div class="modal-footer">
    <div class="form-row justify-content-end">
        <div class="col-md-auto col-auto">
            <button class="btn btn-sm btn-primary" (click)="close()">Close</button>
        </div>
    </div>
</div>