import { Component, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DataBindingDirective } from '@progress/kendo-angular-grid';
import { Subscription } from 'rxjs';
import { process } from '@progress/kendo-data-query';
import { InsuranceService } from '@app/services/insurance.service';
import { ToasterService } from '@shared/utility/toaster.service';
import { getExactDate } from '@shared/utility/utilityHelpers';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AddAnnouncementComponent } from '../add-announcement/add-announcement.component';

@Component({
  selector: 'app-announcement-list',
  templateUrl: './announcement-list.component.html',
  styleUrl: './announcement-list.component.css'
})
export class AnnouncementListComponent {
  gridData: [];
  searchText: any;
  private subscription = new Subscription();
  @ViewChild(DataBindingDirective) dataBinding: DataBindingDirective;
  announcementfrom: FormGroup;
  dataAnnouncement: any;
  dataAnnouncementView: any;
  public pageindex = 1;
  public pageSize = 10;
  constructor(private insuranceService: InsuranceService, private fb: FormBuilder, private toasterService: ToasterService, private modalService: NgbModal) {

  }
  ngOnInit(): void {
    this.initForm();
    this.loadData();
  }

  onKeydown(event) {
    if (event.key === '/') {
      return false;
    }
  }

  clearAll() {
    this.announcementfrom.controls['messagefromdate'].reset();
    this.announcementfrom.controls['messagetodate'].reset();
    this.loadData();
  }

  initForm() {
    this.announcementfrom = this.fb.group({
      messagefromdate: ['', Validators.required],
      messagetodate: ['', Validators.required]
    });
  }

  addannouncement(announcementId = 0) {
    const modalref = this.modalService.open(AddAnnouncementComponent, {
      backdrop: "static",
      backdropClass: "modal-on-modal",
      windowClass: "modal-on-modal-dialog",
      centered: true,
      size: "lg",
    });
    modalref.componentInstance.announcementId = announcementId;
    modalref.result.then((data: any) => {
      if (data != null) {
        this.loadData();
      }
    });
  }

  currentDate = new Date(new Date().setHours(0, 0, 0, 0));
  priorDate = new Date(new Date().setDate(this.currentDate.getDate() - 30));

  loadData() {
    if (this.priorDate == null || this.priorDate == undefined || this.currentDate == null || this.currentDate == undefined) {
      this.currentDate = new Date(new Date().setHours(0, 0, 0, 0));
      this.priorDate = new Date(new Date().setDate(this.currentDate.getDate() - 30));
    }
    const objData = Object.assign({});
    objData.messageFromDate = getExactDate(this.priorDate);
    objData.messageToDate = getExactDate(this.currentDate);
    this.subscription.add(
      this.insuranceService.GetAllAnnouncement(objData).subscribe((response: any): void => {
        if (response != null) {
          this.dataAnnouncement = response;
          this.dataAnnouncementView = response;
        }
      }
      )
    );
  }

  EnableDisableAnnoucement(announcementId: number) {
    this.subscription.add(
      this.insuranceService.UpdateAnnouncementStatus(announcementId).subscribe((response: any): void => {
        if (response != null) {
          this.toasterService.success('Announcement status updated successfully.');
          this.loadData();
        }
      }
      )
    );
  }

  searchHistory() {
    if (this.announcementfrom.valid) {
      const objData = Object.assign({});
      objData.messageFromDate = this.announcementfrom.controls["messagefromdate"].value ? getExactDate(this.announcementfrom.controls["messagefromdate"].value) : null;
      objData.messageToDate = this.announcementfrom.controls["messagetodate"].value ? getExactDate(this.announcementfrom.controls["messagetodate"].value) : null;

      this.subscription.add(
        this.insuranceService.GetAllAnnouncement(objData).subscribe((response: any): void => {
          if (response != null) {
            this.dataAnnouncement = response;
            this.dataAnnouncementView = response;
            this.dataBinding.skip = 0;
          }
        }
        )
      );
    }
    else {
      this.insuranceService.validateAllFormFields(this.announcementfrom);
    }
  }

  filterData() {
    this.dataAnnouncement = this.dataAnnouncementView;
    if (this.searchText && this.searchText !== '') {
      this.dataAnnouncement = process(this.dataAnnouncementView, {
        filter: {
          logic: 'or',
          filters: [
            {
              field: 'subject',
              operator: 'contains',
              value: this.searchText
            },
            {
              field: 'message',
              operator: 'contains',
              value: this.searchText
            },
            {
              field: 'messageFromDate',
              operator: 'contains',
              value: this.searchText
            },
            {
              field: 'messageToDate',
              operator: 'contains',
              value: this.searchText
            },
            {
              field: 'createdByUser',
              operator: 'contains',
              value: this.searchText
            },
            {
              field: 'messageFromDateString',
              operator: 'contains',
              value: this.searchText
            },
            {
              field: 'messageToDateString',
              operator: 'contains',
              value: this.searchText
            }

          ]
        }
      }).data;
      this.dataBinding.skip = 0;
    }
  }
}
