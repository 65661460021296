<div class="row g-2">
    <div  *ngIf="this.isShowTranssummContaint" class="boxcontainer" style="height: 5em; background-color: rgb(255, 204, 255); display: block;">
        <div id="transline" style="text-align:center;margin:1.5em 0;font-weight:bold; font-size:1.2em;">Transaction successfully created:
            <a class="mypdftrans"  (click)="openTransactions(this.transId)">{{ this.transId }}</a></div>
      <div class="text-align " style="text-align:center;margin-top:5px;"><a href="/open-market-transaction">Create Another Open Market Transaction &gt;&gt;</a></div>
    </div>
    <div class="col-md-12" *ngIf="!this.isShowTranssummContaint">
        <div class="card h-100 blankpage-height">
            <div class="card border">
                <div  class="card-body pb-0">
                    <form novalidate [formGroup]="openMarketTransForm">
                        <div class="card border">
                            <div class="card-body pb-0">
                                <h6 class="mb-3">Transaction Information</h6>
                                <div class="row input-without-lbl-gray">
                                    <div class="row mt-2">
                                        <div class="form-group col-md-12">
                                            <div class="row">
                                                <div class="form-group col-md-4">
                                                    <label>Transaction Date</label>
                                                    <kendo-datepicker [format]="'dd/MM/yyyy'" placeholder="dd/mm/yyyy"
                                                        formControlName="transactionDate" class="w-100">
                                                    </kendo-datepicker>
                                                    <div class="validation-msg-label"
                                                        *ngIf="openMarketTransForm.controls.transactionDate.touched  && openMarketTransForm.controls.transactionDate.errors?.required">
                                                        Required
                                                    </div>
                                                </div>
                                                <div class="form-group col-md-4">
                                                    <label>Transaction Type</label>
                                                    <kendo-combobox [data]="transactionTypeList" class="w-100"
                                                        [textField]="'text'" [valueField]="'value'"
                                                        [valuePrimitive]="true" formControlName="transactionType">
                                                    </kendo-combobox>
                                                    <div class="validation-msg-label"
                                                        *ngIf="openMarketTransForm.controls.transactionType.touched  && openMarketTransForm.controls.transactionType.errors?.required">
                                                        Required
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card border mt-3">
                            <div class="card-body pb-0">
                                <h6 class="mb-3">Policy Information</h6>
                                <div class="row input-without-lbl-gray">
                                    <div class="row mt-2">
                                        <div class="form-group col-md-12">
                                            <div class="row">
                                                <div class="form-group col-md-4">
                                                    <label>Broker</label>
                                                    <kendo-combobox [data]="brokerList" class="w-100"
                                                        [textField]="'tradingName'" [valueField]="'agentId'"
                                                        [valuePrimitive]="true" formControlName="brokerId">
                                                    </kendo-combobox>
                                                    <div class="validation-msg-label"
                                                        *ngIf="openMarketTransForm.controls.brokerId.touched  && openMarketTransForm.controls.brokerId.errors?.required">
                                                        Required
                                                    </div>
                                                </div>
                                                <div class="form-group col-md-4">
                                                    <label>Proposer</label>
                                                    <input type="text" class="form-control" name="Proposer"
                                                        formControlName="proposer" maxlength="100" appSpaceInputRestriction>
                                                    <div class="validation-msg-label"
                                                        *ngIf="openMarketTransForm.controls.proposer.touched  && openMarketTransForm.controls.proposer.errors?.required">
                                                        Required
                                                    </div>
                                                </div>
                                                <div class="form-group col-md-4">
                                                    <label>Inception Date</label>
                                                    <kendo-datepicker [format]="'dd/MM/yyyy'" placeholder="dd/mm/yyyy"
                                                        formControlName="inceptionDate" class="w-100">
                                                    </kendo-datepicker>
                                                    <div class="validation-msg-label"
                                                        *ngIf="openMarketTransForm.controls.inceptionDate.touched  && openMarketTransForm.controls.inceptionDate.errors?.required">
                                                        Required
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="form-group col-md-3">
                                                    <label>Policy Preminum (incl Levy)</label>
                                                    <input type="text" class="form-control" name="policyPreminum"
                                                        formControlName="policyPreminum" maxlength="8" twodecimalOnly appSpaceInputRestriction
                                                        (change)="calculateRetailPremium()">
                                                    <div class="validation-msg-label"
                                                        *ngIf="openMarketTransForm.controls.policyPreminum.touched  && openMarketTransForm.controls.policyPreminum.errors?.required">
                                                        Required
                                                    </div>
                                                  
                                                </div>
                                                <div class="form-group col-md-3">
                                                    <label>Levy %</label>
                                                    <input (change)="calculateTotalCommision()" type="text"
                                                        class="form-control" name="levy" formControlName="levy" maxlength="3" appSpaceInputRestriction twodecimalOnly>
                                                    <div class="validation-msg-label"
                                                        *ngIf="openMarketTransForm.controls.levy.touched  && openMarketTransForm.controls.levy.errors?.required">
                                                        Required
                                                    </div>
                                                    <div class="validation-msg-label"        
                                                     *ngIf="openMarketTransForm.controls.levy.touched && (openMarketTransForm.controls.levy.errors?.min || openMarketTransForm.controls.levy.errors?.max)"> 
                                                    Levy % must be between 0 and 100   
                                                    </div>
                                                </div>
                                                <div class="form-group col-md-3">
                                                    <label>Fees</label>
                                                    <input type="text" class="form-control" name="fees"
                                                        formControlName="fees" maxlength="8" appSpaceInputRestriction twodecimalOnly (change)="calculateRetailPremium()">
                                                    <div class="validation-msg-label"
                                                        *ngIf="openMarketTransForm.controls.fees.touched  && openMarketTransForm.controls.fees.errors?.required">
                                                        Required
                                                    </div>
                                                </div>
                                                <div class="form-group col-md-3">
                                                    <label>Retail Premium (incl Levy + Fees)</label>
                                                    <input type="text" class="form-control" name="retailPremium"
                                                        formControlName="retailPremium">
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="form-group col-md-12">
                                                    <label>Policy Notes</label>
                                                    <textarea name="" rows="5" id="" maxLength="500"
                                                        class="form-control form-control-textarea"
                                                        formControlName="policyNote"></textarea>
                                                    <!-- <div class="validation-msg-label"
                                                        *ngIf="openMarketTransForm.controls.policyNote.touched  && openMarketTransForm.controls.policyNote.errors?.required">
                                                        Required
                                                    </div> -->
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card border mt-3 mb-3">
                            <div class="card-body pb-0">
                                <h6 class="mb-3">Commision Percentages</h6>
                                <div class="row input-without-lbl-gray">
                                    <div class="row mt-2">
                                        <div class="form-group col-md-12">
                                            <div class="row">
                                                <div class="form-group col-md-3">
                                                    <label>Total Commision %</label>
                                                    <input type="text" class="form-control" name="retailPremium"
                                                        formControlName="totalCommisionPer" maxlength="3" twodecimalOnly appSpaceInputRestriction
                                                        (change)="calculateTotalCommision()">
                                                    <div class="validation-msg-label"
                                                        *ngIf="openMarketTransForm.controls.totalCommisionPer.touched  && openMarketTransForm.controls.totalCommisionPer.errors?.required">
                                                        Required
                                                    </div>
                                                    <div class="validation-msg-label"        
                                                    *ngIf="openMarketTransForm.controls.totalCommisionPer.touched && (openMarketTransForm.controls.totalCommisionPer.errors?.min || openMarketTransForm.controls.totalCommisionPer.errors?.max)"> 
                                                    Total Commision % must be between 0 to 100   
                                                   </div>
                                                </div>
                                                <div class="form-group col-md-3">
                                                    <label>Broker Commision %</label>
                                                    <input type="text" class="form-control" name="retailPremium"
                                                        formControlName="brokerCommisionPer" maxlength="3" twodecimalOnly appSpaceInputRestriction
                                                        (change)="calculateBrokerCommision()">
                                                    <div class="validation-msg-label"
                                                        *ngIf="openMarketTransForm.controls.brokerCommisionPer.touched  && openMarketTransForm.controls.brokerCommisionPer.errors?.required">
                                                        Required
                                                    </div>
                                                    <div class="validation-msg-label"        
                                                    *ngIf="openMarketTransForm.controls.brokerCommisionPer.touched && (openMarketTransForm.controls.brokerCommisionPer.errors?.min || openMarketTransForm.controls.brokerCommisionPer.errors?.max)"> 
                                                    Broker Commision % must be between 0 to 100   
                                                   </div>
                                                </div>
                                                <div class="form-group col-md-3">
                                                    <label>Llyods Commision %</label>
                                                    <input type="text" class="form-control" name="retailPremium"
                                                        formControlName="llyodsCommisionPer" maxlength="3" twodecimalOnly appSpaceInputRestriction
                                                        (change)="calculateLlyodsCommision()">
                                                    <div class="validation-msg-label"
                                                        *ngIf="openMarketTransForm.controls.llyodsCommisionPer.touched  && openMarketTransForm.controls.llyodsCommisionPer.errors?.required">
                                                        Required
                                                    </div>
                                                    <div class="validation-msg-label"        
                                                    *ngIf="openMarketTransForm.controls.llyodsCommisionPer.touched && (openMarketTransForm.controls.llyodsCommisionPer.errors?.min || openMarketTransForm.controls.llyodsCommisionPer.errors?.max)"> 
                                                    Llyods Commision % must be between 0 to 100   
                                                   </div>
                                                </div>
                                                <div class="form-group col-md-3">
                                                    <label>Optis Commision %</label>
                                                    <input type="text" class="form-control" name="retailPremium"
                                                        formControlName="optisCommisionPer">
                                                    <div class="validation-msg-label"
                                                        *ngIf="openMarketTransForm.controls.optisCommisionPer.touched  && openMarketTransForm.controls.optisCommisionPer.errors?.required">
                                                        Required
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="form-group col-md-3">
                                                    <label>Total Commision Amount € </label>
                                                    <input type="text" class="form-control" name="retailPremium"
                                                        formControlName="totalCommision">
                                                </div>
                                                <div class="form-group col-md-3">
                                                    <label>Broker Commision Amount € </label>
                                                    <input type="text" class="form-control" name="retailPremium"
                                                        formControlName="brokerCommision">
                                                </div>
                                                <div class="form-group col-md-3">
                                                    <label>Llyods Commision Amount € </label>
                                                    <input type="text" class="form-control" name="retailPremium"
                                                        formControlName="llyodsCommision">
                                                </div>
                                                <div class="form-group col-md-3">
                                                    <label>Optis Commision Amount € </label>
                                                    <input type="text" class="form-control" name="retailPremium"
                                                        formControlName="optisCommision">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                    <div class="row mt-3 mb-3 justify-content-end">
                        <div class="col-md-auto">
                            <button class="btn btn-sm btn-primary" (click)="Save()">Submit</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>