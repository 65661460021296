

<div class="div">
    <script>
      window.onload = function () {
        /* The parent window hosts the Angular application */
        var parent = window.parent;
        /* Send the id_token information to the oidc message handler */
        var event = new CustomEvent('oidc-silent-renew-message', { detail: window.location });
        parent.dispatchEvent(event);
      };
    </script>
  </div>