<div class="footer-main my-2">
  <div class="row justify-content-between">
    <div class="col-md-auto">
      <p class="mb-0">Registered Office: Unit 1, Knightsbrook Square, Knightsbrook, Trim, Co. Meath C15 AN81</p>
      <p class="mb-0">
        Optis Insurances Limited t/a Optis Insurance is regulated by the Central Bank of Ireland.
      </p>
    </div>
    <div class="col-md-auto">
      <p class="mb-0">
        Website content © 2010 - Optis Insurances Limited.
      </p>
      <p class="mb-0"><a href="https://optis.ie/contact/privacy-notice/" target="_blank">Privacy Notice</a> | <a href="https://optis.ie/cookies/" target="_blank">Cookie Policy</a></p>
    </div>
  </div>
</div>