import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { InsuranceService } from '@app/services/insurance.service';
import { ConfirmationDialogService } from '@shared/components/confirmation-dialog/confirmation-dialog.service';
import { ToasterService } from '@shared/utility/toaster.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-document-configuration',
  templateUrl: './document-configuration.component.html',
  styleUrl: './document-configuration.component.css'
})
export class DocumentConfigurationComponent implements OnInit, OnDestroy {
  public subscription = new Subscription();
  documentTest: FormGroup;
  documentListdata: any[];
  documentFiledata: any[];
  selectedFile: File = null;
  uploadedDocumentPath: string | ArrayBuffer | null = null;

  constructor(
    private insuranceService: InsuranceService,
    private toasterService: ToasterService,
    private confirMationDialogService: ConfirmationDialogService,
    private fb: FormBuilder,
  ) { }

  ngOnInit(): void {
    this.loadDocumentFile(),
      this.loadDocumentGroup(),
      this.initForm()
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  initForm() {
    this.documentTest = this.fb.group({
      documentGroupId: [null, Validators.required],
      path: [null, [Validators.required]],
      fileName: [null, [Validators.required, Validators.maxLength(100), this.noWhitespaceValidator]],
      header: [null, [Validators.maxLength(100), this.noWhitespaceValidator]],
    });
  }

  loadDocumentGroup() {
    this.subscription.add(
      this.insuranceService.getAllDocumentGroup().subscribe((data: any[]): void => {
        this.documentListdata = data;
      })
    )
  }

  loadDocumentFile() {
    this.subscription.add(
      this.insuranceService.getAllDocumentFile().subscribe((data: any[]): void => {
        this.documentFiledata = data.map(file => ({
          ...file,
          isArchived: file.isArchived ?? false
        }));
      })
    )
  }

  noWhitespaceValidator(control: AbstractControl) {
    const value = control.value || '';
    const hasWhitespace = value.trim() === '' && value.length > 0;
    return hasWhitespace ? { whitespace: true } : null;
  }

  validFileExtension(control: FormControl) {
    const file = control.value;
    const allowedExtensions = ['jpg', 'jpeg', 'pdf', 'email', 'doc', 'docx'];
    if (file) {
      const fileExtension = file.name.split('.').pop().toLowerCase();
      if (!allowedExtensions.includes(fileExtension)) {
        return { invalidFileExtension: true };
      }
    }
    return null;
  }

  onSelect(event) {
    const input = event.target as HTMLInputElement;
    const file = input.files[0];
    this.selectedFile = file;
    const extensionValidationResult = this.validFileExtension(new FormControl(file));
    if (file && extensionValidationResult === null) {
      this.filetoS3(file);
    } else {
      if (extensionValidationResult !== null) {
        this.documentTest.controls['path'].setErrors(extensionValidationResult);
      }
    }
  }

  filetoS3(file: File) {
    const formData = new FormData();
    formData.append('formFile', file, file.name);
    formData.append('keyPath', 'UploadedDocument/Insurance');
    this.subscription.add(
      this.insuranceService.UploadFileToS3(formData).subscribe((result: any) => {
        this.uploadedDocumentPath = this.cleanPath(result.path);
        this.documentTest.controls['path'].setValue(this.uploadedDocumentPath);
      })
    );
  }

  cleanPath(path: string): string {
    return path.replace(/\/\//g, '/');
  }

  saveData() {
    if (this.documentTest.valid) {
      const objSave = Object.assign({});
      objSave.documentGroupId = this.documentTest.controls['documentGroupId'].value,
        objSave.path = this.uploadedDocumentPath,
        objSave.fileName = this.documentTest.controls['fileName'].value,
        objSave.header = this.documentTest.controls['header'].value,
        this.subscription.add(
          this.insuranceService.SaveDocumentFile(objSave).subscribe(() => {
            this.documentTest.reset()
            this.toasterService.success("Data saved successfully.");
            this.loadDocumentFile();
          })
        );
    } else {
      this.insuranceService.validateAllFormFields(this.documentTest);
    }
  }

  downloadFile(Path) {
    let objFileInfo = Object.assign({});
    objFileInfo.keyName = Path;
    this.insuranceService
      .DownloadFileFromS3(objFileInfo)
      .subscribe((res: any) => {
        var blob = new Blob([res]);
        var url = window.URL.createObjectURL(blob);
        var a = document.createElement("a");
        a.href = url;
        var filename = Path.split('/').pop();
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
      });
  }

  clearData() {
    this.documentTest.reset();
  }

  remove(documentFileId: any) {
    this.confirMationDialogService
      .confirm(
        "Delete Document",
        "Are you sure that you want to delete this permanently?",
        "Yes",
        "No"
      )
      .then((r) => {
        if (r) {
          this.subscription.add(
            this.insuranceService
              .DeleteDocumentFile(documentFileId)
              .subscribe(() => {
                this.documentTest.reset()
                this.toasterService.success("Document Deleted successfully.");
                this.loadDocumentFile();
              })
          );
        }
      });
  }

  toggleArchive(dataItem: any) {
    dataItem.isArchived = dataItem.isArchived;
    this.subscription.add(
      this.insuranceService.ArchiveDocumentFile(dataItem).subscribe(() => {
        this.toasterService.success("Document archive status updated successfully.");
      })
    );
  }
}
