<div class="modal-header">
    <div class="row justify-content-between align-items-center">
        <div class="col-md-auto">
            <div class="mb-0">Add Announcement
            </div>
        </div>
        <div class="col-md-auto">
            <span class="material-symbols-outlined cursor-pointer" (click)="closeDialog()">
                close
            </span>
        </div>
    </div>
</div>
<div class="modal-body">
    <form novalidate [formGroup]="announcementfrom">
        <div class="row mt-2">
            <div class="form-group col-md-12">
                <div class="row">
                    <div class="form-group col-md-4">
                        <label>Message Live from</label>
                        <kendo-datepicker [format]="'dd/MM/yyyy'" placeholder="day/month/year"
                            formControlName="messagefromdate" [min]="currentDate" class="w-100"
                            (keydown)="onKeydown($event)">
                        </kendo-datepicker>
                        <div class="validation-msg-label"
                            *ngIf="announcementfrom.controls.messagefromdate.touched  && announcementfrom.controls.messagefromdate.errors?.required">
                            Required
                        </div>
                        <div class="validation-msg-label" *ngIf="announcementfrom.controls.messagefromdate.touched  && !announcementfrom.controls.messagefromdate.valid 
                                    && !announcementfrom.controls.messagefromdate.errors?.required">
                            Invalid
                        </div>
                    </div>
                    <div class="form-group col-md-4">
                        <label>Message Live to</label>
                        <kendo-datepicker [format]="'dd/MM/yyyy'" placeholder="day/month/year"
                            formControlName="messagetodate" [min]="currentDate" class="w-100"
                            (keydown)="onKeydown($event)">
                        </kendo-datepicker>
                        <div class="validation-msg-label"
                            *ngIf="announcementfrom.controls.messagetodate.touched  && announcementfrom.controls.messagetodate.errors?.required">
                            Required
                        </div>
                        <div class="validation-msg-label" *ngIf="announcementfrom.controls.messagetodate.touched  && !announcementfrom.controls.messagetodate.valid 
                                    && !announcementfrom.controls.messagetodate.errors?.required">
                            Invalid
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-group col-md-12">
                <label>Message Title</label>
                <input type="text" class="form-control" name="subject" formControlName="subject">
                <div class="validation-msg-label"
                    *ngIf="announcementfrom.controls.subject.touched  && announcementfrom.controls.subject.errors?.required">
                    Required
                </div>
                <div *ngIf="announcementfrom.controls['subject'].errors?.maxlength" class="validation-msg-label">
                    Maximum length is 200 characters
                </div>
                <div class="validation-msg-label"
                    *ngIf="announcementfrom.controls['subject'].touched  && announcementfrom.controls['subject'].errors?.whitespace">
                    Whitespace not allowed.
                </div>
            </div>
            <div class="form-group col-md-12">
                <label>Message Description</label>
                <!-- <div id="froala-editor" [froalaEditor]="imgOptions" [(froalaModel)]="message"></div> -->

                <kendo-textarea [rows]="10" resizable="vertical" style="height: 100px;"
                    formControlName="message"></kendo-textarea>

                <!-- <kendo-editor  formControlName="message" style="height: 400px;"></kendo-editor> -->
                <!-- <div class="validation-msg-label"
                    *ngIf="announcementfrom.controls.message.touched  && announcementfrom.controls.message.errors?.required">
                    Required
                </div> -->
                <div class="validation-msg-label"
                    *ngIf="announcementfrom.controls.message.touched && announcementfrom.controls.message.errors?.whitespace">
                    Whitespace not allowed.
                </div>
            </div>
        </div>
    </form>
</div>
<div class="modal-footer">
    <div class="row justify-content-end">
        <div class="col-md-auto if-no-label-28">
            <button class="btn btn-sm btn-primary" (click)="saveData()">Publish</button>
        </div>
        <div class="col-md-auto if-no-label-28">
            <button class="btn btn-sm btn-secondary" (click)="clearAnnouncementAll()">Cancel</button>
        </div>
    </div>
</div>