import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AddClaimComponent } from '@app/features-modules/claim/add-claim/add-claim.component';
import { InsuranceService } from '@app/services/insurance.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { getExactDateWithoutTime } from '@shared/utility';
import { InitPolicyStatus, PolicySubStatus } from '@shared/utility/enum';
import { ToasterService } from '@shared/utility/toaster.service';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-claim-report',
  templateUrl: './claim-report.component.html',
  styleUrl: './claim-report.component.css'
})
export class ClaimReportComponent implements OnInit, OnDestroy {
  public subscription = new Subscription();
  claimTest: FormGroup;
  productList: any[];
  insurersList: any[];
  statutsesList: any[];
  perlisList: any[]
  SummaryData: any[];
  isSystemAgent: boolean = false;
  // policySummaryData: any[];
  claimList: any;
  claimId: any;
  policyId: number;
  policyNumber;
  productCode;
  policyInsurer;
  policyStartDate;
  policyEndDate;
  // productId= 0;
  maxDate: Date;
  productId: number;
  redirectPolicyId: number;

  policy_Detail: any[];
  claimPerilList: any;
  periodTime: string = 'August - 2024';
  constructor(
    private insuranceService: InsuranceService,
    private toasterService: ToasterService,
    private modalService: NgbModal,
    private fb: FormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private oidcSecurityService: OidcSecurityService,
  ) { }

  ngOnInit() {
    this.loadFormData();
    this.initForm();
    this.loadGridData();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  initForm() {
    this.claimTest = this.fb.group({
      productId: [null],
      insurersId: [null],
      statutsesId: [null],
      perlisId: [null],
      claimId: [null],
      ins_adj: [null],
      proposer: [null],
      policy: [null],
    });
  }

  onClaimIdInput(event: any): void {
    const input = event.target.value;
    const alphanumericInput = input.replace(/[^0-9]/g, '');
    if (input !== alphanumericInput) {
      this.claimTest.get('claimId').setValue(alphanumericInput);
    }
  }

  getExactDateWithoutTime(date: any) {
    return getExactDateWithoutTime(date);
  }

  EditClaim(dataItem: any) {
    const modalref = this.modalService.open(AddClaimComponent, {
      backdrop: "static",
      backdropClass: "modal-on-modal",
      windowClass: "modal-on-modal-dialog",
      centered: true,
      size: "lg",
    });

    modalref.componentInstance.claimId = (dataItem.duplicateOf) ? dataItem.duplicateOf : dataItem.claimId;
    modalref.componentInstance.policyId = dataItem.policyDetail.policyId;
    modalref.componentInstance.policyNumber = dataItem.policyDetail.policyNumber;
    modalref.componentInstance.policyInsurer = dataItem.policyDetail.policyInsurerName;
    modalref.componentInstance.productCode = dataItem.policyDetail.productCode;
    modalref.componentInstance.policyStartDate = dataItem.policyDetail.policyStartDate;
    modalref.componentInstance.policyEndDate = dataItem.policyDetail.policyEndDate;
    modalref.componentInstance.productId = dataItem.policyDetail.productId;
    modalref.componentInstance.isEditMode = true;
    modalref.result.then(
      (result) => {
        if (result === true) {
          this.loadGridData();
        }
      },
      (reason) => {
        console.log('Modal dismissed with reason: ', reason);
      }
    );
  }


  getQuote(dataItem: any) {
    this.reDirectDashboard(dataItem);
  }

  reDirectDashboard(dataItem: any): void {
    let encryptedqueryparams: { policyId: number };
    encryptedqueryparams = { policyId: dataItem.policyDetail.policyId }
    const url = this.router.serializeUrl(
      this.router.createUrlTree(["/product/policy-dashboard"], {
        queryParams: {
          data: this.insuranceService.encryptData(encryptedqueryparams),
        },
        relativeTo: this.activatedRoute.root,
      })
    );
    window.open(url, '_blank');
  }

  loadFormData() {
    this.subscription.add(
      this.insuranceService.GetClaimReportFormData().subscribe((data: any): void => {
        this.productList = data.products;
        this.insurersList = data.insurers;
        this.statutsesList = data.statues;
        this.perlisList = data.perils;
      })
    )
  }

  reset() {
    this.claimTest.reset();
    this.loadGridData();
  }

  loadGridData() {
    let objFileInfo = Object.assign({});
    objFileInfo.productId = this.claimTest.controls.productId.value ? this.claimTest.controls.productId.value : null;
    objFileInfo.insurerName = this.claimTest.controls.insurersId.value ? this.claimTest.controls.insurersId.value : null;
    objFileInfo.statuesId = this.claimTest.controls.statutsesId.value ? this.claimTest.controls.statutsesId.value : null;
    objFileInfo.perilId = this.claimTest.controls.perlisId.value ? this.claimTest.controls.perlisId.value : null;
    objFileInfo.proposer = this.claimTest.controls.proposer.value ? this.claimTest.controls.proposer.value : null;
    objFileInfo.shortPolicyNumber = this.claimTest.controls.policy.value ? this.claimTest.controls.policy.value : null;
    objFileInfo.insAdjRefrance = this.claimTest.controls.ins_adj.value ? this.claimTest.controls.ins_adj.value : null;
    objFileInfo.claimId = this.claimTest.controls.claimId.value ? this.claimTest.controls.claimId.value : null;
    this.subscription.add(
      this.insuranceService.GetClaimsForReport(objFileInfo).subscribe((data: any): void => {
        this.SummaryData = data.payload;
      })
    )
  }
}
