import { ChangeDetectionStrategy, NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ContentLayoutComponent } from './features-modules/layout/content-layout/content-layout.component';
import { AuthGuard } from '@shared/guards/auth.guard';
import { AutoLoginComponent } from './auth/auto-login/auto-login.component';
import { IntermediateComponent } from './features-modules/layout/intermediate/intermediate.component';
import { ForbiddenComponent } from './forbidden/forbidden.component';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';
import { NewPolicyComponent } from './new-policy/new-policy.component';
import { ManageExistingBusinessComponent } from './manage-existing-business/manage-existing-business.component';
import { BankStatementComponent } from './bank-statement/bank-statement.component';
import { AgentListComponent } from './agent-list/agent-list.component';
import { BrokerReconciliationComponent } from './broker-reconciliation/broker-reconciliation.component';
import { SearchPolicyComponent } from './features-modules/product/search-policy/search-policy.component';

import { SilentRenewComponent } from './silent-renew/silent-renew.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { AgentDashboardComponent } from './agent-dashboard/agent-dashboard.component';

import { DiaryDetailComponent } from './features-modules/diary/diary-detail/diary-detail.component';
import { AnnouncementListComponent } from './announcement/announcement-list/announcement-list.component';
import { DocumentGroupComponent } from './features-modules/document-group/document-group.component';
import { DocumentConfigurationComponent } from './document-configuration/document-configuration.component';

import { OpenMarketTransactionComponent } from './open-market-transaction/open-market-transaction.component';

import { UserprofileComponent } from './features-modules/user-management/userprofile/userprofile.component';

import { ConfigurationComponent } from './configuration/configuration.component';
import { AddClaimComponent } from './features-modules/claim/add-claim/add-claim.component';

import { ChangePasswordComponent } from './features-modules/user-management/change-password/change-password.component';
import { QebCancellationComponent } from './qeb-cancellation/qeb-cancellation.component';
import { ClaimReportComponent } from './claim-report/claim-report.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { SevenDaysPolicyReportComponent } from './features-modules/report/seven-days-policy-report/seven-days-policy-report.component';
import { PaymentInfoComponent } from './features-modules/payment-info/payment-info.component';



const routes: Routes = [
  {
    path: "",
    component: AgentDashboardComponent,
  },
  // {
  //   path: "login",
  //   component: LoginComponent,
  // },
  {
    path: 'external',
    children: [
      {
        path: '',
        loadChildren: () =>
          import('src/app/external-link/external-link.module').then(m => m.ExternalLinkModule)
      },
    ],
    // canActivate: [AuthGuard]
  },

  // {
  //   path: 'agent',
  //   component: ContentLayoutComponent,
  //   children: [
  //     {
  //       path: '',
  //       loadChildren: () =>
  //         import('src/app/features-modules/AgentManagement/agent-management.module').then(m => m.AgentManagementModule)
  //     },
  //   ],
  //   canActivate: [AuthGuard]
  // },
  {
    path: 'agents',
    component: ContentLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('src/app/features-modules/Agents-Management/agents-management.module').then(m => m.AgentsManagementModule)
      },
    ],
    canActivate: [AuthGuard]
  },
  {
    path: 'users',
    component: ContentLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('src/app/features-modules/user-management/user-management.module').then(m => m.UserManagementModule)
      },
    ],
    canActivate: [AuthGuard]
  },

  {
    path: 'product',
    component: ContentLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('src/app/features-modules/product/product.module').then(m => m.ProductModule)
      },
    ],
    canActivate: [AuthGuard]
  },
  {
    path: 'property-owner',
    component: ContentLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('src/app/features-modules/product/property-owners/property-owners.module').then(m => m.PropertyOwnersModule)
      },
    ],
    canActivate: [AuthGuard]
  },
  {
    path: 'claim',
    component: ContentLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('src/app/features-modules/claim/claim.module').then(m => m.ClaimModule),

      },

    ],
    canActivate: [AuthGuard]
  },
  {
    path: '',
    loadChildren: () =>
      import('src/app/auth/auth.module').then(m => m.AuthModule)
  },

  {
    path: 'diary',
    component: ContentLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('src/app/features-modules/diary/diary.module').then(m => m.DiaryModule)
      },
    ],
    canActivate: [AuthGuard]
  },


  
  {
    path: "new-policy",
    component: NewPolicyComponent,
  },
  {
    path: "add-claim",
    component: AddClaimComponent,
  },

  {
    path: "manage-existing-business",
    component: ManageExistingBusinessComponent,
  },
  {
    path: "search-policy",
    component: SearchPolicyComponent,
  },
  {
    path: "dashboard",
    component: DashboardComponent,
  },
  {
    path: "agent-dashboard",
    component: AgentDashboardComponent,
  },
  {
    path: "rpt-bank-statement",
    component: BankStatementComponent,
  },
  {
    path: "agent-list",
    component: AgentListComponent,
  },
  {
    path: "diary-detail",
    component: DiaryDetailComponent,
  },
  {
    path: "announcement-list",
    component: AnnouncementListComponent,
  },
  {
    path: "configuration",
    component: ConfigurationComponent,
  },
  {
    path: "document-group",
    component: DocumentGroupComponent,
  },
  {
    path: "document-configuration",
    component: DocumentConfigurationComponent,
  },
  {
    path: "qeb-cancellation",
    component: QebCancellationComponent,
  },  
  
  {
    path: "claim-report",
    component: ClaimReportComponent,
  },
  {
    path: "app-userprofile",
    component: UserprofileComponent,
  },

  {
    path: "app-change-password",
    component: ChangePasswordComponent,
  },
  // {
  //   path: 'utility',
  //   component: ContentLayoutComponent,
  //   children: [
  //     {
  //       path: '',
  //       loadChildren: () =>
  //         import('src/app/features-modules/search/search.module').then(m => m.SearchModule)
  //     },
  //   ],
  //   canActivate: [AuthGuard]
  // },
  { path: '', component: AutoLoginComponent },
  { path: 'autologin', component: AutoLoginComponent },
  { path: 'intermediate', component: IntermediateComponent },
  { path: 'forbidden', component: ForbiddenComponent },
  { path: 'unauthorized', component: UnauthorizedComponent },
  // { path: 'logoutMain', component: LogoutMainComponent },
  { path: 'home', component: IntermediateComponent },
  { path: "silent-renew", component: SilentRenewComponent },
  {
    path: 'document-manager',
    children: [
      {
        path: '',
        loadChildren: () =>
          import('src/app/document-manager/document-manager.module').then(m => m.DocumentManagerModule)
      },
    ],
    // canActivate: [AuthGuard]
  },
  { path: "open-market-transaction", component: OpenMarketTransactionComponent },
  { path: "contact-us", component: ContactUsComponent },
  { path: "payment-info", component: PaymentInfoComponent },
  {
    path: 'product-tm',
    component: ContentLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('./features-modules/product-tm/product-tm.module').then(m => m.ProductTmModule)
      },
    ],
    canActivate: [AuthGuard]
  },
  {
    path: 'report',
    component: ContentLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./features-modules/report/report.module').then(m => m.ReportModule)
      },
    ],
     canActivate: [AuthGuard]
  },
  { 
    path: 'export-email',
    component: ContentLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('./external-email/external-email.module').then(m => m.ExternalEmailModule) },
    ],
    canActivate: [AuthGuard]
 },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
