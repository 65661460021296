<div class="card h-100">
    <div class="card-body">
        <button type="button" *ngIf="backBtnPath" (click)="back()" class="btn btn-primary">
            {{backBtnText}}
        </button>
        <div class="form-row mt-3">
            <div class="col-md-12">
                <div class="alert card-gray text-center">
                    <p class="form-group mb-0 text-primary fs-6">Reconciliation No. {{paymentDetail?.acPaymentId}}</p>
                </div>
            </div>
        </div>
        <!-- <a class="btn btn-xs btn-warning" href="report/creditor-reconciliation">&lt;&lt;&lt;&lt; Reconciliation Page</a> -->
        <div class="row input-without-lbl-gray">
            <div class="col-md-6">
                <div class="gray-input-box d-flex justify-content-between">
                    <label>Broker :</label>
                    <label class="font-weight-semibold">
                        {{paymentDetail?.brokerCode}}
                    </label>
                </div>
            </div>
            <div class="col-md-6">
                <div class="gray-input-box d-flex justify-content-between">
                    <label>Write Off :</label>
                    <label class="font-weight-semibold">
                        {{ paymentDetail?.writeoffAmount | currency:' ':'symbol':'0.2-2' }}

                    </label>
                </div>
            </div>
            <div class="col-md-6">
                <div class="gray-input-box d-flex justify-content-between">
                    <label>Reconciliation Date :</label>
                    <label class="font-weight-semibold">
                        {{paymentDetail?.paymentDate | date: 'dd/MM/yyyy' }}
                    </label>
                </div>
            </div>
            <div class="col-md-6">
                <div class="gray-input-box d-flex justify-content-between">
                    <label>Payment Type :</label>
                    <label class="font-weight-semibold">
                        {{paymentDetail?.paymentType}}
                    </label>
                </div>
            </div>
            <div class="col-md-6">
                <div class="gray-input-box d-flex justify-content-between">
                    <label>Reconciliation Amount :</label>
                    <label class="font-weight-semibold">
                        {{ amountReconcil | currency:' ':'symbol':'0.2-2' }}
                    </label>
                </div>
            </div>
            <div class="col-md-6">
                <div class="gray-input-box d-flex justify-content-between">
                    <label class="col-md-auto">Cheque Number :</label>
                    <label class="font-weight-semibold text-break">
                        {{paymentDetail?.chequeNumber}}
                    </label>
                </div>
            </div>
            <div class="col-md-6">
                <div class="gray-input-box d-flex justify-content-between">
                    <label>{{amountLabel}} :</label>
                    <label class="font-weight-semibold">
                        {{paymentDetail?.paymentAmount| currency:' ':'symbol':'0.2-2'}}
                    </label>
                </div>
            </div>
            <div class="col-md-6">
                <div class="gray-input-box d-flex justify-content-between">
                    <label>Created By :</label>
                    <label class="font-weight-semibold">
                        {{paymentDetail?.userName}}
                    </label>
                </div>
            </div>
            <div class="col-md-6">
                <div class="gray-input-box d-flex justify-content-between">
                    <label>Bank ID :</label>
                    <label class="font-weight-semibold cursor-pointer text-primary">
                        <a (click)="openDetail(paymentDetail)">{{paymentDetail?.bankID}}</a>
                    </label>
                </div>
            </div>
            <div class="col-md-6">
                <div class="gray-input-box d-flex justify-content-between">
                    <label>Bank Date :</label>
                    <label class="font-weight-semibold">
                        {{(paymentDetail?.bankDate)?(paymentDetail?.bankDate| date: 'dd/MM/yyyy') : '-'}}
                    </label>
                </div>
            </div>


        </div>


        <div class="bg-white policy-box mt-5" style="margin-top: 30px;">
            <div class="data-header">{{gridTitle}}</div>
            <div class="row">
                <div class="col-md-12">
                    <kendo-grid [pageable]="false" [sortable]="true" [resizable]="true"
                        [data]="paymentDetail?.transactions">
                        <kendo-grid-column field="acTransactionId" title="Trans Id" [headerStyle]="{ width: '100px' }"
                            [style]="{ width: '100px' }">
                            <ng-template kendoGridCellTemplate let-dataItem>
                                <a (click)="openTransactions(dataItem)">{{dataItem?.acTransactionId}}</a>
                            </ng-template>
                        </kendo-grid-column>
                        <kendo-grid-column field="transactionDate" title="Trans Date" [headerStyle]="{ width: '150px' }"
                            [style]="{ width: '150px' }">
                            <ng-template kendoGridCellTemplate let-dataItem>
                                {{ dataItem.transactionDate | date: "dd/MM/yyyy" }}
                            </ng-template>
                        </kendo-grid-column>
                        <kendo-grid-column field="policyNumber" title="Policy" [headerStyle]="{ width: '100px' }"
                            [style]="{ width: '100px' }">
                        </kendo-grid-column>
                        <kendo-grid-column field="proposer" title="Proposer" [headerStyle]="{ width: '100px' }"
                            [style]="{ width: '100px' }">
                        </kendo-grid-column>
                        <kendo-grid-column field="transType" title="Type" [headerStyle]="{ width: '100px' }"
                            [style]="{ width: '100px' }">
                        </kendo-grid-column>
                        <kendo-grid-column field="payment" title="Paid Amount" [headerStyle]="{ width: '100px' }"
                            [style]="{ width: '100px' }">
                            <ng-template kendoGridCellTemplate let-dataItem>
                                <span *ngIf="dataItem.isTotal==true"><strong>{{ dataItem.payment | currency:'
                                        ':'symbol':'0.2-2' }}</strong></span>
                                <span *ngIf="dataItem.isTotal!=true">{{ dataItem.payment | currency:'
                                    ':'symbol':'0.2-2' }}</span>
                            </ng-template>
                        </kendo-grid-column>
                        <kendo-grid-column *ngIf="this.paymentDetail?.paymentTypeId != 2 && this.paymentDetail?.paymentTypeId != 4" field="writeOff" title="WriteOff" [headerStyle]="{ width: '100px' }"
                            [style]="{ width: '100px' }">
                            <ng-template kendoGridCellTemplate let-dataItem>
                                <span *ngIf="dataItem.isTotal==true"><strong>{{ dataItem.writeOff | currency:'
                                        ':'symbol':'0.2-2' }}</strong></span>
                                <span *ngIf="dataItem.isTotal!=true">{{ dataItem.writeOff | currency:'
                                    ':'symbol':'0.2-2' }}</span>
                            </ng-template>
                        </kendo-grid-column>
                        <kendo-grid-column field="actualPaid" *ngIf="this.paymentDetail?.paymentTypeId != 2 && this.paymentDetail?.paymentTypeId != 4" title="Actual Paid" [headerStyle]="{ width: '100px' }"
                            [style]="{ width: '100px' }">
                            <ng-template kendoGridCellTemplate let-dataItem>
                                <span *ngIf="dataItem.isTotal==true"><strong>{{ dataItem.actualPaid | currency:'
                                        ':'symbol':'0.2-2' }}</strong></span>
                                <span *ngIf="dataItem.isTotal!=true">{{ dataItem.actualPaid | currency:'
                                    ':'symbol':'0.2-2' }}</span>

                            </ng-template>
                        </kendo-grid-column>

                    </kendo-grid>
                </div>
            </div>

        </div>
    </div>
</div>