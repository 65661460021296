import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { InsuranceService } from '@app/services/insurance.service';
import { ConfirmationDialogService } from '@shared/components/confirmation-dialog/confirmation-dialog.service';
import { ToasterService } from '@shared/utility/toaster.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-qeb-cancellation',
  templateUrl: './qeb-cancellation.component.html',
  styleUrl: './qeb-cancellation.component.css'
})
export class QebCancellationComponent implements OnInit, OnDestroy {
  public subscription = new Subscription();
  periodTest: FormGroup;
  periodList: { text: string, value: string }[] = [];
  periodData: any[];
  periodId: any;

  constructor(
    private insuranceService: InsuranceService,
    private toasterService: ToasterService,
    private fb: FormBuilder,
  ) { }

  ngOnInit(): void {
    this.generateMonthList();
    this.loadPeriodList();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }  

  generateMonthList() {
    const startDate = new Date(2020, 0, 1); // January 1, 2020
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth();
    const currentYear = currentDate.getFullYear();

    let month = 0;
    let year = 2020;

    while (year < currentYear || (year === currentYear && month <= currentMonth)) {
      const monthName = this.getMonthName(month);
      this.periodList.push({
        text: `${monthName}-${year}`,
        value: `${month+1}-${year}`
      });
      month++;
      if (month === 12) {
        month = 0;
        year++;
      }
    }
    this.periodList.reverse();
    this.periodId = this.periodList[0];
  }
  
  periodChange(event) {
    if (event === undefined || event === null) {
      this.periodId = this.periodList[0];
    } else {
      this.periodId = event;
    }
  }

  getMonthName(monthIndex) {
    const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    return monthNames[monthIndex];
  }

  loadPeriodList() {
    let month = this.periodId.value.split('-')[0];
    let year = this.periodId.value.split('-')[1];
    this.subscription.add(
      this.insuranceService.GetCancelledPolicyLisyByMonth(month,year).subscribe((data: any): void => {
        this.periodData = data.payload;
      })
    )
  }

}
