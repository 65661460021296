import { NgModule } from '@angular/core';
import { AuthModule,AuthWellKnownEndpoints, LogLevel } from 'angular-auth-oidc-client';
import { environment } from '../../environments/environment.development';
const authWellKnownEndpoints: AuthWellKnownEndpoints = {
  issuer:environment.issuer,// "https://cognito-idp.eu-west-1.amazonaws.com/eu-west-1_XsBJ5Ueem",
  jwksUri:environment.issuer +"/.well-known/jwks.json",//https://cognito-idp.eu-west-1.amazonaws.com/eu-west-1_XsBJ5Ueem/.well-known/jwks.json",
  authorizationEndpoint: environment.stsServer + '/oauth2/authorize',
  tokenEndpoint: environment.stsServer + '/oauth2/token',
  endSessionEndpoint: environment.stsServer + '/logout?client_id='+environment.client_id+'&logout_uri=' + window.location.origin + "/autologin"+ '',
  checkSessionIframe: environment.stsServer + '/oauth2/checksession',
  revocationEndpoint: environment.stsServer + '/oauth2/revocation',
  introspectionEndpoint: environment.stsServer + '/oauth2/introspect',
  userInfoEndpoint: environment.stsServer + '/oauth2/userInfo'
 
};
@NgModule({
  imports: [
    AuthModule.forRoot({
      config:
        {
           authWellknownEndpoints : authWellKnownEndpoints,
           authority:  environment.stsServer,
          redirectUrl: window.location.origin + '/intermediate',
          postLogoutRedirectUri: window.location.origin + '/autologin',
          clientId:environment.client_id,            
          scope: 'openid profile email phone',
          responseType: 'code',
          silentRenew: true,
          useRefreshToken: true,
          logLevel: LogLevel.Debug,
          silentRenewUrl:window.location.origin + '/silent-renew',
        },
         
     
    }),
  ],
  exports: [AuthModule],
})
export class AuthConfigModule {}
