import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { GridModule, PDFModule, ExcelModule } from '@progress/kendo-angular-grid';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { BrowserAnimationsModule, NoopAnimationsModule } from "@angular/platform-browser/animations";
import { DateInputsModule } from "@progress/kendo-angular-dateinputs";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HttpServiceModule } from '@shared/async-services/http';
import { ComponentsModule } from '@shared/components';
import { ContainersModule } from '@shared/containers';
import { AuthModule } from 'angular-auth-oidc-client';
import { SimpleNotificationsModule } from 'angular2-notifications';
import { NgxFileDropModule } from 'ngx-file-drop';
import { ToastrModule } from 'ngx-toastr';
import { AuthConfigModule } from './auth/auth-config.module';
import { AuthGuard } from '@shared/guards/auth.guard';
import { CanDeactivateGuard } from '@shared/guards/can-deactivate.guard';
import { LoaderService } from '@shared/utility/loader.service';
import { ToasterService } from '@shared/utility/toaster.service';
import { AuthService } from './auth/auth.service';
import { DataService } from '@shared/async-services/data.service';
import { SharedModule } from '@shared/shared.module';
import { ConfirmationDialogComponent } from '@shared/components/confirmation-dialog/confirmation-dialog.component';
import { ContentLayoutComponent } from './features-modules/layout/content-layout/content-layout.component';
import { IntermediateComponent } from './features-modules/layout/intermediate/intermediate.component';
import { NavComponent } from './features-modules/layout/nav/nav.component';
import { ForbiddenComponent } from './forbidden/forbidden.component';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';
import { LoaderComponent } from '@shared/components/spinner/loader.component';
import { NewPolicyComponent } from './new-policy/new-policy.component';
import { BrokerReconciliationComponent } from './broker-reconciliation/broker-reconciliation.component';
import { BankStatementComponent } from './bank-statement/bank-statement.component';
import { ManageExistingBusinessComponent } from './manage-existing-business/manage-existing-business.component';
import { AgentListComponent } from './agent-list/agent-list.component';
import { QuoteDetailsDialogComponent } from './quote-details-dialog/quote-details-dialog.component';
import { EditPolicyQuoteComponent } from './edit-policy-quote/edit-policy-quote.component';
import { SearchPolicyComponent } from './features-modules/product/search-policy/search-policy.component';
import { GridDataPolicyService } from './services/gridData.policy.service';
// import { EditorModule } from "@progress/kendo-angular-editor";
import { SilentRenewComponent } from './silent-renew/silent-renew.component';
import { DocumentManagerModule } from './document-manager/document-manager.module';
import { AgentDashboardComponent } from './agent-dashboard/agent-dashboard.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { AboutInsurerComponent } from './dashboard/about-insurer/about-insurer.component';
import { FooterComponent } from './footer/footer.component';
import { GridDataDiaryService } from './services/gridData.diary.service';

import { AddAnnouncementComponent } from './announcement/add-announcement/add-announcement.component';
import { AnnouncementListComponent } from './announcement/announcement-list/announcement-list.component';
import { AnnouncementPopupComponent } from './announcement/announcement-popup/announcement-popup.component';

import { FroalaEditorModule, FroalaViewModule } from 'angular-froala-wysiwyg';
import { DocumentGroupComponent } from './features-modules/document-group/document-group.component';
import { CommonModule, DatePipe } from '@angular/common';
import { DocumentConfigurationComponent } from './document-configuration/document-configuration.component';
import { FileSelectModule, UploadModule } from '@progress/kendo-angular-upload';
import { OpenMarketTransactionComponent } from './open-market-transaction/open-market-transaction.component';
import { ConfigurationComponent } from './configuration/configuration.component';
import { GridDataDashboardDiaryService } from './services/gridData-dashboard-DiaryService';
import { QebCancellationComponent } from './qeb-cancellation/qeb-cancellation.component';
import { ClaimReportComponent } from './claim-report/claim-report.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { PaymentInfoComponent } from './features-modules/payment-info/payment-info.component';
import { GridDataExportEmailService } from './services/gridData.exportEmail.service';


@NgModule({
  declarations: [			
    AppComponent,
    HeaderComponent,
    LoaderComponent,
    ConfirmationDialogComponent,
    ContentLayoutComponent,
    NavComponent,
    UnauthorizedComponent,
    ForbiddenComponent,
    IntermediateComponent,
    NewPolicyComponent,
    BrokerReconciliationComponent,
    BrokerReconciliationComponent,
    BankStatementComponent,
    ManageExistingBusinessComponent,
    AgentListComponent,
    QuoteDetailsDialogComponent,
    ConfigurationComponent,
    EditPolicyQuoteComponent,
    DocumentConfigurationComponent,
    SearchPolicyComponent,
    SilentRenewComponent,
    AgentDashboardComponent,
    DashboardComponent,
    AboutInsurerComponent,
    AddAnnouncementComponent,
    AnnouncementListComponent,
    AnnouncementPopupComponent,
    FooterComponent,
    OpenMarketTransactionComponent,
    QebCancellationComponent,
    ClaimReportComponent,
    ContactUsComponent,
    PaymentInfoComponent
   ],
  imports: [
    // EditorModule,
    BrowserModule,
    AppRoutingModule,
    PDFModule,
    ExcelModule,
    GridModule,
    LayoutModule,
    InputsModule,
    UploadModule,
    FileSelectModule,
    BrowserAnimationsModule,
    DropDownsModule,
    DateInputsModule,

    AuthModule,
    FormsModule,
    NoopAnimationsModule,
    ReactiveFormsModule,
    SharedModule,

    DocumentGroupComponent,  
    CommonModule,
    

    ComponentsModule,
    ContainersModule,
    HttpServiceModule.forRoot(),
    NgxFileDropModule,
    AuthConfigModule,
    ToastrModule.forRoot({
      positionClass: 'toast-top-center',
      preventDuplicates: true,
      maxOpened: 1,
      autoDismiss: true,
      timeOut: 1000
      // positionClass: 'inline'
    }),
    /** Third party modules */
    NgbModule,      
    SimpleNotificationsModule.forRoot(),
    DocumentManagerModule,

    FroalaEditorModule.forRoot(), FroalaViewModule.forRoot(),
  ],
  providers: [
    AuthService,
    AuthGuard,
    LoaderService,
    DataService,
    ToasterService,
    CanDeactivateGuard,
    GridDataPolicyService,
    GridDataDiaryService,
    GridDataExportEmailService,
    GridDataDashboardDiaryService,
    DatePipe,
    ],

  exports: [SharedModule],
  bootstrap: [AppComponent]
  // entryComponents: [
  //   QuoteDetailsDialogComponent,
  //   EditPolicyQuoteComponent,
  //   CreditReconciliationTransactionsComponent
  // ],
})
export class AppModule { }
