import { CommonModule } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { InsuranceService } from '@app/services/insurance.service';
import { ItemTemplateDirective } from '@progress/kendo-angular-dropdowns';
import { Path } from '@progress/kendo-drawing';
import { event } from 'jquery';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-document-group',
  standalone: true,
  imports: [CommonModule, FormsModule],
  templateUrl: './document-group.component.html',
  styleUrl: './document-group.component.css'
})
export class DocumentGroupComponent implements OnInit {
  public subscription = new Subscription();
  documentGroupdata: any[];
  documentGroupdataclone: any[];
  documentFiledata: any[];
  documentFiledataclone: any[];
  documentFiledataclone1: any[];
  isArchived: boolean = false;
  selectedDocumentGroupId: number = null;
  expandedIndex: number | null = null;

  constructor(private insuranceService: InsuranceService) { }

  ngOnInit() {
    this.loaddocumetnGroup();

  }
  toggleAccordion(index: number) {
    if (this.expandedIndex === index) {
      this.expandedIndex = null; // Collapse if clicked item is already expanded
    } else {
      this.expandedIndex = index; // Expand clicked item
      // Optionally, you can fetch document files or perform other actions here
    }
  }

  loaddocumetnGroup() {
    this.subscription.add(
      this.insuranceService.getAllDocumentGroup().subscribe((data: Array<any>): void => {
        this.documentGroupdata = data;
        this.documentGroupdataclone = Object.assign([], data);
        this.documentGroupdata.forEach(element => {
          this.GetdocumetnFile(element);
        });
      })
    )
  }

  getArchivedFiles(documentGroupId: string): any[] {
    return this.documentFiledata.filter(
      (doc) => doc.documentGroupId === documentGroupId && doc.isArchived
    );
  }

  GetdocumetnFile(item) {
    this.selectedDocumentGroupId = item.documentGroupId;
    this.subscription.add(
      this.insuranceService.getAllDocumentFile().subscribe((data: Array<any>): void => {
        this.documentFiledata = data;
        this.documentFiledataclone = Object.assign([], data);
        // Filter files based on 'Group data' documentFiledata
        if (this.documentFiledata) {
          this.documentFiledata = this.documentFiledataclone.filter(file =>
            file.documentGroupId === this.selectedDocumentGroupId &&
            (
              this.isArchived
                ? file.isArchived === true
                : (file.isArchived === false || file.isArchived === null)
            )
          );
        }
        // Dynamically update `isHide` for groups based on the filtered file data
        this.documentGroupdata.forEach(group => {
          group.isHide = !this.documentFiledataclone.some(file =>
            file.documentGroupId === group.documentGroupId &&
            (
              this.isArchived
                ? file.isArchived === true
                : (file.isArchived === false || file.isArchived === null)
            )
          );
        });
      }))
  }

  onArchiveCheckboxChange() {
    this.expandedIndex = null;
    this.documentFiledata = [];
    // Work on IsArchived changed
    this.documentGroupdata = this.documentGroupdataclone.filter(group =>
      this.documentFiledataclone.some(file =>
        file.documentGroupId === group.documentGroupId &&
        (
          this.isArchived
            ? file.isArchived === true
            : (file.isArchived === false || file.isArchived === null)
        )
      )
    );
    if (this.selectedDocumentGroupId) {
      this.GetdocumetnFile({ documentGroupId: this.selectedDocumentGroupId });
    }
  }

  openFile(Path) {
    let objFileInfo = Object.assign({});
    objFileInfo.keyName = Path;
    this.insuranceService
      .DownloadFileFromS3(objFileInfo)
      .subscribe((res: any) => {
        var url = window.URL.createObjectURL(res);
        window.open(url, "_blank");
      });
  }

  downloadFile(Path) {
    let objFileInfo = Object.assign({});
    objFileInfo.keyName = Path;
    this.insuranceService
      .DownloadFileFromS3(objFileInfo)
      .subscribe((res: any) => {
        var blob = new Blob([res]);
        var url = window.URL.createObjectURL(blob);
        var a = document.createElement("a");
        a.href = url;
        var filename = Path.split('/').pop();
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
      });
  }

}