<div class="modal-header">
    <div class="row justify-content-between align-items-center">
        <div class="col-md-auto">
            <div class="mb-0"><b>Optis Insurance</b>
            </div>
        </div>
        <div class="col-md-auto">
            <span class="material-symbols-outlined cursor-pointer" (click)="closeDialog()">
                close
            </span>
        </div>
    </div>
</div>
<div class="modal-body">

    <p><strong>Dear Broker,</strong></p>

    <p>Optis Insurance is delighted to announce that it has agreed a multi-year facility with AXA Insurance DAC to
        underwrite the following insurance products:</p>
    <ul>
        <li>Commercial Combined</li>
        <li>Combined Liability</li>
        <li>Real Estate</li>
    </ul>

    <p>With effect from Wednesday 10th August, all policies of insurance provided by Optis and previously underwritten
        by QBE Insurance with a renewal date of 07 September 2022 onward shall be underwritten by AXA Insurance DAC.
    </p>
    <p>At Optis, we recognise how important it is for our broker partners to have access to stable and secure capacity
        when recommending a product to their client. Accordingly, we wish to confirm that AXA Insurance DAC carries an
        A-rating from a number of major rating agencies. Further details can be found at <a target="_blank"
            href="https://www.axa.com/en/investor/financial-strength-ratings">https://www.axa.com/en/investor/financial-strength-ratings</a>
    </p>

    <p>With a view to aiding a smooth transition between insurers, we have enclosed the following documents that you may
        find useful: </p>
    <ul>
        <li>IPIDs for the three policies i.e. Commercial Combined; Combined Liability &amp; Real Estate</li>
        <li>Copies of AXA Policy Wordings</li>
        <li>Summary of key differences</li>
    </ul>


    <p>We wish to thank you for your support and look forward to continuing to provide you with an unrivalled service,
        secure in the knowledge that all our products are A-rated. Should you have any queries regarding the above,
        please feel free to contact us on 046 9481667 or e-mail us at <a href="mailto:info@optis.ie">info&#64;optis.ie</a>
    </p>

    <p>Yours sincerely,</p>
    <img width="200" src="../../../assets/images/Frankie_Signature.jpg"><br>

    __________________________<br>
    Frankie McCague<br>
    Chief Executive Officer

    <br><br>
    <hr>
    <strong>Policy Documents</strong><br>
    <ul>
        <li><a href="/appshared/CC/Documents/AxaWording.asp?v=202212" target="_blank">Commercial Combined Policy
                Wording</a></li>
        <li><a href="/appshared/CC/Documents/AxaIpid.asp?v=202209" target="_blank">Commercial Combined IPID</a></li>
        <li><a href="/class/elpl_n/Documents/AxaWording.asp?v=202212" target="_blank">Combined Liability Policy
                Wording</a></li>
        <li><a href="/class/elpl_n/Documents/AxaIpid.asp?v=202209" target="_blank">Combined Liability IPID</a></li>
        <li><a href="/rei/axa/documents/AxaWording.asp?v=202212" target="_blank">Real Estate Policy Wording</a></li>
        <li><a href="/rei/axa/documents/AxaIpid.asp?v=202209" target="_blank">Real Estate IPID</a></li>
    </ul>
    <ul>
        <li><a href="/appshared/Axa/Documents/SummaryOfDifferences.asp" target="_blank">Summary of Key Differences</a>
        </li>
    </ul>
</div>
<div class="modal-footer">
    <button class="btn btn-primary btn-sm" (click)="closeDialog()">Thank you</button>
</div>