import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { AuthService } from '@app/auth/auth.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-intermediate',
  templateUrl: './intermediate.component.html',
  styleUrls: ['./intermediate.component.css'],
})
export class IntermediateComponent implements OnInit {
  userData$: Observable<any>;
  constructor(
    private router: Router,
    public oidcSecurityService: OidcSecurityService,
    private authService: AuthService,
  ) { }

  ngOnInit(): void {
    this.loadData()
  }
 
  loadData() {
    this.oidcSecurityService.isAuthenticated$.subscribe((d: any): any => {
      if (d != null && d.isAuthenticated) {
        // this.authService.getuseInfo().subscribe((env_data) => {
        //   console.log("---------intermediate----");
        //   console.log(env_data);
        //   if(env_data.isSystemAgent)
        //   {
        //     this.router.navigate(["agent-dashboard"])
        //   }
        //   else
        //   {
        //     this.router.navigate(["dashboard"])

        //   }

        //     window.sessionStorage.setItem("isAuthorized","true");
        // });
      }
    })
  }
}
