<form novalidate [formGroup]="claimTest">
  <div class="card h-100">
    <div class="card-body">
      <h5 class="form-group mb-2">Claim Listing</h5>
      <div class="row mb-2 g-2 align-items-center">
        <div class="form-group col-md-3">
          <label>All Product</label>
          <kendo-combobox [data]="productList" formControlName="productId" [valuePrimitive]="true" [textField]="'text'"
            [valueField]="'value'" class="w-100" placeholder="Select Product">
          </kendo-combobox>
        </div>
        <div class="form-group col-md-3">
          <label>All Insurers</label>
          <kendo-combobox [data]="insurersList" formControlName="insurersId" [valuePrimitive]="true"
            [textField]="'text'" [valueField]="'text'" class="w-100" placeholder="Select Insurers">
          </kendo-combobox>
        </div>
        <div class="form-group col-md-3">
          <label>All Statuses</label>
          <kendo-combobox [data]="statutsesList" formControlName="statutsesId" [valuePrimitive]="true"
            [textField]="'text'" [valueField]="'value'" class="w-100" placeholder="Select Statuses">
          </kendo-combobox>
        </div>
        <div class="form-group col-md-3">
          <label>All Perils</label>
          <kendo-combobox [data]="perlisList" formControlName="perlisId" [valuePrimitive]="true" [textField]="'text'"
            [valueField]="'value'" class="w-100" placeholder="Select Perils">
          </kendo-combobox>
        </div>
        <div class="form-group col-md">
          <label>Claim Id</label>
          <input type="text" class="form-control" formControlName="claimId" placeholder="Claim Id"
            (input)="onClaimIdInput($event)" />
        </div>
        <div class="form-group col-md">
          <label>Ins/Adj Ref.</label>
          <input type="text" class="form-control" formControlName="ins_adj" placeholder="Ins/Adj Ref." />
        </div>
        <div class="form-group col-md">
          <label>Proposer</label>
          <input type="text" class="form-control" formControlName="proposer" placeholder="Proposer" />
        </div>
        <div class="form-group col-md">
          <label>Short Policy</label>
          <input type="text" class="form-control" formControlName="policy" placeholder="Short Policy #" />
        </div>
        <div class="form-group col-md-auto col-auto if-no-label">
          <button type="submit" class="btn btn-primary" (click)="loadGridData()">Search</button>
        </div>
        <div class="form-group col-md-auto col-auto if-no-label">
          <button type="submit" class="btn btn-primary" (click)="reset()">Reset</button>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 custom-grid-plus pc-grid">
          <kendo-grid [pageable]="true" [kendoGridBinding]="SummaryData" [sortable]="false" [pageSize]="10"
            [scrollable]="'scrollable'">
            <kendo-grid-column-group title="Claim Summary" [columnMenu]="false">
              <kendo-grid-column field="claimId" title="Id" [width]="40">
                <ng-template kendoGridCellTemplate let-dataItem>
                  <a (click)="EditClaim(dataItem)">{{dataItem?.claimId}}</a>
                </ng-template>
              </kendo-grid-column>
              <kendo-grid-column field="statusDescription" title="Status" [width]="70">
              </kendo-grid-column>
              <kendo-grid-column field="insCompany" title="Insurer" [width]="70">
              </kendo-grid-column>
              <kendo-grid-column field="claimPerilName" title="Peril" [width]="80">
              </kendo-grid-column>
              <kendo-grid-column field="incidentDate" title="Incident Date" [width]="80">
                <ng-template kendoGridCellTemplate let-dataItem>
                  {{getExactDateWithoutTime(dataItem?.incidentDate) | date: 'dd/MM/yyyy'}}
                </ng-template>
              </kendo-grid-column>
              <kendo-grid-column field="reserved" title="Reserved" [width]="70">
              </kendo-grid-column>
              <kendo-grid-column field="setteled" title="Settled" [width]="70">
              </kendo-grid-column>
              <kendo-grid-column field="total" title="Total" [width]="70">
              </kendo-grid-column>
            </kendo-grid-column-group>
            <kendo-grid-column-group title="Policy Summary" [columnMenu]="false">
              <kendo-grid-column field="policyDetail.productName" title="Product" [width]="80">
              </kendo-grid-column>
              <kendo-grid-column field="policyDetail.policyNumber" title="Policy#" [width]="100">
                <ng-template kendoGridCellTemplate let-dataItem>
                  <a (click)="getQuote(dataItem)">{{dataItem.policyDetail.policyNumber}}</a>
                </ng-template>
              </kendo-grid-column>
              <kendo-grid-column field="policyDetail.inceptionDate" title="Inception Date" [width]="80">
                <ng-template kendoGridCellTemplate let-dataItem>
                  {{getExactDateWithoutTime(dataItem.policyDetail.inceptionDate) | date: 'dd/MM/yyyy'}}
                </ng-template>
              </kendo-grid-column>
              <kendo-grid-column field="policyDetail.businessName" title="Insured" [width]="150">
              </kendo-grid-column>
            </kendo-grid-column-group>
          </kendo-grid>
        </div>
      </div>
    </div>
  </div>
</form>