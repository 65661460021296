
<div class="card h-100">
    <div class="card-body dash-icons">
   <!-- Add the new button -->
   <div class="mt-3">
    
    <div class="row mt-0">
        
            <div class="policy-box">
                <div class="row gy-4">
                    <div id="header">

                        <img src="/assets/images/optis-logo.svg" height="80" alt="Optis Insurance">
                    
                          <div id="tel">
                    
                    <div id="telPhone">046 9481667</div>
                    
                       
                    <div id="telHours">Mon - Thur 9am - 5.30pm<br>Fri 9am - 5pm</div>
                    
                          </div>
                      
                    
                        
                    </div>
                    <div id="cobpage">	
                    
                                <div class="boxcontainer" id="box1">
                                    <div class="dataLineHeader">
                                        <span class="lineheader">Contact Details</span>         
                                    </div>
                                    
                                    <div class="dataLine">
                                        <span class="labelArea"><p>Unit 1</p>
                                                                <p>Knightsbrook Square</p>
                                                                <p>Knightsbrook</p>
                                                                <p>Trim</p>
                                                                <p>Co.Meath</p>
                             </span>
                                        <span class="infoArea" id="infopropname">&nbsp;</span>
                                        <div class="valueArea"><p>Tel: 00353 46 9481667</p>
                                                                <p>Fax: 00353 46 9564500</p>
                                                                <p><a href="mailto:info@optis.ie">optis general email</a></p>
                                    </div>          
                                    
                                </div>	<!-- end box 1 -->
                        
                        
                               </div>                                                                                                                                        
                        </div>
                </div>
            </div>
        </div>
  </div>
</div>
</div>

