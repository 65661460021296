import { Component, HostListener, ViewChild } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { AuthService } from '@app/auth/auth.service';
import { InsuranceService } from '@app/services/insurance.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationDialogService } from '@shared/components/confirmation-dialog/confirmation-dialog.service';
import { ToasterService } from '@shared/utility/toaster.service';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { Observable, filter } from 'rxjs';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
  hamburgerActive = false;
  showHeader: boolean = true;
  userData$: Observable<any>;
  isToggle: boolean = false;
  isLogin = false;
  username = "";
  isAgentUser = false;
  messageDisplay: string;
  isShowonSite: any = false;
  constructor(private router: Router,
    public authService: AuthService,
    private modalService: NgbModal,
    private confirationService: ConfirmationDialogService,
    public oidcSecurityService: OidcSecurityService, private insuranceService: InsuranceService, private tosterService: ToasterService
  ) { }

  ngOnInit(): void {
    this.router.events.pipe(
      filter((event): event is NavigationEnd => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      // Determine if the current route is the login page
      this.showHeader = !event.url.includes('/login'); // Adjust the URL as needed
    });

    this.authService.isLogin.subscribe(t => {

      this.isLogin = t;
      if (this.isLogin && this.authService.authentication) {
        this.username = this.authService.authentication.fullname;
        this.isAgentUser = this.authService.authentication.isSystemAgent;
      }

    });

    this.oidcSecurityService.isAuthenticated$.subscribe((result) => {
      this.isLogin = result.isAuthenticated;
      if (this.isLogin && this.authService.authentication) {
        this.username = this.authService.authentication.fullname;
        this.isAgentUser = this.authService.authentication.isSystemAgent;
        let isshowAlert = window.sessionStorage.getItem('isShowonSite')
        if (isshowAlert != 'false') {
          this.GetMessageAlertSite();
        }

      }
    });
  }
  GetMessageAlertSite() {
    this.insuranceService.GetMessageAlertSite().subscribe((data: any): void => {
      if (data.payload.isShowonSite) {
        this.isShowonSite = data.payload.isShowonSite;
        this.messageDisplay = data.payload.message;
        if (data.payload.timeDurationSecond != null && data.payload.timeDurationSecond != undefined) {
          const delayInMilliseconds = data.payload.timeDurationSecond * 1000;
          this.delayedExecution(delayInMilliseconds);
        }
      }
    });
  }
  getInceptAccess() {
    var access = this.getMainMenuclaims(["CANVIEWSTOPQOUTECREATE"]);
    if (access == 'true')
      return false;

    return this.getMainMenuclaims(['CANVIEWBBGUHOUSE', 'CANVIEWCOMCOMBINED', 'CANVIEWCONTRAALLRISK', 'CANVIEWELPL', 'CANVIEWOFFICE', 'CANVIEWPERSNLACCIDENT'
      , 'CANVIEWPROFINDEM', 'CANVIEWPROPOWNERS', 'CANVIEWPUBHOUSEREST', 'CANVIEWSELFBUILD', 'CANVIEWSHOPRETAIL', 'CANVIEWREALESTATE']);
  }
  showonSiteMsg() {
    this.isShowonSite = false;
    window.sessionStorage.setItem("isShowonSite", "false");
  }
  async delayedExecution(delay: number) {
    await this.delay(delay);
    this.delayedFunction();
  }
  delay(ms: number): Promise<void> {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
  delayedFunction() {
    this.isShowonSite = false;
    window.sessionStorage.setItem("isShowonSite", "false");
  }

  opendashoard() {
    if (this.authService.authentication.isSystemAgent) {
      this.router.navigate(["dashboard"]);
    }
    else {
      this.router.navigate(["agent-dashboard"]);
    }

  }
  canaccess() {


  }
  @ViewChild('dropdown') dropdown: any;

  closeDropdown(event: MouseEvent) {
    const target = event.target as HTMLElement;
    if (!target.classList.contains('dropdown-item')) {
      event.stopPropagation();
      event.preventDefault();
    }
  }

  redirct() {
    this.router.navigate(['dashboard'])

  }
  logout() {

    this.confirationService
      .confirm(
        "Confirm",
        "Are you sure you want to logout?", "Yes", "No"
      ).then((r) => {
        if (r) {
          this.authService.localLogout();
          this.oidcSecurityService.logoff().subscribe((result) => this.router.navigateByUrl('/'));
          window.sessionStorage.clear();
          window.localStorage.clear();
        }
      }
      )
  }

  getMainMenuclaims(claimNameArray) {

    const auth = JSON.parse(window.sessionStorage.getItem('authentication'));
    if (auth != undefined && auth != null && auth.claims != undefined && auth.claims != null && auth.claims.length > 0) {
      for (var i = 0; i < claimNameArray.length; i++) {
        var isExists = auth.claims.find(a => a.toString().toLowerCase() === claimNameArray[i].toLowerCase());
        if (isExists != "" && isExists != undefined) {
          return "'" + isExists + "'";
        }
      }
    }


    // if (auth && auth.userSystem && claimName) {
    //   const isExists = auth.claims.find(a => a.toString().toLowerCase() === claimName.toLowerCase());
    //   return isExists && isExists !== undefined && isExists !== null;
    // }
  }


}
