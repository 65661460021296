<div class="card h-100">
    <div class="card-body">
        <div class="row mt-0">
            <div class="col-md-8">
                <div class="policy-box">
                    <div class="data-header">
                        Policy Details
                    </div>
                    <div class="row mb-2 g-2 align-items-center">
                        <div class="col-md-5">
                            <label>Inception Date</label>
                            <kendo-datepicker class="w-100" placeholder="Select Date">
                            </kendo-datepicker>
                        </div>
                    </div>
                </div>
                <div class="policy-box mt-3">
                    <div class="data-header">
                        Business Details 123
                    </div>
                    <div class="row mb-2 gy-3 align-items-center">
                        <div class="col-md-5">
                            <label>Business Name</label>
                            <input type="text" class="form-control">
                        </div>
                        <div class="col-md-6">
                            <label>Business Address(enter the address or eircode and select the relevant result)</label>
                            <input type="text" class="form-control" placeholder="Eircode or address">
                        </div>
                        <div class="col-md-5">
                            <label>Business Description</label>
                            <kendo-combobox class="w-100" placeholder="Select Date">
                            </kendo-combobox>
                        </div>
                        <div class="col-md-3">
                            <label>Year Business Established</label>
                            <kendo-datepicker class="w-100" placeholder="Select Year">
                            </kendo-datepicker>
                        </div>
                        <div class="col-md-3">
                            <label>Tenure</label>
                            <kendo-combobox class="w-100" placeholder="Select Date">
                            </kendo-combobox>
                        </div>
                    </div>
                </div>
                <div class="policy-box mt-3">
                    <div class="data-header">
                        Legal Liabilities
                    </div>
                    <div class="row mb-2 gy-3 align-items-center">
                        <div class="col-md-5">
                            <label>Projected Annual Turnover</label>
                            <input type="text" class="form-control">
                        </div>
                        <div class="col-md-6">
                            <label>Number of Employees</label>
                            <input type="text" class="form-control" placeholder="">
                        </div>
                        <div class="col-md-5">
                            <label>Is there manual work away from the Premise?
                            </label>
                            <kendo-combobox class="w-100" placeholder="Select Date">
                            </kendo-combobox>
                        </div>
                        <div class="col-md-6">
                            <label>Do you require public liability cover to be included for another business premises?
                            </label>
                            <input type="text" class="form-control" placeholder="">
                        </div>
                        
                    </div>
                </div>
                <div class="policy-box mt-3">
                    <div class="data-header">
                        Business Interruption
                    </div>
                    <div class="row mb-2 g-2 align-items-center">
                        <div class="col-md-5">
                            <label>Is Business Interruption cover required?
                            </label>
                            <kendo-combobox class="w-100" placeholder="Select Date">
                            </kendo-combobox>
                        </div>
                    </div>
                </div>
                <div class="alert alert-secondary mt-3">
                    You are submitting details of a Third Party and the data that you provide will be retained to allow us to provide insurance services. Please do not proceed unless the Third Party has consented to allow their details be submitted to us. Our <a href="#">Privacy Statement</a> lets you know how we use data and how to request removal.
                </div>
                <div class="row">
                    <div class="col-md-auto col-auto">
                        <button type="button" class="btn btn-primary">
                            Get Quote
                        </button>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="accordion" id="accordionExample1">
                    <div class="accordion-item">
                        <h2 class="accordion-header" id="headingOne1">
                            <button class="accordion-button" type="button" data-bs-toggle="collapse"
                                data-bs-target="#collapseOne1" aria-expanded="true" aria-controls="collapseOne1">
                                Acceptance Criteria
                            </button>
                        </h2>
                        <div id="collapseOne1" class="accordion-collapse collapse show" aria-labelledby="headingOne1"
                            data-bs-parent="#accordionExample1">
                            <div class="accordion-body">
                                <p class="font-weight-semibold mb-1">
                                    Has the proposer or any Partner or Director either personally or in connection with
                                    any business they have been involved with:
                                </p>
                                <div class="underwriter-repeater">
                                    <div class="row justify-content-between align-items-center">
                                        <div class="col-md">
                                            <p class="mb-0">
                                                Ever been declared bankrupt, the subject of bankruptcy or liquidation
                                                proceedings or made any arrangement with creditors?
                                            </p>
                                        </div>
                                        <div class="col-md-auto">
                                            <div class="btn-group" role="group"
                                                aria-label="Basic radio toggle button group">
                                                <input type="radio" class="btn-check" name="No" id="No"
                                                    autocomplete="off" checked>
                                                <label class="btn toggle-btn-sm btn-outline-primary mb-0"
                                                    for="No">No</label>
                                                <input type="radio" class="btn-check" name="Yes" id="Yes"
                                                    autocomplete="off">
                                                <label class="btn toggle-btn-sm btn-outline-primary mb-0"
                                                    for="Yes">Yes</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="underwriter-repeater">
                                    <div class="row justify-content-between align-items-center">
                                        <div class="col-md">
                                            <p class="mb-0">
                                                Ever been refused insurance, had renewal of an insurance policy declined
                                                or had special terms or conditions imposed by an insurer?
                                            </p>
                                        </div>
                                        <div class="col-md-auto">
                                            <div class="btn-group" role="group"
                                                aria-label="Basic radio toggle button group">
                                                <input type="radio" class="btn-check" name="No" id="No"
                                                    autocomplete="off" checked>
                                                <label class="btn toggle-btn-sm btn-outline-primary mb-0"
                                                    for="No">No</label>
                                                <input type="radio" class="btn-check" name="Yes" id="Yes"
                                                    autocomplete="off">
                                                <label class="btn toggle-btn-sm btn-outline-primary mb-0"
                                                    for="Yes">Yes</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="underwriter-repeater">
                                    <div class="row justify-content-between align-items-center">
                                        <div class="col-md">
                                            <p class="mb-0">
                                                Ever been convicted of any offence of any nature or have any
                                                prosecutions due other than for a motoring offence?
                                            </p>
                                        </div>
                                        <div class="col-md-auto">
                                            <div class="btn-group" role="group"
                                                aria-label="Basic radio toggle button group">
                                                <input type="radio" class="btn-check" name="No" id="No"
                                                    autocomplete="off" checked>
                                                <label class="btn toggle-btn-sm btn-outline-primary mb-0"
                                                    for="No">No</label>
                                                <input type="radio" class="btn-check" name="Yes" id="Yes"
                                                    autocomplete="off">
                                                <label class="btn toggle-btn-sm btn-outline-primary mb-0"
                                                    for="Yes">Yes</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="underwriter-repeater">
                                    <div class="row justify-content-between align-items-center">
                                        <div class="col-md">
                                            <p class="mb-0">
                                                Incurred any claims in the past 5 years for any of the perils or
                                                contingencies to which this insurance applies?
                                            </p>
                                        </div>
                                        <div class="col-md-auto">
                                            <div class="btn-group" role="group"
                                                aria-label="Basic radio toggle button group">
                                                <input type="radio" class="btn-check" name="No" id="No"
                                                    autocomplete="off" checked>
                                                <label class="btn toggle-btn-sm btn-outline-primary mb-0"
                                                    for="No">No</label>
                                                <input type="radio" class="btn-check" name="Yes" id="Yes"
                                                    autocomplete="off">
                                                <label class="btn toggle-btn-sm btn-outline-primary mb-0"
                                                    for="Yes">Yes</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <p class="font-weight-semibold mt-3 mb-1">
                                    Has the proposer or any Partner or Director either personally or in connection with
                                    any business they have been involved with:
                                </p>

                                <div class="underwriter-repeater">
                                    <div class="row justify-content-between align-items-center">
                                        <div class="col-md">
                                            <p class="mb-0">In a good state of repair and will be so maintained
                                                accordingly? </p>
                                        </div>
                                        <div class="col-md-auto">
                                            <div class="btn-group" role="group"
                                                aria-label="Basic radio toggle button group">
                                                <input type="radio" class="btn-check" name="No" id="No"
                                                    autocomplete="off" checked>
                                                <label class="btn toggle-btn-sm btn-outline-primary mb-0"
                                                    for="No">No</label>
                                                <input type="radio" class="btn-check" name="Yes" id="Yes"
                                                    autocomplete="off">
                                                <label class="btn toggle-btn-sm btn-outline-primary mb-0"
                                                    for="Yes">Yes</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="underwriter-repeater">
                                    <div class="row justify-content-between align-items-center">
                                        <div class="col-md">
                                            <p class="mb-0">
                                                Free from special exposure to floods or subsidence, evidence of wall
                                                cracks or other subsidence issues and is not constructed on an infill
                                                site or alluvial soil liable to water seepage or constructed of pre-cast
                                                slabs?
                                            </p>
                                        </div>
                                        <div class="col-md-auto">
                                            <div class="btn-group" role="group"
                                                aria-label="Basic radio toggle button group">
                                                <input type="radio" class="btn-check" name="No" id="No"
                                                    autocomplete="off" checked>
                                                <label class="btn toggle-btn-sm btn-outline-primary mb-0"
                                                    for="No">No</label>
                                                <input type="radio" class="btn-check" name="Yes" id="Yes"
                                                    autocomplete="off">
                                                <label class="btn toggle-btn-sm btn-outline-primary mb-0"
                                                    for="Yes">Yes</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="underwriter-repeater">
                                    <div class="row justify-content-between align-items-center">
                                        <div class="col-md">
                                            <p class="mb-0">Are you aware of any issues in relation to the Fire Safety
                                                Certificate for any property to be insured? </p>
                                        </div>
                                        <div class="col-md-auto">
                                            <div class="btn-group" role="group"
                                                aria-label="Basic radio toggle button group">
                                                <input type="radio" class="btn-check" name="No" id="No"
                                                    autocomplete="off" checked>
                                                <label class="btn toggle-btn-sm btn-outline-primary mb-0"
                                                    for="No">No</label>
                                                <input type="radio" class="btn-check" name="Yes" id="Yes"
                                                    autocomplete="off">
                                                <label class="btn toggle-btn-sm btn-outline-primary mb-0"
                                                    for="Yes">Yes</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="underwriter-repeater">
                                    <div class="row justify-content-between align-items-center">
                                        <div class="col-md">
                                            <p class="mb-0">Statutory insurance is in place, where required, in respect
                                                of lifts, cranes and hoists, steam pressure apparatus and passenger
                                                lifts, elevators or escalators? </p>
                                        </div>
                                        <div class="col-md-auto">
                                            <div class="btn-group" role="group"
                                                aria-label="Basic radio toggle button group">
                                                <input type="radio" class="btn-check" name="No" id="No"
                                                    autocomplete="off" checked>
                                                <label class="btn toggle-btn-sm btn-outline-primary mb-0"
                                                    for="No">No</label>
                                                <input type="radio" class="btn-check" name="Yes" id="Yes"
                                                    autocomplete="off">
                                                <label class="btn toggle-btn-sm btn-outline-primary mb-0"
                                                    for="Yes">Yes</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="underwriter-repeater">
                                    <div class="row justify-content-between align-items-center">
                                        <div class="col-md">
                                            <p class="mb-0">Have you prepared an up to date written safety statement in
                                                accordance with the Safety, Health, and Welfare at Work Act 2005 and
                                                Safety Health and Welfare at Work General Application Regulations 2007?
                                            </p>
                                        </div>
                                        <div class="col-md-auto">
                                            <div class="btn-group" role="group"
                                                aria-label="Basic radio toggle button group">
                                                <input type="radio" class="btn-check" name="No" id="No"
                                                    autocomplete="off" checked>
                                                <label class="btn toggle-btn-sm btn-outline-primary mb-0"
                                                    for="No">No</label>
                                                <input type="radio" class="btn-check" name="Yes" id="Yes"
                                                    autocomplete="off">
                                                <label class="btn toggle-btn-sm btn-outline-primary mb-0"
                                                    for="Yes">Yes</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>