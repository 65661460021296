<div class="modal-header">
    <div class="row justify-content-between align-items-center">
        <div class="col-md-auto">
            <div class="mb-0">Your Quote Reference Number is <b>842442</b>
            </div>
        </div>
        <div class="col-md-auto">
            <span class="material-symbols-outlined cursor-pointer" (click)="closeDialog()">
                close
            </span>
        </div>
    </div>
</div>
<div class="modal-body">
    <div class="row mt-0">
        <div class="col-md-12">
            <div class="accordion" id="accordionExample1">
                <div class="accordion-item">
                    <h2 class="accordion-header" id="headingOne1">
                        <button class="accordion-button" type="button" data-bs-toggle="collapse"
                            data-bs-target="#collapseOne1" aria-expanded="true" aria-controls="collapseOne1">
                            Acceptance Criteria
                        </button>
                    </h2>
                    <div id="collapseOne1" class="accordion-collapse collapse show" aria-labelledby="headingOne1"
                        data-bs-parent="#accordionExample1">
                        <div class="accordion-body">
                            <p class="font-weight-semibold mb-1">
                                Has the proposer or any Partner or Director either personally or in connection
                                with any business they have been involved with:
                            </p>
                            <div class="underwriter-repeater">
                                <div class="row justify-content-between align-items-center">
                                    <div class="col-md-10">
                                        <p class="mb-0">Ever been declined or refused insurance cover or had cover
                                            cancelled
                                            or
                                            subject to special terms in respect of any covers to which this
                                            insurance relates?</p>
                                    </div>
                                    <div class="col-md-auto">
                                        <div class="btn-group" role="group"
                                            aria-label="Basic radio toggle button group">
                                            <input type="radio" class="btn-check" name="No" id="No" autocomplete="off"
                                                checked>
                                            <label class="btn toggle-btn-sm btn-outline-primary mb-0"
                                                for="No">No</label>
                                            <input type="radio" class="btn-check" name="Yes" id="Yes"
                                                autocomplete="off">
                                            <label class="btn toggle-btn-sm btn-outline-primary mb-0"
                                                for="Yes">Yes</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="underwriter-repeater">
                                <div class="row justify-content-between align-items-center">
                                    <div class="col-md-10">
                                        <p class="mb-0">Ever been convicted of or charged with (but not yet tried) a
                                            criminal offence other than a motoring offence?</p>
                                    </div>
                                    <div class="col-md-auto">
                                        <div class="btn-group" role="group"
                                            aria-label="Basic radio toggle button group">
                                            <input type="radio" class="btn-check" name="No" id="No" autocomplete="off"
                                                checked>
                                            <label class="btn toggle-btn-sm btn-outline-primary mb-0"
                                                for="No">No</label>
                                            <input type="radio" class="btn-check" name="Yes" id="Yes"
                                                autocomplete="off">
                                            <label class="btn toggle-btn-sm btn-outline-primary mb-0"
                                                for="Yes">Yes</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="underwriter-repeater">
                                <div class="row justify-content-between align-items-center">
                                    <div class="col-md-10">
                                        <p class="mb-0">Suffered any losses or made any claims in the past five years
                                            for any of the perils or contingencies to which this insurance applies? </p>
                                    </div>
                                    <div class="col-md-auto">
                                        <div class="btn-group" role="group"
                                            aria-label="Basic radio toggle button group">
                                            <input type="radio" class="btn-check" name="No" id="No" autocomplete="off"
                                                checked>
                                            <label class="btn toggle-btn-sm btn-outline-primary mb-0"
                                                for="No">No</label>
                                            <input type="radio" class="btn-check" name="Yes" id="Yes"
                                                autocomplete="off">
                                            <label class="btn toggle-btn-sm btn-outline-primary mb-0"
                                                for="Yes">Yes</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="underwriter-repeater">
                                <div class="row justify-content-between align-items-center">
                                    <div class="col-md-10">
                                        <p class="mb-0">Ever been declared bankrupt or are the subject of any bankruptcy
                                            proceedings or any voluntary or mandatory insolvency or winding up
                                            procedures or been the subject of a recovery action by Revenue
                                            Commissioners? </p>
                                    </div>
                                    <div class="col-md-auto">
                                        <div class="btn-group" role="group"
                                            aria-label="Basic radio toggle button group">
                                            <input type="radio" class="btn-check" name="No" id="No" autocomplete="off"
                                                checked>
                                            <label class="btn toggle-btn-sm btn-outline-primary mb-0"
                                                for="No">No</label>
                                            <input type="radio" class="btn-check" name="Yes" id="Yes"
                                                autocomplete="off">
                                            <label class="btn toggle-btn-sm btn-outline-primary mb-0"
                                                for="Yes">Yes</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="underwriter-repeater">
                                <div class="row justify-content-between align-items-center">
                                    <div class="col-md-10">
                                        <p class="mb-0">Ever been prosecuted or served a prohibition order under Health
                                            and Safety legislation within the last 5 years? </p>
                                    </div>
                                    <div class="col-md-auto">
                                        <div class="btn-group" role="group"
                                            aria-label="Basic radio toggle button group">
                                            <input type="radio" class="btn-check" name="No" id="No" autocomplete="off"
                                                checked>
                                            <label class="btn toggle-btn-sm btn-outline-primary mb-0"
                                                for="No">No</label>
                                            <input type="radio" class="btn-check" name="Yes" id="Yes"
                                                autocomplete="off">
                                            <label class="btn toggle-btn-sm btn-outline-primary mb-0"
                                                for="Yes">Yes</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-md-12">
            <h6 class="mt-3 mb-2">Policy, Business & Employee Details</h6>

            <div class="row">
                <div class="col-md-4">
                    <label>Inception Date</label>
                    <kendo-datepicker class="w-100" placeholder="Select Date">
                    </kendo-datepicker>
                </div>
                <div class="col-md-5">
                    <label>Business Name</label>
                    <input type="text" class="form-control">
                </div>
            </div>
           
        </div>
    </div>
</div>