import { Component } from '@angular/core';
import { QuoteDetailsDialogComponent } from '@app/quote-details-dialog/quote-details-dialog.component';
//import { QuoteDetailsDialogComponent } from '../quote-details-dialog/quote-details-dialog.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-manage-existing-business',
  templateUrl: './manage-existing-business.component.html',
  styleUrls: ['./manage-existing-business.component.scss']
})
export class ManageExistingBusinessComponent {
  constructor(private modalService: NgbModal) {
  }
  public Broker: Array<string> = [
    "All Broker",
    "Optis Insurance - BS0000",
    "Advanced Financial Services - BS0045",
  ];
  public Type: Array<string> = [
    "Quote",
    "Policies",
    "Renewals",
    "Lapsed",
    "MTA Quotes",
  ];
  public Product: Array<string> = [
    "EL / PL",
    "Office",
    "Personal Accident",
    "Professional Indemnity",
    "Property Owners",
  ];
  public gridData = [
    {
      Ref: '500164',
      Proposer: "O Meara Aspect Holding Ltd",
      Broker: 'Optis Insurance',
      DateSaved: '09-08-2023',
      Premium: '€ 418.39'
    },
    {
      Ref: '500164',
      Proposer: "O Meara Aspect Holding Ltd",
      Broker: 'Optis Insurance',
      DateSaved: '09-08-2023',
      Premium: '€ 418.39'
    },
    {
      Ref: '500164',
      Proposer: "O Meara Aspect Holding Ltd",
      Broker: 'Optis Insurance',
      DateSaved: '09-08-2023',
      Premium: '€ 418.39'
    },
    {
      Ref: '500164',
      Proposer: "O Meara Aspect Holding Ltd",
      Broker: 'Optis Insurance',
      DateSaved: '09-08-2023',
      Premium: '€ 418.39'
    },
    {
      Ref: '500164',
      Proposer: "O Meara Aspect Holding Ltd",
      Broker: 'Optis Insurance',
      DateSaved: '09-08-2023',
      Premium: '€ 418.39'
    },
    {
      Ref: '500164',
      Proposer: "O Meara Aspect Holding Ltd",
      Broker: 'Optis Insurance',
      DateSaved: '09-08-2023',
      Premium: '€ 418.39'
    },
    {
      Ref: '500164',
      Proposer: "O Meara Aspect Holding Ltd",
      Broker: 'Optis Insurance',
      DateSaved: '09-08-2023',
      Premium: '€ 418.39'
    },
  ];
  getQuote() {
    const modalref = this.modalService.open(QuoteDetailsDialogComponent, {
      backdrop: "static",
      windowClass: "quote-details-dialog",
      centered: true,
      size: "lg",
    });
  }
}
