<div class="card h-100">
    <div class="card-body dash-icons">
   <!-- Add the new button -->
   <div class="mt-3">
    
  </div>

        <div class="row mt-0">
            <div class="col-md-8">
                <div class="policy-box">
                    <div class="row gy-4">
                        <div class="col-xl col-md-6 col-12">
                            <div class="card">
                                <div class="card-body">
                                    <div class="align-items-center row cursor-pointer" (click)="navigateToPolicyStatus(3)">
                                        <div class="col">
                                            <h6 class="text-secondary mb-2">Active Policy</h6>
                                            <span class="h3 mb-0">{{activePolicyData}}</span>
                                        </div>
                                        <div class="col-auto">
                                            <span class="material-symbols-outlined">
                                                check_circle
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl col-md-6 col-12">
                            <div class="card">
                                <div class="card-body">
                                    <div class="align-items-center row cursor-pointer" (click)="navigateToPolicyStatus(8)">
                                        <div class="col">
                                            <h6 class="text-secondary mb-2">Renewal List
                                            </h6>
                                            <span class="h3 mb-0">{{renewDateCount}}</span>
                                        </div>
                                        <div class="col-auto">
                                            <span class="material-symbols-outlined">
                                                autorenew
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl col-md-6 col-12">
                            <div class="card">
                                <div class="card-body">
                                    <div class="align-items-center row cursor-pointer" (click)="navigateToPolicyStatus(1)">
                                        <div class="col">
                                            <h6 class="text-secondary mb-2">Referred</h6>
                                            <span class="h3 mb-0">{{referredPolicyPolicyData}}</span>
                                        </div>
                                        <div class="col-auto">
                                            <span class="material-symbols-outlined">
                                                policy
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl col-md-6 col-12">
                            <div class="card">
                                <div class="card-body">
                                    <div class="align-items-center row cursor-pointer" (click)="navigateToPolicyStatus(1)">
                                        <div class="col">
                                            <h6 class="text-secondary mb-2">Active Quote</h6><span
                                                class="h3 mb-0">{{clearReferredPolicyData}}</span>
                                        </div>
                                        <div class="col-auto">
                                            <span class="material-symbols-outlined">
                                                free_cancellation
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="policy-box mt-3" *ngIf="false">
                    <div class="row">
                        <div class="col-md-6 col-12">
                            <div class="card">
                                <div class="card-body">
                                    <div class="align-items-center row">
                                        <div class="col">
                                            <h6 class="text-secondary mb-2">Some Text</h6><span
                                                class="h3 mb-0">29</span>
                                        </div>
                                        <div class="col-auto"><button type="button"
                                                class="btn btn-primary btn-sm">Action</button></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 col-12">
                            <div class="card">
                                <div class="card-body">
                                    <div class="align-items-center row">
                                        <div class="col">
                                            <h6 class="text-secondary mb-2">Some Text
                                            </h6>
                                            <span class="h3 mb-0">7,500</span>
                                        </div>
                                        <div class="col-auto"><button type="button"
                                                class="btn btn-primary btn-sm">Action</button></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="policy-box mt-3" *ngIf="false">
                    <div class="card">
                        <div class="card-header">
                            <h6 class="card-header-title mb-0">Sample Table</h6>
                        </div>
                        <div class="table-responsive">
                            <table class="card-table table-nowrap table table-sm">
                                <thead>
                                    <tr>
                                        <th>Invoice ID</th>
                                        <th>Date</th>
                                        <th>Amount</th>
                                        <th>Status</th>
                                    </tr>
                                </thead>
                                <tbody class="fs-base">
                                    <tr>
                                        <td><a href="/invoice">Invoice #10395</a></td>
                                        <td><time datetime="2020-04-24">Apr 24, 2020</time></td>
                                        <td>$29.00</td>
                                        <td><span class="badge bg-secondary">Outstanding</span></td>
                                    </tr>
                                    <tr>
                                        <td><a href="/invoice">Invoice #10244</a></td>
                                        <td><time datetime="2020-03-24">Mar 24, 2020</time></td>
                                        <td>$29.00</td>
                                        <td><span class="badge bg-success">Paid</span></td>
                                    </tr>
                                    <tr>
                                        <td><a href="/invoice">Invoice #10119</a></td>
                                        <td><time datetime="2020-02-24">Feb 24, 2020</time></td>
                                        <td>$29.00</td>
                                        <td><span class="badge bg-success">Paid</span></td>
                                    </tr>
                                    <tr>
                                        <td><a href="/invoice">Invoice #10062</a></td>
                                        <td><time datetime="2020-01-24">Jan 24, 2020</time></td>
                                        <td>$29.00</td>
                                        <td><span class="badge bg-success">Paid</span></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="policy-box">
                    <h6 class="font-weight-semibold mb-1">
                        Confirmation of New Underwriter
                    </h6>
                    <img class="insurer-logo mt-3" src="{{activeInsurer?.logoPath}}">
                    <div class="mt-3">
                        <a (click)="aboutInsurere()" class="cursor-pointer text-primary text-decoration-underline">
                            Find out more >
                        </a>
                    </div>

                    <div class="card card-gray mt-3">
                        <div class="card-body">
                            <h3>Call: {{ phoneNumber }}</h3>
                            <p class="mb-0">{{ contactTime }}</p>
                        </div>
                    </div>
                </div>
                <div class="policy-box mt-3 announcement-focus">
                    <div class="pp-animation">
                        <span class="pp-animation__line pp-animation__line_left"></span>
                        <span class="pp-animation__line pp-animation__line_right"></span>
                        <span class="pp-animation__line pp-animation__line_top"></span>
                        <span class="pp-animation__line pp-animation__line_bottom"></span>
                    </div>
                    <h6 class="font-weight-semibold mb-1">
                        Announcements
                    </h6>
                    <div class="underwriter-repeater position-relative z-3">
                        <div class="row justify-content-between align-items-center overflow-scrollable" *ngIf="announcementList?.length>0">
                            <div class="text-black policy-box mb-2 notification-block"
                                *ngFor="let item of announcementList;">
                                <span class="font-weight-600">Subject : {{item.subject}}</span><br> <br>
                                <span class="font-weight-600">Message :</span>
                                <div class="col-md col" [innerHTML]="item.message">
                                </div>
                            </div>
                        </div>
                        <div class="row justify-content-between align-items-center"
                            *ngIf="!(announcementList?.length>0)">
                            <div class="col-md">
                                <div class="text-black policy-box mb-2 notification-block">
                                    <p class="mb-0">
                                        No Announcement
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>