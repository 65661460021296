import { Component, Input } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { InsuranceService } from '@app/services/insurance.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToasterService } from '@shared/utility/toaster.service';
import { getExactDate } from '@shared/utility/utilityHelpers';
import { Subscription } from 'rxjs';
import "froala-editor/js/plugins.pkgd.min.js";
import FroalaEditor from "froala-editor";

@Component({
  selector: 'app-add-announcement',
  templateUrl: './add-announcement.component.html',
  styleUrl: './add-announcement.component.css'
})
export class AddAnnouncementComponent {
  @Input() announcementId: number;
  announcementfrom: FormGroup;
  currentDate = new Date(new Date().setHours(0, 0, 0, 0));
  message;
  announcementData
  private subscription = new Subscription();

  constructor(private fb: FormBuilder,
    private insuranceService: InsuranceService,
    private router: Router,
    private toasterService: ToasterService,
    private activeModal: NgbActiveModal,
  ) { }


  ngOnInit() {
    this.initForm();
    this.loadData();
  }
  isContentChanged: boolean = false;
  editor: FroalaEditor;
  // content = '';
  public imgOptions: Object = {
    angularIgnoreAttrs: ['style', 'ng-reflect-froala-editor', 'ng-reflect-froala-model'],
    immediateAngularModelUpdate: true,
    key: 'Ig1A7vC3C1C2B2F2G4A3nB-31qawB-11tpH-8uC-21xD5B-13oD3whxC4B3A3A1B5A1D1E4F1A3==',
    attribution: false,
    events: {
      "initialized": (initControls) => {
        this.editor = initControls.getEditor();
        this.editor.events.on('drop', function (dropEvent) {
        });
      },
      "contentChanged": () => {
        this.isContentChanged = true;
      },
      'drop': (dropEvent) => {
        // editor.events.on('drop', function (dropEvent) {
        // Focus at the current posisiton.
        this.editor.markers.insertAtPoint(dropEvent.originalEvent);
        var $marker = this.editor.$el.find('.fr-marker');
        //$marker.replaceWith(this.editor.MARKERS);
        this.editor.selection.restore();

        // Save into undo stack the current position.
        if (!this.editor.undo.canDo()) this.editor.undo.saveStep();

        // Insert HTML.
        // if (dropEvent.originalEvent.dataTransfer.getData('Text') == 'drag-smile') {
        //   this.editor.html.insert('<span class="fr-emoticon fr-emoticon-img" style="background: url(https://cdnjs.cloudflare.com/ajax/libs/emojione/2.0.1/assets/svg/1f600.svg)">&nbsp;</span>');
        // }
        // else {
        //   this.editor.html.insert('<div class="[BM_LandlordDivStyle]"><div class="row justify-content-between align-items-center mt-4"><div class="col-md-12 mb-4"><div class="title">Signed by or on behalf of the landlord(s):</div></div><div class="repeator-div"><div class="LstPropertyLandlord"><div class="d-block"><span class="col-md-12 d-block"><span class="title">Name : [BM_FullName]</span></span><span class="col-md-12 d-block">Signature :<span class="title" style="color:white;">[sig|req|[BM_LandlordSignId]]</span></span><span class="col-md-12 d-block"><span class="title">Date :<span class="title"style="color:white;">[date|req|[BM_LandlordSignId]]</span></span></span></div></div></div></div></div>');
        // }
        this.editor.html.insert(dropEvent.originalEvent.dataTransfer.getData('html'));
        // Save into undo stack the changes.
        this.editor.undo.saveStep();

        // Stop event propagation.
        dropEvent.preventDefault();
        dropEvent.stopPropagation();

        // Firefox show cursor.
        if (this.editor.core.hasFocus() && this.editor.browser.mozilla) {
          this.editor.events.disableBlur();
          setTimeout(function () {
            this.editor.$el.blur().focus();
            this.editor.events.enableBlur();
          }, 0);
        }

        return false;
        // }, true);
        // Do something here.
        // this is the editor instance.
      }
    }
  }

  initForm() {
    this.announcementfrom = this.fb.group({
      messagefromdate: ['', Validators.required],
      messagetodate: ['', Validators.required],
      subject: ['', [Validators.required, this.noWhitespaceValidator, Validators.maxLength(200)]],
      message: ['', [Validators.required, this.noWhitespaceValidator]],
    });
  }

  loadData() {
    this.insuranceService
      .GetAnnouncementById(this.announcementId)
      .subscribe((data: any): void => {
        this.announcementData = data;
        this.setAnnouncementValue();
      })
  }
  setAnnouncementValue() {
    this.announcementfrom.patchValue({
      messagefromdate: getExactDate(this.announcementData.messageFromDate),
      messagetodate: getExactDate(this.announcementData.messageToDate),
      subject: this.announcementData.subject,
      message: this.announcementData.message
    });
  }

  noWhitespaceValidator(control: AbstractControl) {
    const value = control.value || '';
    const hasWhitespace = value.trim() === '' && value.length > 0;
    return hasWhitespace ? { whitespace: true } : null;
  }


  clearAnnouncementAll() {
    this.announcementfrom.controls["messagefromdate"].patchValue("");
    this.announcementfrom.controls["messagetodate"].patchValue("");
    this.announcementfrom.controls["subject"].patchValue("");
    this.announcementfrom.controls["message"].patchValue("");
    this.message = "";
    this.activeModal.close(false);
  }

  onKeydown(event) {
    if (event.key === '/') {
      return false;
    }
  }

  saveData() {
    if (this.announcementfrom.valid) {

      this.message = this.announcementfrom.controls["message"].value;

      if (this.message === undefined || this.message === null || this.message === '') {
        this.toasterService.warning('Please enter message description');
        return;
      }

      const objDetails = Object.assign({});
      objDetails.subject = this.announcementfrom.controls["subject"].value;
      objDetails.message = this.message;
      objDetails.announcementId = this.announcementId;
      objDetails.messageFromDate = this.announcementfrom.controls['messagefromdate'].value ? getExactDate(this.announcementfrom.controls['messagefromdate'].value) : null;
      objDetails.messageToDate = this.announcementfrom.controls['messagetodate'].value ? getExactDate(this.announcementfrom.controls['messagetodate'].value) : null;

      if (objDetails.messageFromDate > objDetails.messageToDate) {
        this.toasterService.warning('From date should be lesser than To date.');
        return;
      }
      if (this.announcementId > 0) {
        this.subscription.add(
          this.insuranceService.UpdateAnnouncement(objDetails)
            .subscribe((data: any) => {
              if (data !== undefined && data != null && data > 0) {
                this.toasterService.success("Announcement schedule updated successfully");
                this.announcementfrom.reset();
                this.message = '';
                this.activeModal.close(true);
              }
              else {
                this.toasterService.error("Something went wrong, Announcement schedule not saved");
              }
            })
        );
      } else {
        this.subscription.add(
          this.insuranceService.SaveAnnouncement(objDetails)
            .subscribe((data: any) => {
              if (data !== undefined && data != null && data > 0) {
                this.toasterService.success("Announcement schedule saved successfully");
                this.announcementfrom.reset();
                this.message = '';
                this.activeModal.close(true);
              }
              else {
                this.toasterService.error("Something went wrong, Announcement schedule not saved");
              }
            })
        );
      }
    } else {
      this.insuranceService.validateAllFormFields(this.announcementfrom);
    }
  }

  closeDialog() {
    this.activeModal.close(false);
  }
}
