import { Component, OnInit, OnDestroy } from '@angular/core';
import {
  OidcSecurityService,
} from 'angular-auth-oidc-client';

@Component({
  selector: 'app-auto-component',
  templateUrl: './auto-login.component.html',
})
export class AutoLoginComponent implements OnInit, OnDestroy {
  isAuthenticated = false;
  constructor(public oidcSecurityService: OidcSecurityService) {}

  ngOnInit() {
    this.oidcSecurityService.isAuthenticated$.subscribe(
      ({ isAuthenticated }) => {
        this.isAuthenticated = isAuthenticated;

        console.warn('authenticated: ', isAuthenticated);
      }
    );
      
     
     
    

    this.oidcSecurityService.authorize();
  }

  login() {}

  ngOnDestroy(): void {}
}
