<div class="row g-2">
    <div class="col-md-12">
        <div class="card h-100">
            <div class="card-body">
                <div class="row">
                    <div class="col-md-12">
                        <form novalidate [formGroup]="announcementfrom">
                            <div class="row gx-2">
                                <div class="form-group col-md-3">
                                    <label>Message Live from</label>
                                    <kendo-datepicker [format]="'dd/MM/yyyy'" placeholder="dd/mm/yyyy"
                                        formControlName="messagefromdate" class="w-100" (keydown)="onKeydown($event)"
                                        [(ngModel)]="priorDate">
                                    </kendo-datepicker>
                                    <div class="validation-msg-label"
                                        *ngIf="announcementfrom.controls.messagefromdate.touched  && announcementfrom.controls.messagefromdate.errors?.required">
                                        Required
                                    </div>
                                </div>
                                <div class="form-group col-md-3">
                                    <label>Message Live to</label>
                                    <kendo-datepicker [format]="'dd/MM/yyyy'" placeholder="dd/mm/yyyy"
                                        formControlName="messagetodate" class="w-100" (keydown)="onKeydown($event)"
                                        [(ngModel)]="currentDate">
                                    </kendo-datepicker>
                                    <div class="validation-msg-label"
                                        *ngIf="announcementfrom.controls.messagetodate.touched  && announcementfrom.controls.messagetodate.errors?.required">
                                        Required
                                    </div>
                                </div>
                                <div class="col-md-auto if-no-label-28">
                                    <button class="btn btn-sm btn-primary" (click)="searchHistory()">Search</button>
                                </div>
                                <div class="col-md-auto if-no-label-28">
                                    <button class="btn btn-sm btn-secondary" (click)="clearAll()">Clear</button>
                                </div>
                                <div class="col-md-auto if-no-label-28 ms-auto">
                                    <button class="btn btn-sm btn-primary" (click)="addannouncement()">Add</button>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div class="col-md-12 announcement-grid">
                        <kendo-grid [data]="dataAnnouncement" [pageable]="{buttonCount: 5}" [pageSize]="10"
                            [sortable]="true" [kendoGridBinding]="dataAnnouncement" [resizable]="true">
                            <ng-template kendoGridToolbarTemplate>
                                <div class="form-row align-items-center w-100 py-2">
                                    <div class="col-md m-top-10">
                                        <input placeholder="Search in all columns..." class="form-control w-100"
                                            (input)="filterData($event.target.value)" [(ngModel)]="searchText"
                                            [ngModelOptions]="{standalone: true}" />
                                    </div>
                                    <!-- <div class="col-md-auto m-top-10">
                                    <button kendoGridExcelCommand type="button" class="m-w-100" icon="file-excel">Export
                                        to
                                        Excel</button>
                                </div>
                                <div class="col-md-auto m-top-10 mr-2">
                                    <button kendoGridPDFCommand icon="file-pdf" class="m-w-100">Export to PDF</button>
                                </div> -->
                                </div>

                            </ng-template>
                            <kendo-grid-column title="Status" [headerStyle]="{'width': '80px'}"
                            [style]="{'width': '80px'}">
                                <ng-template kendoGridCellTemplate let-dataItem>
                                    <span *ngIf="!dataItem.isEnabled" class="badge bg-danger">Disabled</span>
                                    <span *ngIf="dataItem.isEnabled" class="badge bg-success">Enabled</span>
                                </ng-template>
                            </kendo-grid-column>
                            <kendo-grid-column title="Subject" field="subject" [headerStyle]="{'width': '250px'}"
                                [style]="{'width': '250px'}">
                            </kendo-grid-column>
                            <kendo-grid-column title="Message" field="message">
                                <ng-template kendoGridCellTemplate let-dataItem>
                                    <div [innerHTML]="dataItem.message">
                                    </div>
                                </ng-template>
                            </kendo-grid-column>
                            <kendo-grid-column title="User" field="createdByUser" [headerStyle]="{'width': '200px'}"
                                [style]="{'width': '200px'}">
                            </kendo-grid-column>
                            <kendo-grid-column field="messageFromDate" title="Message Live from"
                                [headerStyle]="{'width': '200px'}" [style]="{'width': '200px'}">
                                <ng-template kendoGridCellTemplate let-dataItem>
                                    {{ dataItem.messageFromDate | date: "dd/MM/yyyy" }}
                                </ng-template>
                            </kendo-grid-column>
                            <kendo-grid-column field="messageToDate" title="Message Live to"
                                [headerStyle]="{'width': '200px'}" [style]="{'width': '200px'}">
                                <ng-template kendoGridCellTemplate let-dataItem>
                                    {{ dataItem.messageToDate | date: "dd/MM/yyyy" }}
                                </ng-template>
                            </kendo-grid-column>

                            <kendo-grid-column field="action" title="Action" width="80">
                                <ng-template kendoGridCellTemplate let-dataItem>
                                    <div class="dot-menu">
                                        <div class="dropdown">
                                            <button class="btn-grid dropdown-toggle pb-0" type="button"
                                                id="dropdownMenuButton1" data-bs-toggle="dropdown"
                                                aria-expanded="false">
                                                <span class="material-symbols-outlined">
                                                    expand_circle_down
                                                </span>
                                            </button>
                                            <ul class="dropdown-menu dropdown-menu1 dropdown-menu-right"
                                                aria-labelledby="dropdownMenuButton1">
                                                <li (click)="EnableDisableAnnoucement(dataItem.announcementId)">
                                                    <span *ngIf="dataItem.isEnabled">Disable</span>
                                                    <span *ngIf="!dataItem.isEnabled">Enable</span>
                                                </li>
                                                <li (click)="addannouncement(dataItem.announcementId)">
                                                    <span>Edit</span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </ng-template>
                            </kendo-grid-column>
                        </kendo-grid>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>