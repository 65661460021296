<div class="row g-2">
    <div class="col-md-10">
        <div class="card h-100">
            <div class="card-body">
                <div class="row mb-2 g-2 align-items-center">
                    <div class="col-md-3">
                        <kendo-combobox class="w-100" placeholder="Select Broker">
                        </kendo-combobox>
                    </div>
                    <div class="col-md-auto col-auto">
                        <button type="button" class="btn btn-primary">
                            Generate
                        </button>
                    </div>
                </div>
                <kendo-grid [data]="gridData" [pageable]="true" [pageSize]="10" [sortable]="true" [resizable]="true">
                    <ng-template kendoGridToolbarTemplate>
                        <div class="row g-2 w-100">
                            <div class="col-md m-top-10">
                                <input type="search" placeholder="Search in all columns..."
                                    class="form-control w-100" />
                            </div>
                            <div class="col-md-auto col-auto m-top-10">
                                <button kendoGridExcelCommand type="button" class="m-w-100" icon="file-excel">

                                </button>
                            </div>
                            <div class="col-md-auto col-auto m-top-10">
                                <button kendoGridPDFCommand icon="file-pdf" type="button" class="m-w-100">

                                </button>
                            </div>
                        </div>
                    </ng-template>

                    <kendo-grid-column field="transId" title="Trans Id" [headerStyle]="{ width: '100px' }"
                        [style]="{ width: '100px' }">
                    </kendo-grid-column>
                    <kendo-grid-column field="transDate" title="Trans Date" [headerStyle]="{ width: '110px' }"
                        [style]="{ width: '110px' }">
                    </kendo-grid-column>
                    <kendo-grid-column field="policyId" title="policy#" [headerStyle]="{ width: '110px' }"
                        [style]="{ width: '110px' }">
                    </kendo-grid-column>
                    <kendo-grid-column field="type" title="Type" [headerStyle]="{ width: '70px' }"
                        [style]="{ width: '70px' }">
                    </kendo-grid-column>
                    <kendo-grid-column field="insured" title="Insured" [headerStyle]="{ width: '160px' }"
                        [style]="{ width: '160px' }">
                    </kendo-grid-column>
                    <kendo-grid-column field="gross" title="gross" [headerStyle]="{ width: '100px' }"
                        [style]="{ width: '100px' }">
                    </kendo-grid-column>
                    <kendo-grid-column field="commission" title="Commission" [headerStyle]="{ width: '100px' }"
                        [style]="{ width: '100px' }">
                    </kendo-grid-column>
                    <kendo-grid-column field="dueToOptis" title="Due To Optis" [headerStyle]="{ width: '100px' }"
                        [style]="{ width: '100px' }">
                    </kendo-grid-column>
                    <kendo-grid-column field="pay" title="Pay" [headerStyle]="{ width: '50px' }"
                        [style]="{ width: '50px' }">
                        <ng-template kendoGridCellTemplate let-dataItem>
                            <div class="custom-control custom-checkbox">
                                <input type="checkbox" class="custom-control-input" id="check" />
                            </div>
                        </ng-template>
                    </kendo-grid-column>
                    <kendo-grid-column field="writeoff" title="WriteOff" [headerStyle]="{ width: '120px' }"
                        [style]="{ width: '120px' }">
                        <ng-template kendoGridCellTemplate let-dataItem>
                            <input type="text" class="form-control">
                        </ng-template>
                    </kendo-grid-column>
                </kendo-grid>
            </div>
        </div>
    </div>
    <div class="col-md-2">
        <div class="card h-100">
            <div class="card-body">
                <div class="row mb-2 g-2 align-items-center">
                    <div class="col-md-12">
                        <label>Total Amount Selected</label>
                        <input type="text" class="form-control">
                    </div>
                    <div class="col-md-12">
                        <label>Total WriteOff</label>
                        <input type="text" class="form-control">
                    </div>
                    <div class="col-md-12">
                        <label>Total Paid to Optis</label>
                        <input type="text" class="form-control">
                    </div>
                    <div class="col-md-12">
                        <label>Payment Method</label>
                        <kendo-combobox class="w-100" placeholder="Select Broker">
                        </kendo-combobox>
                    </div>
                    <div class="col-md-12">
                        <label>Reference <small>(At lease 3 chars.)</small></label>
                        <input type="text" class="form-control">
                    </div>
                    <div class="col-md-auto col-auto">
                        <button type="button" class="btn btn-primary">
                            Reconcile
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>



