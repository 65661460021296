<form novalidate [formGroup]="configTest">
  <div class="card h-100">
    <div class="card-body">
      <h5 class="form-group mb-2">Configuration File</h5>
      <div class="row">
        <div class="col-md-12 custom-grid-plus pc-grid">
          <kendo-grid [pageable]="true" [kendoGridBinding]="configData" [sortable]="true" [pageSize]="10">
            <!-- <kendo-grid-column field="configKey" title="Config key" [headerStyle]="{ width: '150px' }"
              [style]="{ width: '150px' }">
            </kendo-grid-column> -->
            <kendo-grid-column field="configValue" title="Config Value" [headerStyle]="{ width: '200px' }"
              [style]="{ width: '200px' }">
            </kendo-grid-column>
            <kendo-grid-column field="isActive" title="Active" [headerStyle]="{ width: '18px' }"
              [style]="{ width: '18px' }">
              <ng-template kendoGridCellTemplate let-dataItem>
                <input type="checkbox" class="mx-2" [ngModelOptions]="{ standalone: true }"
                  [(ngModel)]="dataItem.isActive" (change)="toggleArchive(dataItem)"/>
              </ng-template>
            </kendo-grid-column>
            <kendo-grid-column title="Action" [headerStyle]="{ width: '10px' }" [style]="{ width: '10px' }">
              <ng-template kendoGridCellTemplate let-dataItem>
                  <span class="material-symbols-outlined cursor-pointer text-primary" (click)="openEditModal(dataItem)">border_color</span>   
              </ng-template>
            </kendo-grid-column>
          </kendo-grid>
        </div>
      </div>
    </div>
  </div>
</form>
<ng-template #editModal>
  <div class="modal-header">
    <h5 class="modal-title">Edit Configuration</h5>
  </div>
  <div class="modal-body">
    <form [formGroup]="configTest">
      <div class="form-group">
        <label for="configValue">Config Value</label>
        <input formControlName="configValue" id="configValue" class="form-control">
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="closeModal()">Close</button>
    <button type="button" class="btn btn-primary" (click)="updateData()">Save changes</button>
  </div>
</ng-template>