    <div class="card h-100">
        <div class="card-body">
            <h5 class="form-group mb-2">QEB Cancellation</h5>
            <div class="row mb-2 g-2 align-items-center">
                <div class="form-group col-md-3">
                    <label>Cancelled Period</label>
                    <kendo-combobox [data]="periodList" [ngModel]="periodId" (valueChange)="periodChange($event)"
                        [textField]="'text'" [valueField]="'value'" class="w-100" placeholder="Select Period">
                    </kendo-combobox>
                    <!-- <div *ngIf="periodTest.controls['periodId'].touched && periodTest.controls['periodId'].errors?.required"
                        class="validation-msg-label">
                        Required
                    </div> -->
                </div>
                <div class="form-group col-md-auto col-auto if-no-label">
                    <button type="submit" class="btn btn-primary" (click)="loadPeriodList()">
                        Search
                    </button>
                </div>
                <h6 class="col-md-12">This report shows a list of cancelled policies including cancelled date and cancel reason (ordered by inception date). Filter by cancellation month if required.</h6>
            </div>
            <div class="row">
                <div class="col-md-12 custom-grid-plus pc-grid">
                    <p class="">Policy Cancelled in the period : {{ periodId.text }} </p>
                    <kendo-grid [pageable]="true" [kendoGridBinding]="periodData"
                    [sortable]="false" [pageSize]="10" [scrollable]="'scrollable'">
                    <ng-template kendoGridToolbarTemplate>
                        <div class="form-row w-100">
                          <div class="col-md-auto col-auto m-top-10">
                            <button kendoGridExcelCommand type="button" class="m-w-100" icon="file-excel">
                              Export to Excel
                            </button>
                          </div>
                        </div>
                      </ng-template>
                        <kendo-grid-column field="policyNumber" title="Unique policy reference" [headerStyle]="{ width: '150px' }"
                            [style]="{ width: '150px' }">
                        </kendo-grid-column>
                        <kendo-grid-column field="policyHolder" title="Name of policyholder" [headerStyle]="{ width: '150px' }"
                            [style]="{ width: '150px' }">
                        </kendo-grid-column>
                        <kendo-grid-column field="policyHolderAddress" title="Policyholder Address" [headerStyle]="{ width: '150px' }"
                            [style]="{ width: '150px' }">
                        </kendo-grid-column>
                        <kendo-grid-column field="policyType" title="Policy Type" [headerStyle]="{ width: '70px' }"
                            [style]="{ width: '70px' }">
                        </kendo-grid-column>
                        <kendo-grid-column field="policyRenewal" title="Policy Renewal - yes/no" [headerStyle]="{ width: '100px' }"
                            [style]="{ width: '100px' }">
                        </kendo-grid-column>
                        <kendo-grid-column field="inceptionDate" title="Inception Date" [headerStyle]="{ width: '100px' }"
                            [style]="{ width: '100px' }">
                            <ng-template kendoGridCellTemplate let-dataItem>
                                {{ dataItem.inceptionDate | date: "dd/MM/yyyy" }}
                            </ng-template>
                        </kendo-grid-column>
                        <kendo-grid-column field="expiaryDate" title="Expiry Date" [headerStyle]="{ width: '100px' }"
                            [style]="{ width: '100px' }">
                            <ng-template kendoGridCellTemplate let-dataItem>
                                {{ dataItem.expiaryDate | date: "dd/MM/yyyy" }}
                            </ng-template>
                        </kendo-grid-column>
                        <kendo-grid-column field="dateOfCancellation" title="Date of Cancellation" [headerStyle]="{ width: '150px' }"
                            [style]="{ width: '150px' }">
                            <ng-template kendoGridCellTemplate let-dataItem>
                                {{ dataItem.dateOfCancellation | date: "dd/MM/yyyy" }}
                            </ng-template>
                        </kendo-grid-column>
                        <kendo-grid-column field="cancellationReason" title="Reason for Cancellation" [headerStyle]="{ width: '150px' }"
                            [style]="{ width: '150px' }">
                        </kendo-grid-column>
                        <kendo-grid-excel fileName="QEB_Cancellation.xlsx"></kendo-grid-excel>
                    </kendo-grid>
                </div>
            </div>
        </div>
    </div>