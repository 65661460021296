<div class="card">
    <div class="card-body">
        <div class="row mb-2 g-2 align-items-center">
            <div class="col-md-3">
                <kendo-combobox class="w-100" placeholder="Account">
                </kendo-combobox>
            </div>
            <div class="col-md-auto">
                <kendo-datepicker class="w-100" placeholder="Date From">
                </kendo-datepicker>
            </div>
            <div class="col-md-auto">
                <kendo-datepicker class="w-100" placeholder="Date To">
                </kendo-datepicker>
            </div>
            <div class="col-md-auto col-auto">
                <button type="button" class="btn btn-primary">
                    Generate
                </button>
            </div>
        </div>
        <table class="table table-bordered table-hover table-striped">
            <thead>
                <tr>
                    <th>Id</th>
                    <th>Date</th>
                    <th>Transaction details</th>
                    <th>Payments Out</th>
                    <th>Payments In</th>
                    <th>Balance</th>
                </tr>
            </thead>
            <tbody>

                <tr class="balforw">
                    <th colspan="2"></th>
                    <th>BALANCE FORWARD</th>
                    <th colspan="2"></th>
                    <th>€ 2,357,933.65</th>
                </tr>


                <tr class="newdate">
                    <td>

                        <a class="various" data-fancybox-type="iframe">36334</a>

                    </td>
                    <th>03/07/2023</th>
                    <td>ROBERT BYLTHMAN</td>
                    <td></td>
                    <td>€ 8,286.08</td>
                    <td>€ 2,366,219.73</td>
                </tr>


                <tr>
                    <td>

                        <a class="various" data-fancybox-type="iframe">36335</a>

                    </td>
                    <th></th>
                    <td>ALAN TIERNEY</td>
                    <td></td>
                    <td>€ 426.69</td>
                    <td>€ 2,366,646.42</td>
                </tr>


                <tr>
                    <td>

                        <a class="various" data-fancybox-type="iframe">36336</a>

                    </td>
                    <th></th>
                    <td>RICHARDSON INSURANCE</td>
                    <td></td>
                    <td>€ 1,102.63</td>
                    <td>€ 2,367,749.05</td>
                </tr>


                <tr>
                    <td>

                        <a class="various" data-fancybox-type="iframe">36338</a>

                    </td>
                    <th></th>
                    <td>O DONOVAN GMIB</td>
                    <td></td>
                    <td>€ 2,525.57</td>
                    <td>€ 2,370,274.62</td>
                </tr>


                <tr>
                    <td>

                        <a class="various" data-fancybox-type="iframe">36339</a>

                    </td>
                    <th></th>
                    <td>DON REDDAN</td>
                    <td></td>
                    <td>€ 9,714.50</td>
                    <td>€ 2,379,989.12</td>
                </tr>


                <tr>
                    <td>

                        <a class="various" data-fancybox-type="iframe">36340</a>

                    </td>
                    <th></th>
                    <td>LEE INSURANCE BROKERS</td>
                    <td></td>
                    <td>€ 470.55</td>
                    <td>€ 2,380,459.67</td>
                </tr>




                <tr>
                    <td>

                        <a class="various" data-fancybox-type="iframe">36346</a>

                    </td>
                    <th></th>
                    <td>INSIGHT</td>
                    <td></td>
                    <td>€ 2,657.14</td>
                    <td>€ 2,401,599.90</td>
                </tr>


                <tr>
                    <td>

                        <a class="various" data-fancybox-type="iframe">36376</a>

                    </td>
                    <th></th>
                    <td>GALLAGHER</td>
                    <td></td>
                    <td>€ 25.71</td>
                    <td>€ 2,401,625.61</td>
                </tr>


                <tr>
                    <td>

                        <a class="various" data-fancybox-type="iframe">36377</a>

                    </td>
                    <th></th>
                    <td>GALLAGHER</td>
                    <td></td>
                    <td>€ 12,137.15</td>
                    <td>€ 2,413,762.76</td>
                </tr>


                <tr>
                    <td>

                        <a class="various" data-fancybox-type="iframe">36378</a>

                    </td>
                    <th></th>
                    <td>GALLAGHER</td>
                    <td></td>
                    <td>€ 808.02</td>
                    <td>€ 2,414,570.78</td>
                </tr>


                <tr>
                    <td>

                        <a class="various" data-fancybox-type="iframe">36395</a>

                    </td>
                    <th></th>
                    <td>SPARROW</td>
                    <td></td>
                    <td>€ 6,432.93</td>
                    <td>€ 2,421,003.71</td>
                </tr>


                <tr>
                    <td>

                        <a class="various" data-fancybox-type="iframe">36423</a>

                    </td>
                    <th></th>
                    <td>mig</td>
                    <td></td>
                    <td>€ 6,507.04</td>
                    <td>€ 2,427,510.75</td>
                </tr>



                <tr>
                    <td>

                        <a class="various" data-fancybox-type="iframe">36426</a>

                    </td>
                    <th></th>
                    <td>mig</td>
                    <td></td>
                    <td>€ 2,051.94</td>
                    <td>€ 2,439,372.41</td>
                </tr>


                <tr>
                    <td>

                        <a class="various" data-fancybox-type="iframe">36427</a>

                    </td>
                    <th></th>
                    <td>mig</td>
                    <td></td>
                    <td>€ 2,135.10</td>
                    <td>€ 2,441,507.51</td>
                </tr>


                <tr>
                    <td>

                        <a class="various" data-fancybox-type="iframe">36428</a>

                    </td>
                    <th></th>
                    <td>mig</td>
                    <td></td>
                    <td>€ 2,589.45</td>
                    <td>€ 2,444,096.96</td>
                </tr>





                <tr>
                    <td>

                        <a class="various" data-fancybox-type="iframe">36546</a>

                    </td>
                    <th></th>
                    <td>insight for 523</td>
                    <td></td>
                    <td>€ 1,486.88</td>
                    <td>€ 2,475,509.68</td>
                </tr>


                <tr class="newdate">
                    <td>

                        <a class="various" data-fancybox-type="iframe">36337</a>

                    </td>
                    <th>04/07/2023</th>
                    <td>PLANALIFE</td>
                    <td></td>
                    <td>€ 4,422.17</td>
                    <td>€ 2,479,931.85</td>
                </tr>


                <tr>
                    <td>

                        <a class="various" data-fancybox-type="iframe">36344</a>

                    </td>
                    <th></th>
                    <td>RICHARDSON</td>
                    <td></td>
                    <td>€ 180.04</td>
                    <td>€ 2,480,111.89</td>
                </tr>


                <tr>
                    <td>

                        <a class="various" data-fancybox-type="iframe">36345</a>

                    </td>
                    <th></th>
                    <td>CAMPIONS MULLINGAR</td>
                    <td></td>
                    <td>€ 2,255.60</td>
                    <td>€ 2,482,367.49</td>
                </tr>


                <tr>
                    <td>

                        <a class="various" data-fancybox-type="iframe">36347</a>

                    </td>
                    <th></th>
                    <td>MONGELIA BLUE SKY</td>
                    <td></td>
                    <td>€ 25,860.34</td>
                    <td>€ 2,508,227.83</td>
                </tr>


                <tr class="newdate">
                    <td>

                        <a class="various" data-fancybox-type="iframe">36348</a>

                    </td>
                    <th>05/07/2023</th>
                    <td>INSURANCE CONSULTANTS</td>
                    <td></td>
                    <td>€ 2,805.97</td>
                    <td>€ 2,511,033.80</td>
                </tr>


                <tr>
                    <td>

                        <a class="various" data-fancybox-type="iframe">36349</a>

                    </td>
                    <th></th>
                    <td>BRENDAN J KELLY</td>
                    <td></td>
                    <td>€ 5,191.49</td>
                    <td>€ 2,516,225.29</td>
                </tr>


                <tr>
                    <td>

                        <a class="various" data-fancybox-type="iframe">36350</a>

                    </td>
                    <th></th>
                    <td>BEN MCARDLE</td>
                    <td></td>
                    <td>€ 3,782.02</td>
                    <td>€ 2,520,007.31</td>
                </tr>


                <tr>
                    <td>

                        <a class="various" data-fancybox-type="iframe">36403</a>

                    </td>
                    <th></th>
                    <td>ARACHAS</td>
                    <td></td>
                    <td>€ 1,124.87</td>
                    <td>€ 2,521,132.18</td>
                </tr>



                <tr>
                    <td>

                        <a class="various" data-fancybox-type="iframe">36418</a>

                    </td>
                    <th></th>
                    <td>ARACHAS</td>
                    <td></td>
                    <td>€ 8,629.37</td>
                    <td>€ 2,584,831.23</td>
                </tr>


                <tr>
                    <td>

                        <a class="various" data-fancybox-type="iframe">36419</a>

                    </td>
                    <th></th>
                    <td>ARACHAS</td>
                    <td></td>
                    <td>€ 667.25</td>
                    <td>€ 2,585,498.48</td>
                </tr>


                <tr>
                    <td>

                        <a class="various" data-fancybox-type="iframe">36420</a>

                    </td>
                    <th></th>
                    <td>ARACHAS</td>
                    <td></td>
                    <td>€ 15,465.36</td>
                    <td>€ 2,600,963.84</td>
                </tr>


                <tr>
                    <td>

                        <a class="various" data-fancybox-type="iframe">36421</a>

                    </td>
                    <th></th>
                    <td>ARACHAS</td>
                    <td></td>
                    <td>€ 180.04</td>
                    <td>€ 2,601,143.88</td>
                </tr>


                <tr>
                    <td>

                        <a class="various" data-fancybox-type="iframe">36439</a>

                    </td>
                    <th></th>
                    <td>murphy</td>
                    <td></td>
                    <td>€ 2,931.73</td>
                    <td>€ 2,604,075.61</td>
                </tr>



            </tbody>
        </table>
    </div>
</div>