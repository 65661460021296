<div class="card h-100">
    <div class="card-body">
        <h5>Policy Details</h5>
        <div class="row mb-2 g-2 align-items-center">
            <div class="col-md-3">
                <label>Broker</label>
                <kendo-combobox [data]="Broker" class="w-100" placeholder="Select Broker">
                </kendo-combobox>
            </div>
            <div class="col-md">
                <label>Type</label>
                <kendo-combobox [data]="Type" class="w-100" placeholder="Select Broker">
                </kendo-combobox>
            </div>
            <div class="col-md">
                <label>Product</label>
                <kendo-combobox [data]="Product" class="w-100" placeholder="Select Broker">
                </kendo-combobox>
            </div>
            <div class="col-md">
                <label>Proposer</label>
                <input type="text" class="form-control" placeholder="Leave blank to show all">
            </div>
            <div class="col-md">
                <label>Reference Number</label>
                <input type="text" class="form-control" placeholder="Leave blank to show all">
            </div>
            <div class="col-md-auto col-auto if-no-label">
                <button type="button" class="btn btn-primary">
                    Submit
                </button>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <kendo-grid [data]="gridData" [pageable]="true" [pageSize]="10" [sortable]="true" [resizable]="true">
                    <ng-template kendoGridToolbarTemplate>
                        <div class="row g-2 w-100">
                            <div class="col-md m-top-10">
                                <input type="search" placeholder="Search in all columns..."
                                    class="form-control w-100" />
                            </div>
                            <div class="col-md-auto col-auto m-top-10">
                                <button kendoGridExcelCommand type="button" class="m-w-100" icon="file-excel">

                                </button>
                            </div>
                            <div class="col-md-auto col-auto m-top-10">
                                <button kendoGridPDFCommand icon="file-pdf" type="button" class="m-w-100">

                                </button>
                            </div>
                        </div>
                    </ng-template>
                    <kendo-grid-column field="Ref" title="Ref" [headerStyle]="{ width: '100px' }"
                        [style]="{ width: '100px' }">
                        <ng-template kendoGridCellTemplate let-dataItem>
                           <a (click)="getQuote()">{{dataItem?.Ref}}</a>
                        </ng-template>
                    </kendo-grid-column>
                    <kendo-grid-column field="Proposer" title="Proposer" >
                        <ng-template kendoGridCellTemplate let-dataItem>
                            <a (click)="getQuote()">{{dataItem?.Proposer}}</a>
                         </ng-template>
                    </kendo-grid-column>
                    <kendo-grid-column field="Broker" title="Broker" >
                    </kendo-grid-column>
                    <kendo-grid-column field="DateSaved" title="Date Saved" [headerStyle]="{ width: '100px' }"
                        [style]="{ width: '100px' }">
                    </kendo-grid-column>
                    <kendo-grid-column field="Premium" title="Premium" [headerStyle]="{ width: '100px' }"
                        [style]="{ width: '100px' }">
                    </kendo-grid-column>
                </kendo-grid>
            </div>
        </div>
    </div>
</div>