import { Component, OnInit } from "@angular/core";
import { Observable } from "rxjs";
import { OidcSecurityService } from "angular-auth-oidc-client";

@Component({
  selector: "app-forbidden",
  templateUrl: "forbidden.component.html",
  styleUrls: ["./forbidden.component.scss"],
})
export class ForbiddenComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
