<nav class="navbar navbar-expand-lg navbar-light navbar-light" *ngIf="this.isLogin">
    <div class="container-fluid">
        <a class="navbar-brand" style="cursor:pointer;" (click)="opendashoard()">
            <img src="/assets/images/optis-logo.svg" alt="Optis Insurance">
        </a>
        <button class="navbar-toggler collapsed pos-relative" type="button" data-bs-toggle="collapse"
            data-bs-target="#navbar-content">
            <!-- <div class="hamburger-toggle">
                <div class="hamburger">
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
            </div> -->
            <div class="hamburger">
                <span class="line"></span>
                <span class="line"></span>
                <span class="line"></span>
            </div>
        </button>
        <div class="collapse navbar-collapse" id="navbar-content">
            <ul class="navbar-nav mr-auto mb-2 mb-lg-0">
                <li class="nav-item dropdown">
                    <!-- *permission="'CANVIEWDEBTORS'" -->
                    <a class="nav-link dropdown-toggle" data-bs-toggle="dropdown"
                        *ngIf="getMainMenuclaims(['CANVIEWBRRECONSIL','CANVIEWBRAUTORE','CANVIEWAGEDDEBRPT','CASHSUMRPT','CANVIEWFINCANCNOTLET'])">Debtors</a>
                    <ul class="dropdown-menu  animate slideIn">
                        <li><a class="dropdown-item" *permission="'CANVIEWBRRECONSIL'" [routerLink]="['/report/broker-reconciliation-report']">Broker
                                Reconciliation</a></li>
                        <li><a class="dropdown-item" *permission="'CANVIEWBRAUTORE'"
                                [routerLink]="['/report/broker-auto-reconcilation-report']">Broker Auto Rec</a></li>
                        <li><a class="dropdown-item" *permission="'CANVIEWAGEDDEBRPT'"
                                [routerLink]="['/report/rpt-aged-debtor-report']">RPT - Aged Debtors Report</a></li>
                        <li><a class="dropdown-item"  [routerLink]="['/report/rpt-case-book-summary']">RPT - Cashbook Summary</a></li>
                        <li><a class="dropdown-item" *permission="'CANVIEWFINCANCNOTLET'"
                                [routerLink]="['/report/final-cancellation-notice-report']">Final Cancellation Notice</a>
                    </ul>
                </li>
                <!-- *permission="'CANVIEWCREDITORS'" -->
                <li class="nav-item dropdown">
                    <a class="nav-link dropdown-toggle" data-bs-toggle="dropdown"
                        *ngIf="getMainMenuclaims(['CANVIEWCRRECONSIL','CANVIEWAXADACCRRECONSIL','CANVIEWBORDEGEN','CANVIEWAGEDCRRPT','CANVIEWCRRECONSILSUMRPT','CANVIEWCRRECONSILDETRPT'])">Creditors</a>
                    <ul class="dropdown-menu  animate slideIn">
                        <li><a class="dropdown-item" *permission="'CANVIEWCRRECONSIL'"
                                [routerLink]="['/report/creditor-reconciliation']">Creditor Reconciliation</a></li>
                        <li><a class="dropdown-item" *permission="'CANVIEWAXADACCRRECONSIL'"
                                [routerLink]="['/report/axa-dac-creditor-reconciliation-report']">AxaDac Creditor
                                Reconciliation</a></li>
                        <li><a class="dropdown-item" *permission="'CANVIEWBORDEGEN'" [routerLink]="['report/bordereaux-generation']">Bordereaux Generation</a></li>
                        <li><a class="dropdown-item" *permission="'CANVIEWAGEDCRRPT'" [routerLink]="['report/rpt-aged-creditors-report']">RPT - Aged Creditors</a></li>
                        <li><a class="dropdown-item" *permission="'CANVIEWCRRECONSILSUMRPT'" [routerLink]="['report/creditor-reconciliation-summary']">RPT - Creditor 
                                Reconciliation (Summary)</a></li>
                        <li><a class="dropdown-item" *permission="'CANVIEWCRRECONSILDETRPT'" [routerLink]="['report/creditor-reconciliation-detaild']">RPT - Creditor
                                Reconciliation (Detailed)</a></li>
                    </ul>
                </li>
                <!-- *permission="'CANVIEWOFFICEACC'" -->
                <li class="nav-item dropdown">
                    <a class="nav-link dropdown-toggle" data-bs-toggle="dropdown"
                        *ngIf="getMainMenuclaims(['CANVIEWOFFICEACRECONSIL','CANVIEWOFFICEACRECONSILSUM'])">Office
                        Account</a>
                    <ul class="dropdown-menu animate slideIn">
                        <li><a class="dropdown-item" *permission="'CANVIEWOFFICEACRECONSIL'" [routerLink]="['report/office-account-reconciliation']">Office A/c Reconciliation
                                (Transfer to office)</a></li>
                        <li><a class="dropdown-item" *permission="'CANVIEWOFFICEACRECONSILSUM'" [routerLink]="['report/office-account-reconciliation-summary']">RPT - Office A/c
                                Reconciliation (Summary)</a></li>
                    </ul>
                </li>
                <!-- *permission="'CANVIEWBANK'" -->
                <li class="nav-item dropdown">
                    <a class="nav-link dropdown-toggle" data-bs-toggle="dropdown"
                        *ngIf="getMainMenuclaims(['CANVIEWCPBANKRECONSIL','CANVIEWBANKACJOURENTRY','CANVIEWBANKSTATRPT','CANVIEWCPRECONSILRPT'])">Bank</a>
                    <ul class="dropdown-menu animate slideIn">
                        <li><a class="dropdown-item" [routerLink]="['/report/cp-bank-reconciliation-entry']"
                                *permission="'CANVIEWCPBANKRECONSIL'">CP Bank Reconciliation Entry</a></li>
                        <li><a class="dropdown-item" *permission="'CANVIEWBANKACJOURENTRY'"
                                [routerLink]="['/report/bank-account-journal-entry']">Bank A/c Journal Entry</a></li>
                        <li><a class="dropdown-item" *permission="'CANVIEWBANKSTATRPT'"
                                [routerLink]="['/report/rptbank-statement'] ">RPT - Bank Statement</a></li>
                        <li><a class="dropdown-item" [routerLink]="['/report/month-end-client-report']" *permission="'CANVIEWCPRECONSILRPT'">CP Reconciliation Report</a>
                        </li>
                    </ul>
                </li>
                <li class="nav-item dropdown">

                    <a class="nav-link dropdown-toggle" data-bs-toggle="dropdown"
                        *ngIf="getMainMenuclaims(['CANVIEWNEWDIARY','CANVIEWCONTROLPAN','CANVIEWDIARYRPT'])">Diary/Staff</a>
                    <!-- <a class="nav-link dropdown-toggle"  data-bs-toggle="dropdown"  *permission="'CANVIEWDIARYSTAFF'"  >Diary/Staff</a> -->
                    <ul class="dropdown-menu animate slideIn">
                        <li><a class="dropdown-item" *permission="'CANVIEWNEWDIARY'" [routerLink]="['/diary']">New
                                Diary</a></li>
                        <li><a class="dropdown-item" *permission="'CANVIEWDIARYRPT'">Diary Report</a></li>
                        <li><a class="dropdown-item" *permission="'CANVIEWCONTROLPAN'">Control Panel</a></li>
                    </ul>
                </li>
                <li class="nav-item dropdown"
                    *ngIf="getMainMenuclaims(['CANVIEWCHANGEPASSWORD','CANVIEWCROPENMARKTRANS','CANVIEWGENSTATCOMMISTAT','CANVIEWAGENTMAINTENANCE','CANVIEWUSERMAINTENANCE'])">
                    <a class="nav-link dropdown-toggle" data-bs-toggle="dropdown">Staff Tools</a>
                    <ul class="dropdown-menu animate slideIn">
                        <!-- <li> <a class="dropdown-item" *permission="'CANVIEWCHANGEPASSWORD'">Change Password</a></li> -->
                        <li><a class="dropdown-item" [routerLink]="['/open-market-transaction']"
                                *permission="'CANVIEWCROPENMARKTRANS'">Create Open Market Transaction</a></li>
                        <li><a class="dropdown-item" [routerLink]="['/report/commission-statement']" *permission="'CANVIEWGENSTATCOMMISTAT'">Generate
                                Statements/Commission
                                Statement</a></li>
                        <li><a class="dropdown-item" *permission="'CANVIEWAGENTMAINTENANCE'"
                                [routerLink]="['agents/list']">Agent Maintenance</a></li>
                        <li><a class="dropdown-item" *permission="'CANVIEWUSERMAINTENANCE'"
                                [routerLink]="['/users/userlist']">User Management</a></li>
                        <li><a class="dropdown-item" *permission="'CANVIEWANNOUNCEMENT'"
                                [routerLink]="['/announcement-list']">Announcement</a></li>
                    </ul>
                </li>
                  <!-- *ngIf="getMainMenuclaims(['CANVIEWBBGUHOUSE','CANVIEWCOMCOMBINED','CANVIEWCONTRAALLRISK','CANVIEWELPL','CANVIEWOFFICE','CANVIEWPERSNLACCIDENT'
                                               ,'CANVIEWPROFINDEM','CANVIEWPROPOWNERS','CANVIEWPUBHOUSEREST','CANVIEWSELFBUILD','CANVIEWSHOPRETAIL','CANVIEWREALESTATE'])" -->
                <!-- *permission="'CANVIEWINCEPTNEWPOLICY'" -->
                <li class="nav-item dropdown">
                    <a class="nav-link dropdown-toggle" data-bs-toggle="dropdown"
                      
                              *ngIf="getInceptAccess()"                 
                                               >Incept
                        New Policy</a>
                    <ul class="dropdown-menu animate slideIn">
                        <li><a class="dropdown-item" *permission="'CANVIEWBBGUHOUSE'"
                                [routerLink]="['product-tm/bb-new-policy']">B&B / Guesthouse</a></li>
                        <li><a class="dropdown-item" *permission="'CANVIEWCOMCOMBINED'"
                                [routerLink]="['product/commerical-combined']">Commercial Combined</a></li>
                        <li><a class="dropdown-item" *permission="'CANVIEWCONTRAALLRISK'"
                                [routerLink]="['product/contractors-risks']">Contractors All Risks</a></li>
                        <li><a class="dropdown-item" *permission="'CANVIEWELPL'"
                                [routerLink]="['/product/new-policy']">EL / PL</a></li>
                        <li><a class="dropdown-item" *permission="'CANVIEWOFFICE'"
                                [routerLink]="['product/office']">Office</a></li>
                        <li><a class="dropdown-item" *permission="'CANVIEWPERSNLACCIDENT'"
                                [routerLink]="['product-tm/add-personal-accident']">Personal Accident</a></li>
                        <li><a class="dropdown-item" *permission="'CANVIEWPROFINDEM'"
                                [routerLink]="['product-tm/professional-indemnity']">Professional Indemnity</a></li>
                        <!-- <li><a class="dropdown-item" *permission="'CANVIEWPROPOWNERS'" [routerLink]="['/new-policy']">Property Owners</a></li> -->
                        <li><a class="dropdown-item" *permission="'CANVIEWPROPOWNERS'"
                                [routerLink]="['property-owner/main']">Property Owners</a></li>

                        <li><a class="dropdown-item" *permission="'CANVIEWPUBHOUSEREST'"
                                [routerLink]="['product/public-house-restaurant']">Public House/Restaurant/Cafe</a></li>
                        <li><a class="dropdown-item" *permission="'CANVIEWSELFBUILD'"
                                [routerLink]="['product/self-build']">Self Build</a></li>
                        <li><a class="dropdown-item" *permission="'CANVIEWSHOPRETAIL'"
                                [routerLink]="['/product/shop-retail']">Shop Retail</a></li>
                        <li><a class="dropdown-item" *permission="'CANVIEWREALESTATE'"
                                [routerLink]="['product/real-estate']">Real Estate</a></li>
                    </ul>
                </li>
                <li class="nav-item dropdown" *ngIf="getMainMenuclaims(['CANVIEWMANAGEXISTBUSINESS'])">
                    <a class="nav-link" *permission="'CANVIEWMANAGEXISTBUSINESS'"
                        [routerLink]="['/product/search-policy']">Manage Existing Business</a>
                    <!-- <ul class="dropdown-menu animate slideIn">                       
                        <li> <a class="dropdown-item" *permission="'CANVIEWMANAGEXISTBUSINESS'" [routerLink]="['/product/search-policy']">Manage Existing Business</a></li>                        
                    </ul> -->
                </li>

                <li class="nav-item dropdown"
                    *ngIf="getMainMenuclaims(['CANVIEWACCOUNT','CANVIEWBROKERCHANGEPASSWORD','CANVIEWAUTORECONCILIATION'])">
                    <a class="nav-link dropdown-toggle" data-bs-toggle="dropdown">Account</a>
                    <ul class="dropdown-menu animate slideIn">
                        <li>  <a class="dropdown-item"  *permission="'CANVIEWACCOUNT'" >Account</a></li>       

                        <!-- <li>  <a class="dropdown-item"  *permission="'CANVIEWBROKERCHANGEPASSWORD'" >Change Password</a></li>  -->
                        <li>  <a class="dropdown-item"  *permission="'CANVIEWAUTORECONCILIATION'" >Auto Reconciliation</a></li>                      
                    </ul>
                    <!-- <ul class="dropdown-menu animate slideIn">                       
                        <li>  <a class="dropdown-item"  *permission="'CANVIEWBROKERCHANGEPASSWORD'" >Change Password</a></li>                         
                 
                    <ul class="dropdown-menu animate slideIn">
                        <li>  <a class="dropdown-item"  *permission="'CANVIEWAUTORECONCILIATION'" >Auto Reconciliation</a></li>                         
                    </ul> -->

                </li>
                <li class="nav-item dropdown" *ngIf="getMainMenuclaims(['CANVIEWCONTACTUS'])">
                    <a class="nav-link dropdown-toggle" data-bs-toggle="dropdown">Contact Us</a>
                    <ul class="dropdown-menu animate slideIn">
                        <li> <a class="dropdown-item" [routerLink]="['/contact-us']"
                                *permission="'CANVIEWCONTACTUS'">Contact Us</a></li>
                    </ul>
                </li>
                <li class="nav-item dropdown"
                    *ngIf="getMainMenuclaims(['CANVIEWDOCSEARCH','CANVIEWAXADACRPT','CANVIEWAXADOCUMENTCONFIGURATION'])">
                    <a class="nav-link dropdown-toggle" data-bs-toggle="dropdown">Documents</a>
                    <ul class="dropdown-menu animate slideIn">
                        <li> <a class="dropdown-item" [routerLink]="['/document-group']"
                                *permission="'CANVIEWDOCSEARCH'">Search</a></li>
                        <!-- <li><a class="dropdown-item" [routerLink]="['/document-group']"
                                *permission="'CANVIEWAXADACRPT'">Reports</a></li> -->
 
                        <li><a class="dropdown-item" [routerLink]="['/document-configuration']"
                                *permission="'CANVIEWAXADOCUMENTCONFIGURATION'">Document Configuration</a></li>                   </ul>
                </li>


                <li class="nav-item dropdown dropdown-mega position-static"
                    *ngIf="isAgentUser==true && getMainMenuclaims(['CANVIEWSALESRPT','CANVIEWBUSINESSRETENTIONRPT','CANVIEWTOPBROKERS','CANVIEWCOMMIINCOMERPT','CANVIEWBINDERSCONPANEL','CANVIEWMAINPAGEINFOMESSAGE','CANVIEWANNUPOLICYCOUNT','CANVIEWOPTISCOMPLIANCE','CANVIEWRENEWALTRACKCLAIMS','CANVIEWCLAIMSRPT','CANVIEWIMPORTMICLAIMS','CANVIEWQBECANCEL'])">
                    <a class="nav-link dropdown-toggle" data-bs-toggle="dropdown">Report</a>
                    <div class="dropdown-menu animate slideIn">
                        <div class="mega-content px-4">
                            <div class="container-fluid">
                                <div class="row">
                                    <!-- <div class="col-12 col-sm-4 col-md-3 py-4">
                                        <h5 
                                        *ngIf="getMainMenuclaims(['CANVIEWCHANGEPASSWORD','CANVIEWCROPENMARKTRANS','CANVIEWGENSTATCOMMISTAT','CANVIEWAGENTMAINTENANCE','CANVIEWUSERMAINTENANCE'])"
                                        >Staff Tools</h5>
                                        <div class="list-group">
                                            <a class="list-group-item" *permission="'CANVIEWCHANGEPASSWORD'" >Change Password</a>
                                            <a class="list-group-item" [routerLink]="['/open-market-transaction']" *permission="'CANVIEWCROPENMARKTRANS'">Create Open Market Transaction</a>
                                            <a class="list-group-item" *permission="'CANVIEWGENSTATCOMMISTAT'" >Generate Statements/Commission
                                                Statement</a>
                                            <a class="list-group-item" *permission="'CANVIEWAGENTMAINTENANCE'"  [routerLink]="['agents/list']">Agent Maintenance</a>
                                            <a class="list-group-item" *permission="'CANVIEWUSERMAINTENANCE'"  [routerLink]="['/users/userlist']">User Management</a>
                                            <a class="list-group-item" *permission="'CANVIEWANNOUNCEMENT'"  [routerLink]="['/announcement-list']">Announcement</a>
                                        </div> -->


                                    <!-- <h5 class="mt-4"  *ngIf="getMainMenuclaims(['CANVIEWDOCSEARCH','CANVIEWAXADACRPT','CANVIEWAXADOCUMENTCONFIGURATION'])">Documents</h5>
                                        <div class="list-group">
                                            <a class="list-group-item" [routerLink]="['/document-group']" *permission="'CANVIEWDOCSEARCH'" >Search</a>
                                            <a class="list-group-item"[routerLink]="['/document-group']" *permission="'CANVIEWAXADACRPT'" >Reports</a>
                                            <a class="list-group-item"[routerLink]="['/document-configuration']" *permission="'CANVIEWAXADOCUMENTCONFIGURATION'" >Document Configuration</a>
                                    
                                        </div> -->
                                    <div class="col-12 col-sm-4 col-md-3 py-4">
                                        <h5 class="mt-4" *ngIf="getMainMenuclaims(['CANVIEWAXADACRPT'])">AXA Dac</h5>
                                        <div class="list-group">
                                            <!-- <a class="list-group-item"[routerLink]="['/document-group']" *permission="'CANVIEWAXADACRPT'" >Reports</a>
                                            <a class="list-group-item"[routerLink]="['/document-configuration']" *permission="'CANVIEWAXADOCUMENTCONFIGURATION'" >Document Configuration</a> -->
                                            <a class="list-group-item"
                                                [routerLink]="['/report/last-days-policies']">Last 90 days Policies</a>
                                                <a class="list-group-item"
                                                [routerLink]="['/report/loss-ratio-report']">Loss Ratio Reports</a>
                                             <a class="list-group-item"
                                                [routerLink]="['/report/quote-conversion-rates']">Quote Conversion Rate </a>
                                        </div>
                                    </div>
                                    <!-- </div> -->
                                    <div class="col-12 col-sm-12 col-md-3 py-4">
                                        <h5
                                            *ngIf="getMainMenuclaims(['CANVIEWRENEWALTRACKCLAIMS','CANVIEWCLAIMSRPT','CANVIEWIMPORTMICLAIMS','CANVIEWQBECANCEL'])">
                                            Pipeline Tools</h5>
                                        <div class="list-group">
                                            <a class="list-group-item" *permission="'CANVIEWRENEWALTRACKCLAIMS'" [routerLink]="['/report/renewal-tracking-claim-report']" >Renewal Tracking - Claims</a>                                            
                                            <a class="list-group-item" *permission="'CANVIEWCLAIMSRPT'" [routerLink]="['/report/claims-report']">Claims Report</a>

                                            <a class="list-group-item" *permission="'CANVIEWIMPORTMICLAIMS'" [routerLink]="['/report/import-miclaims']" >Import MI Claims</a>
                                            <a class="list-group-item" *permission="'CANVIEWQBECANCEL'" [routerLink]="['/report/qeb-cancellation-report']">QBE Cancellation</a>                                      
                                        </div>

                                        <h5 class="mt-4" *ngIf="getMainMenuclaims(['CANVIEWOPTISCOMPLIANCE'])">Optis
                                            Reports</h5>
                                        <div class="list-group">
                                            <a class="list-group-item" *permission="'CANVIEWOPTISCOMPLIANCE'" [routerLink]="['/report/seven-days-policy-report']"> Compliance - 7 Days Policies </a>
                                        </div>

                                        <!-- <h5 class="mt-4" *ngIf="getMainMenuclaims(['CANVIEWMANAGEXISTBUSINESS'])">Manage Existing Business</h5>
                                        <div class="list-group">
                                            <a class="list-group-item" *permission="'CANVIEWMANAGEXISTBUSINESS'" [routerLink]="['/product/search-policy']">Manage Existing Business</a>
                                        </div> -->
                                    </div>

                                    <div class="col-12 col-sm-12 col-md-3 py-4">
                                        <h5
                                            *ngIf="getMainMenuclaims(['CANVIEWSALESRPT','CANVIEWBUSINESSRETENTIONRPT','CANVIEWTOPBROKERS','CANVIEWCOMMIINCOMERPT','CANVIEWBINDERSCONPANEL','CANVIEWMAINPAGEINFOMESSAGE','CANVIEWANNUPOLICYCOUNT'])">
                                            Management Tools</h5>
                                        <div class="list-group">
                                            <a class="list-group-item" *permission="'CANVIEWSALESRPT'" [routerLink]="['report/sales-report']">Sales Report</a>
                                            <a class="list-group-item"
                                                *permission="'CANVIEWBUSINESSRETENTIONRPT'"  [routerLink]="['report/business-retention-report']">Business Retention
                                                Report</a>
                                            <a class="list-group-item" *permission="'CANVIEWTOPBROKERS'"
                                                [routerLink]="['/report/top-brokers-report']">Top Brokers</a>
                                            <a class="list-group-item"
                                                [routerLink]="['/report/commission-income-report']">Commission Income %
                                                Report</a>
                                            <a class="list-group-item" *permission="'CANVIEWBINDERSCONPANEL'" [routerLink]="['/report/binders-control-panel']">Binders
                                                Control Panel</a>
                                            <a class="list-group-item" *permission="'CANVIEWMAINPAGEINFOMESSAGE'"  [routerLink]="['/report/main-page-alert']">Main
                                                Page Info Message</a>
                                            <a class="list-group-item"
                                                [routerLink]="['/report/policy-on-cover-report']">Annual Policy
                                                Count</a>
                                                 
                                            <a class="list-group-item"
                                                [routerLink]="['/report/monthly-performance']">Monthly Performance
                                            </a>




                                        </div>
                                    </div>
<!-- 
                                    <div class="col-12 col-sm-12 col-md-3 py-4">
                                        <h5>Document Management</h5>
                                        <div class="list-group">
                                            <a class="list-group-item"
                                                [routerLink]="['/document-manager/envelope-template-list']">Envelope
                                                Template</a>
                                            <a class="list-group-item"
                                                [routerLink]="['/document-manager/design-envelope-list']">Design
                                                Envelope</a>
                                            <a class="list-group-item"
                                                [routerLink]="['/document-manager/schedule-envelope-list']">Schedule
                                                Envelope</a>
                                        </div>
                                    </div> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </li>
            </ul>

            <form class="d-flex ms-auto">
                <!-- <div class="input-group">
                    <input class="form-control border-0 mr-2" type="search" placeholder="Search" aria-label="Search">
                </div> -->
                <div class="user-box dropdown px-2">
                    <a class="d-flex align-items-center pe-1 nav-link dropdown-toggle gap-3 dropdown-toggle-nocaret"
                        role="button" data-bs-toggle="dropdown" aria-expanded="false">
                        <div class="user-info">
                            <p class="user-name mb-0">{{username}}</p>
                        </div>
                    </a>
                    <ul class="dropdown-menu dropdown-menu-end">
                        <li><a class="dropdown-item d-flex align-items-center" href="javascript:;"
                                [routerLink]="'/app-userprofile'">
                                <i class="bx bx-user fs-5"></i><span>Profile</span></a>
                        </li>
                        <li  *permission="'CANVIEWSETTINGS'"><a class="dropdown-item d-flex align-items-center" href="javascript:;"
                                [routerLink]="'/configuration'">
                                <i class="bx bx-cog fs-5"></i><span>Settings</span></a>
                        </li>

                        <li><a class="dropdown-item d-flex align-items-center" href="javascript:;"
                                [routerLink]="'/app-change-password'"><i class="bx bx-home-circle fs-5"></i><span>Change
                                    Password</span></a>

                        <li><a class="dropdown-item d-flex align-items-center" (click)="logout()"><i
                                    class="bx bx-log-out-circle"></i><span>Logout</span></a>
                        </li>
                    </ul>
                </div>
            </form>
        </div>
    </div>
</nav>

 <div class="consent-modal" *ngIf="isShowonSite">
    <div class="consent-modal-body">
        <div class="cm-header d-flex align-items-center justify-content-between">
         <h6>IMPORTANT CUSTOMER NOTICE</h6>
         <span  class="material-symbols-outlined cursor-pointer text-white"  (click)="showonSiteMsg()"> close </span>
        </div>
         <p [innerHTML]="messageDisplay"></p>
      </div>
</div> 