<div class="card h-100">
  <div class="card-body">
    <div class="row mb-2 g-2 align-items-center">
      <div class="col-md-3">
        <h5>Documents</h5>
      </div>
      <div class="col-md-auto ms-auto">
        <label>To see Previous Version of Policy Wording Click on Archived Document </label>
      </div>
      <div class="col-md-auto">
        <div class="custom-control custom-checkbox cursor-pointer d-flex ms-4 m-pl-24">
          <input type="checkbox" class="custom-control-input" id="archive" [(ngModel)]="isArchived"
            [ngModelOptions]="{ standalone: true }" (change)="onArchiveCheckboxChange()">
          <label class="custom-control-label cursor-pointer mb-0 ms-2" for="archive">
            Archived 
          </label>
        </div>
      </div>
    </div>
    <div class="accordion-header" id="accordionExample1">
      <div
        class="accordion-item mb-3"
        *ngFor="let item of documentGroupdata; let i = index"
        [hidden]="!isArchived && item.isHide"
      >
        <h2 class="accordion-header" [id]="'headingOne1_' + i">
          <button
            class="accordion-button collapsed"
            type="button"
            [attr.data-bs-toggle]="'collapse'"
            [attr.data-bs-target]="'#collapseOne1_' + i"
            [attr.aria-expanded]="i === expandedIndex ? 'true' : 'false'"
            [attr.aria-controls]="'collapseOne1_' + i"
            (click)="toggleAccordion(i); GetdocumetnFile(item)"
          >
            {{ item.documentGroupName }}
          </button>
        </h2>
        <div
          [id]="'collapseOne1_' + i"
          class="accordion-collapse collapse"
          [class.show]="i === expandedIndex"
          [attr.aria-labelledby]="'headingOne1_' + i"
          data-bs-parent="#accordionExample1"
        >
          <div class="accordion-body">
            <div class="row gy-3 gx-3">
              <div class="col-md-6" *ngFor="let docItem of documentFiledata">
                <h6 class="mt-4">{{ docItem.header }}</h6>
                <div class="policy-box cursor-pointer d-flex justify-content-between">
                  <a class="font-weight-600" (click)="openFile(docItem.path)">{{ docItem.fileName }}</a>
                  <span class="material-symbols-outlined cursor-pointer" (click)="downloadFile(docItem.path)">
                    download
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>