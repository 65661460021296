import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AboutInsurerComponent } from './about-insurer/about-insurer.component';
import { GridDataDiaryService } from '@app/services/gridData.diary.service';
import { Subscription } from 'rxjs';
import { State } from '@progress/kendo-data-query';
import { DataStateChangeEvent } from '@progress/kendo-angular-grid';
import { EditDiaryComponent } from '@app/features-modules/diary/edit-diary/edit-diary.component';
import { InsuranceService } from '@app/services/insurance.service';
import { Router } from '@angular/router';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})


@AutoUnsubscribe({ arrayName: "subscription" })
export class DashboardComponent implements OnInit, OnDestroy {
  index = 0;
  agentId = 0;
  userId = 0;
  count: any;
  DairyApiBasePath = 'Insurance/Diary/SearchDiary';
  public totalCount = 0;
  searchText: any;
  isOuterSearch: boolean;
  public dairyState: State = {
    skip: 0,
    take: 5,
    filter: { filters: [], logic: "or" },
  };
  dashboardSummary: any;

  public searchParameter: any = {
    agentIds: null,
    handlerIds: [this.userId],
    productIds: null,
    statusIds: [1],
  }

  phoneNumber: string;
  contactTime: string;
  
  announcementList: [];
  dairyDataList: any;
  activeInsurer: any;

  configueData: any;
  private subscription = new Subscription();
  constructor(private modalService: NgbModal, private insuranceService: InsuranceService,
    private gridDataService: GridDataDiaryService,
    private router: Router
  ) {
  }

  ngOnInit(): void {
    this.GetActiveInsurer();
    this.loadDiaryData();
    this.GetActiveAnnouncement();
    this.GetCompanyContact();
    this.GetDashboardSummary();
  }
  ngOnDestroy() {

  }
  aboutInsurere() {
    const modalref = this.modalService.open(AboutInsurerComponent, {
      backdrop: "static",
      windowClass: "",
      centered: true,
      size: "lg",
    });
  }


  GetDashboardSummary(): void {
    this.insuranceService.GetDashboardSummary().subscribe((data: any): void => {
      this.dashboardSummary = data;
    });
  }

  navigateToPolicyStatus(statusId: number): void {
    const encryptedQueryParams = this.insuranceService.encryptData({ policystatusId: statusId });
    this.router.navigate(['product/search-policy'], { queryParams: { data: encryptedQueryParams } });
  }

  GetActiveInsurer() {
    this.insuranceService.GetActiveInsurer().subscribe((response: any): void => {
      this.activeInsurer = response;
    });
  }
  
  GetCompanyContact() {
    this.insuranceService.getCompanyContact().subscribe(config => {
      this.phoneNumber = config.phoneNumber;
      this.contactTime = config.time;
    });
  }

  GetActiveAnnouncement() {
    this.insuranceService.GetActiveNotification().subscribe((response: any): void => {
      if (response && response?.length > 0) {
        this.announcementList = response;
      }
    });
  }

  loadDiaryData() {

    var userDetails = JSON.parse(window.sessionStorage.getItem('authentication'));
    if (userDetails != undefined) {
      this.userId = userDetails.userId;
      this.searchParameter.handlerIds = [this.userId];
    }
    this.gridDataService.read(
      this.isOuterSearch ? "outerSearch" : "",
      "",
      this.DairyApiBasePath,
      this.searchParameter,
      this.dairyState.take
    );
    this.dairyDataList = this.gridDataService;
    this.subscription.add(

      this.dairyDataList.subscribe((data: any): void => {
        if (data != null && data != undefined) {
          this.count = data.total;
          //this.loadExterNalScript();  

        }
      })
    );

  }



  public dataStateChangeDairy(state: DataStateChangeEvent): void {
    if (this.searchText != undefined && this.searchText.length > 2) {
      this.gridDataService.state = state;
      this.dairyState = state;
      this.filterData(true);
    } else {
      this.gridDataService.state = state;
      this.dairyState = state;
      this.gridDataService.read(
        this.isOuterSearch ? "outerSearch" : "",
        "",
        this.DairyApiBasePath,
        this.searchParameter, this.dairyState.take,
        ""
      );
    }
  }

  filterData(isfromstatechange: Boolean = false) {
    if (this.searchText == null) {
      return this.gridDataService.defaultLoad(
        "",
        this.searchText,
        this.DairyApiBasePath,
        this.searchParameter,
        this.dairyState.take,
        ""
      );
    }

    if (!isfromstatechange) {
      this.gridDataService.state.skip = 0;
      this.gridDataService.state.take = 5;
    }
    if (this.searchText.length > 2) {
      this.gridDataService.read(
        this.isOuterSearch ? "outerSearch" : "search",
        this.searchText,
        this.DairyApiBasePath,
        this.searchParameter,
        this.dairyState.take,
        ""
      );
    } else if (this.searchText.length === 0) {
      this.gridDataService.state.skip = 0;
      this.gridDataService.state.take = 5;
      this.gridDataService.read(
        this.isOuterSearch ? "outerSearch" : "",
        "",
        this.DairyApiBasePath,
        this.searchParameter,
        this.dairyState.take,
        ""
      );
    }
    this.index = this.index + 1;
    this.dairyDataList = this.gridDataService;
  }

  editDiary(diaryId) {
    const modalref = this.modalService.open(EditDiaryComponent, {
      backdrop: "static",
      backdropClass: "modal-on-modal",
      windowClass: "modal-on-modal-dialog",
      centered: true,
      size: "700",
    });
    modalref.componentInstance.diaryId = diaryId;
    modalref.result.then((data: any) => {
      if (data != null) {
        this.loadDiaryData();
        // this.getQuoteDataList(data);
        // this.getQuoteDetails(data);
      }
    });
  }
  goToQuote(policyId,productcode) {
    let encryptedqueryparams: { policyId: number; };
    encryptedqueryparams = { policyId: policyId }
    console.log(this.insuranceService.encryptData(encryptedqueryparams))
    if (productcode == "ELPL")
      this.router.navigate(['product/quote-details'], { queryParams: { data: this.insuranceService.encryptData(encryptedqueryparams) } });
    else
      this.router.navigate(['product/commquote-dashboard'], { queryParams: { data: this.insuranceService.encryptData(encryptedqueryparams) } });
  }
}
