<form novalidate [formGroup]="documentTest" (ngSubmit)="saveData()">
    <div class="card h-100">
        <div class="card-body">
            <h5 class="form-group mb-2">Document File</h5>
            <div class="row mb-2 g-2 align-items-center">
                <div class="form-group col-md-3">
                    <label>Document Group</label>
                    <kendo-combobox [data]="documentListdata" formControlName="documentGroupId" [valuePrimitive]="true"
                        [textField]="'documentGroupName'" [valueField]="'documentGroupId'" class="w-100"
                        placeholder="Select Group Name">
                    </kendo-combobox>
                    <div *ngIf="documentTest.controls['documentGroupId'].touched && documentTest.controls['documentGroupId'].errors?.required"
                        class="validation-msg-label">
                        Required
                    </div>
                </div>
                <div class="form-group col-md-3">
                    <label>File</label>
                    <input type="file" #file class="form-control fileChoose position-relative w-100" formControlName="path" placeholder="File"
                        (change)="onSelect($event)">
                    <div *ngIf="documentTest.controls['path'].touched && documentTest.controls['path'].errors?.required"
                        class="validation-msg-label">
                        Required
                    </div>
                    <div *ngIf="documentTest.controls['path'].touched && documentTest.controls['path'].errors?.invalidFileExtension"
                        class="validation-msg-label">
                        Invalid file type.
                    </div>
                </div>
                <div class="form-group col-md">
                    <label>File Name</label>
                    <input type="text" class="form-control" formControlName="fileName" placeholder="File name">
                    <div *ngIf="documentTest.controls['fileName'].touched && documentTest.controls['fileName'].errors?.required"
                        class="validation-msg-label">
                        Required
                    </div>
                    <div *ngIf="documentTest.controls['fileName'].touched && documentTest.controls['fileName'].errors?.maxlength"
                        class="validation-msg-label">
                        Maximum length is 100 characters
                    </div>
                    <div class="validation-msg-label"
                        *ngIf="documentTest.controls['fileName'].touched  && documentTest.controls['fileName'].errors?.whitespace">
                        Whitespace not allowed.
                    </div>
                </div>
                <div class="form-group col-md">
                    <label>Header</label>
                    <input type="text" class="form-control" formControlName="header" placeholder="Header">
                    <div *ngIf="documentTest.controls['header'].touched && documentTest.controls['header'].errors?.maxlength"
                        class="validation-msg-label">
                        Maximum length is 100 characters
                    </div>
                    <div class="validation-msg-label"
                        *ngIf="documentTest.controls['header'].touched  && documentTest.controls['header'].errors?.whitespace">
                        Whitespace not allowed.
                    </div>
                </div>
                <div class="form-group col-md-auto col-auto if-no-label">
                    <button type="submit" class="btn btn-primary">
                        Add
                    </button>
                    <button type="button" class="btn btn-sm btn-secondary ms-2" (click)="clearData()">
                        Clear
                    </button>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12 custom-grid-plus pc-grid">
                    <kendo-grid [pageable]="true" [kendoGridBinding]="documentFiledata" [sortable]="true"
                        [pageSize]="10">
                        <kendo-grid-column field="documentGroupName" title="Document Group Name"
                            [headerStyle]="{ width: '150px' }" [style]="{ width: '150px' }">
                        </kendo-grid-column>
                        <kendo-grid-column field="fileName" title="File Name" [headerStyle]="{ width: '200px' }"
                            [style]="{ width: '200px' }">
                        </kendo-grid-column>
                        <kendo-grid-column field="header" title="Header" [headerStyle]="{ width: '100px' }"
                            [style]="{ width: '100px' }">
                        </kendo-grid-column>
                        <kendo-grid-column field="isArchived" title="Archive" [headerStyle]="{ width: '18px' }"
                            [style]="{ width: '18px' }">
                            <ng-template kendoGridCellTemplate let-dataItem>
                                <input type="checkbox" class="mx-2" [ngModelOptions]="{ standalone: true }"
                                    [(ngModel)]="dataItem.isArchived" (change)="toggleArchive(dataItem)" />
                            </ng-template>
                        </kendo-grid-column>
                        <kendo-grid-column title="Action" [headerStyle]="{ width: '30px' }" [style]="{ width: '30px'}">
                            <ng-template kendoGridCellTemplate let-dataItem>
                                <div class="d-flex gap-3">
                                    <span class="material-symbols-outlined cursor-pointer"
                                        (click)="downloadFile(dataItem.path)">download</span>
                                    <span class="material-symbols-outlined cursor-pointer text-danger"
                                        (click)="remove(dataItem.documentFileId)">delete</span>
                                </div>
                            </ng-template>
                        </kendo-grid-column>
                    </kendo-grid>
                </div>
            </div>
        </div>
    </div>
</form>