import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TransactionDetailComponent } from '@app/features-modules/product/transaction-detail/transaction-detail.component';
import { InsuranceService } from '@app/services/insurance.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ListTable } from '@shared/models';
import { getExactDate, getExactDateWithoutTime } from '@shared/utility';
import { ToasterService } from '@shared/utility/toaster.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-open-market-transaction',
  templateUrl: './open-market-transaction.component.html',
  styleUrl: './open-market-transaction.component.css'
})
export class OpenMarketTransactionComponent {
  brokerList: [];
  policyPrim = 0;
  transId = 0;
  transactionTypeList: [];
  currentDate = new Date();
  openMarketTransForm: FormGroup;
  private subscription = new Subscription();
  isShowTranssummContaint :boolean=false;
  savedProposerName: any;
  constructor(private fb: FormBuilder, private insuranceService: InsuranceService, private toasterService: ToasterService,private modalService: NgbModal) {

  }
  ngOnInit(): void {
    this.isShowTranssummContaint=false;
    this.initForm();
    this.GetPolicyTransactionTypes();
    this.GetAgentList();
  }

  initForm() {
    this.openMarketTransForm = this.fb.group({
      transactionDate: [new Date(), Validators.required],
      transactionType: [null, Validators.required],
      brokerId: [null, Validators.required],
      proposer: [null, Validators.required],
      inceptionDate: [new Date(), Validators.required],
      policyPreminum: [null, Validators.required],
      levy: [5, [Validators.required, Validators.pattern(/^\d+$/), Validators.min(0), Validators.max(100)]],
      fees: [null, Validators.required],
      retailPremium: [null, Validators.required],
      policyNote: [null],
      totalCommisionPer: [null, [Validators.required, Validators.pattern(/^\d+$/), Validators.min(0), Validators.max(100)]],
      brokerCommisionPer: [null, [Validators.required, Validators.pattern(/^\d+$/), Validators.min(0), Validators.max(100)]],
      llyodsCommisionPer: [null, [Validators.required, Validators.pattern(/^\d+$/), Validators.min(0), Validators.max(100)]],
      optisCommisionPer: [null, Validators.required],
      totalCommision: [null, Validators.required],
      brokerCommision: [null, Validators.required],
      llyodsCommision: [null, Validators.required],
      optisCommision: [null, Validators.required],
    });
    this.openMarketTransForm.controls.retailPremium.disable();
    this.openMarketTransForm.controls.optisCommisionPer.disable();
    this.openMarketTransForm.controls.totalCommision.disable();
    this.openMarketTransForm.controls.brokerCommision.disable();
    this.openMarketTransForm.controls.llyodsCommision.disable();
    this.openMarketTransForm.controls.optisCommision.disable();
  }

  GetPolicyTransactionTypes() {
    this.subscription.add(
      this.insuranceService.GetOpenMarketTransactionTypes()
        .subscribe((data: any) => {
          if (data !== undefined && data != null) {
            this.transactionTypeList = data;
            if (data.length > 0) {
              this.openMarketTransForm.controls.transactionType.setValue(data[1].value);
            }
          }
        })
    );
  }

  GetAgentList() {
    this.insuranceService.getAgent().subscribe((data: any): void => {
      this.brokerList = data;
    });
  }

  calculateRetailPremium() {
    const policyPreminum = parseFloat(this.openMarketTransForm.controls.policyPreminum.value ? this.openMarketTransForm.controls.policyPreminum.value : 0);
    const fees = parseFloat(this.openMarketTransForm.controls.fees.value ? this.openMarketTransForm.controls.fees.value : 0);
    this.openMarketTransForm.controls.retailPremium.setValue(policyPreminum + fees);
    if (this.openMarketTransForm.controls.totalCommisionPer.value) {
      this.calculateTotalCommision();
    }
    else {
      this.openMarketTransForm.controls.totalCommision.setValue(0);
      this.openMarketTransForm.controls.brokerCommision.setValue(0);
      this.openMarketTransForm.controls.llyodsCommision.setValue(0);
      this.openMarketTransForm.controls.optisCommision.setValue(0);
      this.openMarketTransForm.controls.fees.setValue(fees);
    }
  }

  calculateTotalCommision() {
    const brokerCommisionPer = parseFloat(this.openMarketTransForm.controls.brokerCommisionPer.value ? this.openMarketTransForm.controls.brokerCommisionPer.value : 0);
    const llyodsCommisionPer = parseFloat(this.openMarketTransForm.controls.llyodsCommisionPer.value ? this.openMarketTransForm.controls.llyodsCommisionPer.value : 0);
    const retailPremium = parseFloat(this.openMarketTransForm.controls.retailPremium.value ? this.openMarketTransForm.controls.retailPremium.value : 0);
    const totalCommisionPer = parseFloat(this.openMarketTransForm.controls.totalCommisionPer.value ? this.openMarketTransForm.controls.totalCommisionPer.value : 0);
    let levy = parseFloat(this.openMarketTransForm.controls.levy.value ? this.openMarketTransForm.controls.levy.value : 0);
    if (retailPremium <= 0) {
      this.toasterService.error("Retail Premium should be greater than 0.")
      return;
    }
    // if (levy <= 0) {
    //   this.toasterService.error("Levy should be greater than 0.")
    // }
    if (totalCommisionPer > 0 && this.openMarketTransForm.controls.policyPreminum.value > 0) {
      let txtprem = this.openMarketTransForm.controls.policyPreminum.value;
      let lvyper = (1 + (levy / 100));
      let val = totalCommisionPer;
      let tempComval = txtprem / lvyper;
      tempComval = tempComval * (val / 100);
      let totalCommision = Math.round(tempComval * 100) / 100;
      // const calculatesRetail = (retailPremium / (1 + (levy / 100)));
      // let totalCommision = calculatesRetail * (totalCommisionPer / 100);
      // totalCommision = Math.round(totalCommision * 100)/100
      this.openMarketTransForm.controls.totalCommision.setValue(totalCommision);
      if (brokerCommisionPer > 0) {
        this.calculateBrokerCommision();
      }
      else {
        this.openMarketTransForm.controls.brokerCommision.setValue(0);
        this.openMarketTransForm.controls.brokerCommisionPer.setValue(0);
      }
      if (llyodsCommisionPer > 0) {
        this.calculateLlyodsCommision();
      }
      else {
        this.openMarketTransForm.controls.llyodsCommision.setValue(0);
        this.openMarketTransForm.controls.llyodsCommisionPer.setValue(0);
      }
    }
    else {
      this.openMarketTransForm.controls.totalCommision.setValue(0);
    }
    this.calculateOptisCommision();
  }

  calculateBrokerCommision() {
    const totalCommision = parseFloat(this.openMarketTransForm.controls.totalCommision.value ? this.openMarketTransForm.controls.totalCommision.value : 0);
    const llyodsCommisionPer = parseFloat(this.openMarketTransForm.controls.llyodsCommisionPer.value ? this.openMarketTransForm.controls.llyodsCommisionPer.value : 0);
    const brokerCommisionPer = parseFloat(this.openMarketTransForm.controls.brokerCommisionPer.value ? this.openMarketTransForm.controls.brokerCommisionPer.value : 0);
    const totalCommisionPer = parseFloat(this.openMarketTransForm.controls.totalCommisionPer.value ? this.openMarketTransForm.controls.totalCommisionPer.value : 0);
    if (totalCommisionPer <= 0) {
      // this.toasterService.error("Total Commision should be greater than 0.")
      this.openMarketTransForm.controls.brokerCommision.setValue(0);
      this.openMarketTransForm.controls.brokerCommisionPer.setValue(0);
    }
    if (totalCommisionPer < (llyodsCommisionPer + brokerCommisionPer)) {
      this.toasterService.error("Broker/Llyods Commision cannot be greater than total Commision")
      this.openMarketTransForm.controls.brokerCommision.setValue(0);
      this.openMarketTransForm.controls.brokerCommisionPer.setValue(0);
      return
    }
    if (brokerCommisionPer > 0) {
      const value = totalCommision / totalCommisionPer * brokerCommisionPer;
      this.openMarketTransForm.controls.brokerCommision.setValue(value.toFixed(2));
    }
    else {
      this.openMarketTransForm.controls.brokerCommision.setValue(0);
    }

    this.calculateOptisCommision();
  }

  calculateLlyodsCommision() {
    const llyodsCommisionPer = parseFloat(this.openMarketTransForm.controls.llyodsCommisionPer.value ? this.openMarketTransForm.controls.llyodsCommisionPer.value : 0);
    const totalCommision = parseFloat(this.openMarketTransForm.controls.totalCommision.value ? this.openMarketTransForm.controls.totalCommision.value : 0);
    const totalCommisionPer = parseFloat(this.openMarketTransForm.controls.totalCommisionPer.value ? this.openMarketTransForm.controls.totalCommisionPer.value : 0);
    const brokerCommisionPer = parseFloat(this.openMarketTransForm.controls.brokerCommisionPer.value ? this.openMarketTransForm.controls.brokerCommisionPer.value : 0);
    if (totalCommisionPer <= 0) {
      // this.toasterService.error("Total Commision should be greater than 0.")
      this.openMarketTransForm.controls.llyodsCommision.setValue(0);
      this.openMarketTransForm.controls.llyodsCommisionPer.setValue(0);
      // return
    }
    if (totalCommisionPer < (llyodsCommisionPer + brokerCommisionPer)) {
      this.toasterService.error("Broker/Llyods Commision cannot be greater than total Commision")
      this.openMarketTransForm.controls.llyodsCommision.setValue(0);
      this.openMarketTransForm.controls.llyodsCommisionPer.setValue(0);
      return
    }

    if (llyodsCommisionPer > 0) {
      const value = totalCommision / totalCommisionPer * llyodsCommisionPer;
      this.openMarketTransForm.controls.llyodsCommision.setValue(value.toFixed(2));
    }
    else {
      this.openMarketTransForm.controls.llyodsCommision.setValue(0);
    }
    this.calculateOptisCommision();
  }

  calculateOptisCommision() {
    const totalCommisionPer = this.openMarketTransForm.controls.totalCommisionPer.value ? this.openMarketTransForm.controls.totalCommisionPer.value : 0;
    const brokerCommisionPer = this.openMarketTransForm.controls.brokerCommisionPer.value ? this.openMarketTransForm.controls.brokerCommisionPer.value : 0;
    const llyodsCommisionPer = this.openMarketTransForm.controls.llyodsCommisionPer.value ? this.openMarketTransForm.controls.llyodsCommisionPer.value : 0;
    const totalCommision = this.openMarketTransForm.controls.totalCommision.value ? this.openMarketTransForm.controls.totalCommision.value : 0;
    const brokerCommision = this.openMarketTransForm.controls.brokerCommision.value ? this.openMarketTransForm.controls.brokerCommision.value : 0;
    const llyodsCommision = this.openMarketTransForm.controls.llyodsCommision.value ? this.openMarketTransForm.controls.llyodsCommision.value : 0;
    const optisCommision = this.openMarketTransForm.controls.optisCommision.value ? this.openMarketTransForm.controls.optisCommision.value : 0;
    this.openMarketTransForm.controls.optisCommisionPer.setValue((totalCommisionPer - brokerCommisionPer - llyodsCommisionPer).toFixed(2));
    this.openMarketTransForm.controls.optisCommision.setValue((totalCommision / totalCommisionPer * (this.openMarketTransForm.controls.optisCommisionPer.value)).toFixed(2));
  }
  
  Save() {
    const totalCommision = parseFloat(this.openMarketTransForm.controls.totalCommision.value ? this.openMarketTransForm.controls.totalCommision.value : 0);
    const llyodsCommisionPer = parseFloat(this.openMarketTransForm.controls.llyodsCommisionPer.value ? this.openMarketTransForm.controls.llyodsCommisionPer.value : 0);
    const brokerCommisionPer = parseFloat(this.openMarketTransForm.controls.brokerCommisionPer.value ? this.openMarketTransForm.controls.brokerCommisionPer.value : 0);
    const totalCommisionPer = parseFloat(this.openMarketTransForm.controls.totalCommisionPer.value ? this.openMarketTransForm.controls.totalCommisionPer.value : 0);
    // if (totalCommisionPer <= 0) {
    //   this.toasterService.error("Total Commision should be greater than 0.")
    //   return
    // }
    if (totalCommisionPer < (llyodsCommisionPer + brokerCommisionPer)) {
      this.toasterService.error("Broker/Llyods Commision cannot be greater than total Commision")
      return
    }
    if (this.openMarketTransForm.valid) {
      this.savedProposerName = this.openMarketTransForm.controls.proposer.value;
      const objClaimSave = Object.assign({});
      objClaimSave.transactionDate = getExactDate(this.openMarketTransForm.controls.transactionDate.value);
      objClaimSave.transactionType = this.openMarketTransForm.controls.transactionType.value;
      objClaimSave.brokerId = this.openMarketTransForm.controls.brokerId.value;
      objClaimSave.proposer = this.openMarketTransForm.controls.proposer.value;
      objClaimSave.inceptionDate = getExactDate(this.openMarketTransForm.controls.inceptionDate.value);
      objClaimSave.policyPremium = this.openMarketTransForm.controls.policyPreminum.value;
      objClaimSave.levy = this.openMarketTransForm.controls.levy.value;
      objClaimSave.fees = this.openMarketTransForm.controls.fees.value;
      objClaimSave.retailPremium = this.openMarketTransForm.controls.retailPremium.value;
      objClaimSave.policyNote = this.openMarketTransForm.controls.policyNote.value;
      objClaimSave.totalCommisionPer = this.openMarketTransForm.controls.totalCommisionPer.value;
      objClaimSave.brokerCommisionPer = this.openMarketTransForm.controls.brokerCommisionPer.value;
      objClaimSave.lloydsCommisionPer = this.openMarketTransForm.controls.llyodsCommisionPer.value;
      objClaimSave.optisCommisionPer = this.openMarketTransForm.controls.optisCommisionPer.value;
      objClaimSave.totalCommision = this.openMarketTransForm.controls.totalCommision.value;
      objClaimSave.brokerCommision = this.openMarketTransForm.controls.brokerCommision.value;
      objClaimSave.lloydsCommision = this.openMarketTransForm.controls.llyodsCommision.value;
      objClaimSave.optisCommision = this.openMarketTransForm.controls.optisCommision.value;
      this.subscription.add(
        this.insuranceService.SaveOpenTransaction(objClaimSave).subscribe(data => {
          if (data) {
            this.isShowTranssummContaint = true;
            this.transId = data?.payload?.acTransactionId
            this.toasterService.success("Transaction Saved Successfully");
            this.openMarketTransForm.reset();
          }
        })
      );
    }
    else {
      this.insuranceService.validateAllFormFields(this.openMarketTransForm);
    }
  }
  openTransactions(transactionID: any) {
    const modalref = this.modalService.open(TransactionDetailComponent, {
      backdrop: "static",
      windowClass: "",
      centered: true,
      size: "lg",
    });
    modalref.componentInstance.transactionId = transactionID;
    modalref.componentInstance.proposer = this.savedProposerName;
    modalref.componentInstance.policyNo = 'TBC';
  }
}
