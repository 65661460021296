import { Component } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EditPolicyQuoteComponent } from '../edit-policy-quote/edit-policy-quote.component';

@Component({
  selector: 'app-quote-details-dialog',
  templateUrl: './quote-details-dialog.component.html',
  styleUrls: ['./quote-details-dialog.component.scss']
})
export class QuoteDetailsDialogComponent {
  constructor(
    private activeModal: NgbActiveModal, private modalService: NgbModal
  ) { }

  public gridData = [
    {
      Ref: '500164',
      Proposer: "O Meara Aspect Holding Ltd",
      Inception: 'Optis Insurance',
      twosixindemnity: '€ 218.39',
      sixfiveindemnity: '€ 418.39',
      SanctionsCheck: 'Pass (v5)',
      dateSaved: '09/09/2023',
      action: '',
    },
    {
      Ref: '500164',
      Proposer: "O Meara Aspect Holding Ltd",
      Inception: 'Optis Insurance',
      twosixindemnity: '€ 218.39',
      sixfiveindemnity: '€ 418.39',
      SanctionsCheck: 'Pass (v5)',
      dateSaved: '09/09/2023',
      action: '',
    },
    {
      Ref: '500164',
      Proposer: "O Meara Aspect Holding Ltd",
      Inception: 'Optis Insurance',
      twosixindemnity: '€ 218.39',
      sixfiveindemnity: '€ 418.39',
      SanctionsCheck: 'Pass (v5)',
      dateSaved: '09/09/2023',
      action: '',
    },
    {
      Ref: '500164',
      Proposer: "O Meara Aspect Holding Ltd",
      Inception: 'Optis Insurance',
      twosixindemnity: '€ 218.39',
      sixfiveindemnity: '€ 418.39',
      SanctionsCheck: 'Pass (v5)',
      dateSaved: '09/09/2023',
      action: '',
    },
    {
      Ref: '500164',
      Proposer: "O Meara Aspect Holding Ltd",
      Inception: 'Optis Insurance',
      twosixindemnity: '€ 218.39',
      sixfiveindemnity: '€ 418.39',
      SanctionsCheck: 'Pass (v5)',
      dateSaved: '09/09/2023',
      action: '',
    },
    {
      Ref: '500164',
      Proposer: "O Meara Aspect Holding Ltd",
      Inception: 'Optis Insurance',
      twosixindemnity: '€ 218.39',
      sixfiveindemnity: '€ 418.39',
      SanctionsCheck: 'Pass (v5)',
      dateSaved: '09/09/2023',
      action: '',
    },
    {
      Ref: '500164',
      Proposer: "O Meara Aspect Holding Ltd",
      Inception: 'Optis Insurance',
      twosixindemnity: '€ 218.39',
      sixfiveindemnity: '€ 418.39',
      SanctionsCheck: 'Pass (v5)',
      dateSaved: '09/09/2023',
      action: '',
    },
  ];

  closeDialog() {
    this.activeModal.close(false);
  }

  editQuote() {
    const modalref = this.modalService.open(EditPolicyQuoteComponent, {
      backdrop: "static",
      backdropClass:"modal-on-modal",
      windowClass: "modal-on-modal-dialog",
      centered: true,
      size: "lg",
    });
  }
}
