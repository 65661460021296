import { Component } from '@angular/core';
import { Router, RouteConfigLoadStart, RouteConfigLoadEnd, NavigationStart, ActivatedRoute } from '@angular/router';
import { OidcSecurityService, LoginResponse } from 'angular-auth-oidc-client';
import { AuthService } from './auth/auth.service';
import { Subscription, Observable } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'OptisInsurance';
  public isShowingRouteLoadIndicator: boolean;
  isLogin: boolean;
  showHead: boolean;
  private isAuthorizedSubscription: Subscription = new Subscription();
  public isAuthorized = false;
  userData$: Observable<any>;
  isAuthenticated$: Observable<any>;
  isAuthenticated: boolean;
  userName: any;

  constructor(
    private oidcSecurityService: OidcSecurityService,
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.isAuthenticated$ = this.oidcSecurityService.isAuthenticated$;
    this.userData$ = this.oidcSecurityService.userData$;
    this.isShowingRouteLoadIndicator = false;
    var asyncLoadCount = 0;
    router.events.subscribe((event): void => {
      if (event instanceof RouteConfigLoadStart) {
        asyncLoadCount++;
      } else if (event instanceof RouteConfigLoadEnd) {
        asyncLoadCount--;
      }
      this.isShowingRouteLoadIndicator = !!asyncLoadCount;
    });

    router.events.forEach((event) => {
      if (event instanceof NavigationStart) {
        // if (event["url"] == "/login") {
        if (event["url"] == "/autologin") {
          this.showHead = false;
        } else {
          // console.log("NU")
          this.showHead = true;
        }
      }
    });
  }


  ngOnInit() {

    if (window.location.pathname.indexOf('external') > -1) {
      return;
    }

    this.oidcSecurityService
      .checkAuth()
      .subscribe((loginResponse: LoginResponse) => {
        this.isAuthenticated = loginResponse.isAuthenticated;

        if (loginResponse.userData)
          this.userName = loginResponse.userData.name;
        if (this.isAuthenticated) {
          this.authService.getuseInfo().subscribe((env_data) => {
            
            const redirectUrl = localStorage.getItem('redirectUrl');
            localStorage.removeItem('redirectUrl');
            if (redirectUrl && redirectUrl.length > 0 && redirectUrl != "/") {
              this.router.navigateByUrl(redirectUrl);
              //   window.location.href = redirectUrl;
            }
            else {
              if (window.location.pathname == "/") {
                if (!env_data.isSystemAgent) {
                  this.router.navigate(["agent-dashboard"])
                }
                else {
                  this.router.navigate(["dashboard"])
                }
              }
            }
            window.sessionStorage.setItem("isAuthorized", "true");
            this.isLogin = true;
          });
        }
        else {
          if (
            '/intermediate' !== window.location.pathname &&
            '/autologin' !== window.location.pathname &&
            window.location.search !== undefined &&
            window.location.search !== null &&
            window.location.search !== ''
          ) {
            localStorage.setItem('redirectUrl', window.location.pathname + window.location.search);

          } else if ('/intermediate' !== window.location.pathname &&
            '/autologin' !== window.location.pathname) {
            localStorage.setItem('redirectUrl', window.location.pathname);
          }


          this.router.navigate(['/autologin']);
        }
      })
    console.log(this.isLogin);
  }

  ngOnDestroy() {
    this.authService.ngOnDestroy();
  }
}
