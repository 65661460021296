import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-edit-policy-quote',
  templateUrl: './edit-policy-quote.component.html',
  styleUrls: ['./edit-policy-quote.component.scss']
})
export class EditPolicyQuoteComponent {
  constructor(
    private activeModal: NgbActiveModal
  ) { }

  closeDialog() {
    this.activeModal.close(false);
  }
}
