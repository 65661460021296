import { Component } from '@angular/core';

@Component({
  selector: 'app-broker-reconciliation',
  templateUrl: './broker-reconciliation.component.html',
  styleUrls: ['./broker-reconciliation.component.scss']
})
export class BrokerReconciliationComponent {
  public gridData = [
    {
      transId: '500164',
      transDate: "09-08-2023",
      policyId: 'CLE-140170',
      type: 'NB',
      insured: 'RiRe Management Ltd',
      gross: '€55.85',
      commission: '€5.85',
      dueToOptis: '€15.85',
      pay: '',
      writeoff: '',
    },
    {
      transId: '500164',
      transDate: "09-08-2023",
      policyId: 'CLE-140170',
      type: 'NB',
      insured: 'RiRe Management Ltd',
      gross: '€55.85',
      commission: '€5.85',
      dueToOptis: '€15.85',
      pay: '',
      writeoff: '',
    },
    {
      transId: '500164',
      transDate: "09-08-2023",
      policyId: 'CLE-140170',
      type: 'NB',
      insured: 'RiRe Management Ltd',
      gross: '€55.85',
      commission: '€5.85',
      dueToOptis: '€15.85',
      pay: '',
      writeoff: '',
    },
    {
      transId: '500164',
      transDate: "09-08-2023",
      policyId: 'CLE-140170',
      type: 'NB',
      insured: 'RiRe Management Ltd',
      gross: '€55.85',
      commission: '€5.85',
      dueToOptis: '€15.85',
      pay: '',
      writeoff: '',
    },
    {
      transId: '500164',
      transDate: "09-08-2023",
      policyId: 'CLE-140170',
      type: 'NB',
      insured: 'RiRe Management Ltd',
      gross: '€55.85',
      commission: '€5.85',
      dueToOptis: '€15.85',
      pay: '',
      writeoff: '',
    },
  ];
}
