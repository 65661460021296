<div class="access-denied">
    <div class="card card-position py-4">
      <div class="card-body">
        <div class="text-center">
          <div class="text-center">
              <span class="material-symbols-outlined"> do_not_disturb </span>
          </div>
          <h1>Access denied</h1>
          <div class="text-center col-md-12 fs-18 mb-4">
            Sorry, you are not authorized to access this portal.
          </div>
        </div>
      </div>
    </div>
  </div>
  