<div class="card card-position py-4">
    <div class="card-body">
        <div class="row justify-content-center">
            <div class="text-center col-md-12 fs-18 mb-4">
                Please wait we are processing your request
            </div>
            <div class="col-md-auto col-auto m-pl-25 fs-17 font-weight-semibold">
                <img  src="/assets/images/loader.svg" style="width: 90px;">
                
            </div>
        </div>
    </div>
</div>