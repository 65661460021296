import { Component } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-about-insurer',
  templateUrl: './about-insurer.component.html',
  styleUrls: ['./about-insurer.component.scss']
})
export class AboutInsurerComponent {
  constructor(
    private activeModal: NgbActiveModal, private modalService: NgbModal
  ) { }


  closeDialog() {
    this.activeModal.close(false);
  }
}
