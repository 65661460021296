import { Injectable, Injector } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
  HttpErrorResponse,
  HttpHandler,
  HttpEvent,
} from '@angular/common/http';
import {
  OidcSecurityService,
  OpenIdConfiguration,
} from 'angular-auth-oidc-client';

import { tap, catchError, finalize } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '../../../auth/auth.service';
import { Observable } from 'rxjs';
import { LoaderService } from '@shared/utility/loader.service';
import { ToasterService } from '@shared/utility/toaster.service';

@Injectable()
export class HttpResponseInterceptor implements HttpInterceptor {
  token: string = '';
  private requests: HttpRequest<any>[] = [];
  constructor(
    private injector: Injector,
    private loaderService: LoaderService,
    public oidcSecurityService: OidcSecurityService,
    private toastrService: ToasterService,
    private authService: AuthService
  ) { }

  removeRequest(req: HttpRequest<any>) {
    const i = this.requests.indexOf(req);
    if (i >= 0) {
      this.requests.splice(i, 1);
    }
    this.loaderService.isLoading.next(this.requests.length > 0);
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // add a custom header
    request.headers.set('Accept', 'application/json');
    const oidcSecurityService = this.injector.get(OidcSecurityService);

    if (
      request.url.indexOf('connect/token') > -1 ||
      request.url.indexOf('connect/userinfo') > -1 ||
      request.url.indexOf('sockjs-node') > -1 ||
      request.url.indexOf('openid-configuration/jwks') > -1
    ) {
      this.loaderService.isLoading.next(false);
    } else if (request.url.indexOf('noloaded=true') === -1) {
      this.loaderService.isLoading.next(true);
      this.requests.push(request);
    }
    var req;
    if (
      request.url.indexOf('amazon') == -1 &&
      request.url.indexOf('amazon') == -1 &&
      request.url.indexOf('/connect/token') == -1 &&
      request.url.indexOf('/connect/userinfo') == -1
    ) {
      oidcSecurityService.getAccessToken().subscribe((access: any) => {
        // req = request.clone({
        //   setHeaders: { Authorization: 'Bearer ' + data, systemId: '4' },
        // });


        req = request.clone({
          setHeaders: {
            Authorization: 'Bearer ' + access,
            systemId: '4',
            // idvalue: data,
          },
        });


      });
    } else {
      req = request.clone();
    }

    // pass on the modified request object
    return next.handle(req).pipe(
      tap((ev: HttpEvent<any>) => {
        if (ev instanceof HttpResponse) {
          if (request.url.indexOf('noloaded=true') === -1) {
            this.removeRequest(request);
          }
        }
      }),
      catchError((error: HttpErrorResponse) => {
        switch ((<HttpErrorResponse>error).status) {

          // case 400:
          //   return this.handle400Error(error);
          case 401:
            // return this.handle401Error(request, next);
            this.logout();
            return observableThrowError(null);
          case 422:
            return this.handle422Error(error);
          case 500:
            return this.handle500Error(error);
          default:
            throw error;
        }


      }),

      // error => {
      //   ;
      //   if (error instanceof HttpErrorResponse) {
      //     console.log(error);
      //     switch ((<HttpErrorResponse>error).status) {

      //                   // case 400:
      //                   //   return this.handle400Error(error);
      //                   // case 401:
      //                   //   return this.handle401Error(request, next);
      //                   case 422:
      //                     return this.handle422Error(error);
      //                   // case 500:
      //                   //   return this.handle500Error(error);
      //                   default:
      //                     return observableThrowError(error);
      //                 }

      //     } 
      //   },
      finalize(() => {
        //console.log('enter final');
        if (request.url.indexOf('noloaded=true') === -1) {
          this.removeRequest(request);
        }
      })
    );
  }

  handle422Error(error) {
    if (
      error &&
      error.status === 422
    ) {
      this.toastrService.warning(error.error.Value.Message[0]);
      return observableThrowError(error);
    }
  }

  public logout() {
    this.authService.localLogout();
    this.oidcSecurityService.logoff().subscribe();
  }

  handle500Error(error) {
    if (error && error.status === 500) {
      this.toastrService.error(error.error.message);
      return observableThrowError(error);
    }
  }
}


function observableThrowError(error: Observable<HttpEvent<any>> & HttpErrorResponse): Observable<HttpEvent<any>> {
  throw new Error('Function not implemented.');
}
// import { Injectable, Injector } from '@angular/core';
// import {
//   HttpInterceptor,
//   HttpRequest,
//   HttpResponse,
//   HttpErrorResponse,
//   HttpHandler,
//   HttpSentEvent,
//   HttpHeaderResponse,
//   HttpProgressEvent,
//   HttpUserEvent,
//   HttpEvent
// } from '@angular/common/http';

// import {
//   throwError as observableThrowError,
//   Observable,
//   BehaviorSubject
// } from 'rxjs';
// import {
//   take,
//   filter,
//   catchError,
//   switchMap,
//   finalize,
//   tap
// } from 'rxjs/operators';
// import { AuthService } from '@app/auth/auth.service';
// import { LoaderService } from '@shared/utility/loader.service';
// import { ToasterService } from '@shared/utility/toaster.service';
// import { Router } from '@angular/router';
// import { OidcSecurityService } from 'angular-auth-oidc-client';

// @Injectable()
// export class HttpResponseInterceptor implements HttpInterceptor {
//   isRefreshingToken = false;
//   tokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);
//   private requests: HttpRequest<any>[] = [];

//   constructor(
//     private injector: Injector,
//     private loaderService: LoaderService,
//     private toastrService: ToasterService,
//     private router: Router
//   ) { }

//   removeRequest(req: HttpRequest<any>) {
//     const i = this.requests.indexOf(req);
//     if (i >= 0) {
//       this.requests.splice(i, 1);
//     }
//     this.loaderService.isLoading.next(this.requests.length > 0);
//   }

//   addToken(req: HttpRequest<any>, token: string): HttpRequest<any> {
//     return req.clone({ setHeaders: { Authorization: 'Bearer ' + token } });
//   }

//   intercept(
//     request: HttpRequest<any>,
//     next: HttpHandler
//   ): Observable<
//     | HttpSentEvent
//     | HttpHeaderResponse
//     | HttpProgressEvent
//     | HttpResponse<any>
//     | HttpUserEvent<any>
//   > {
//     const oidcSecurityService = this.injector.get(OidcSecurityService);
//     const authService = this.injector.get(AuthService);
//     if (request.url.indexOf('noloaded=true') === -1) {
//       this.loaderService.isLoading.next(true);
//       this.requests.push(request);
//     }

//     return next.handle(this.addToken(request, oidcSecurityService.getIdToken())).pipe(
//       tap((ev: HttpEvent<any>) => {
//         if (ev instanceof HttpResponse) {
//           this.removeRequest(request);
//           // console.log('processing response', ev);
//         }
//       }),
//       catchError(error => {
//         this.removeRequest(request);
//         if (error instanceof HttpErrorResponse) {
//           switch ((<HttpErrorResponse>error).status) {
//             case 400:
//               return this.handle400Error(error);
//             // case 401:
//             //   return this.handle401Error(request, next);
//             case 422:
//               return this.handle422Error(error);
//             case 500:
//               return this.handle500Error(error);
//             default:
//               return observableThrowError(error);
//           }
//         } else {
//           this.toastrService.error(error.statusText);
//           return observableThrowError(error);
//         }
//       })
//     );
//   }

//   handle400Error(error) {
//     if (error && error.status === 400) {
//       this.toastrService.error(error.error.title);
//       return observableThrowError(error);
//     }
//   }

//   handle500Error(error) {
//     if (error && error.status === 500) {
//       this.toastrService.error(error.error.message);
//       return observableThrowError(error);
//     }
//   }

//   handle422Error(error) {
//     if (
//       error &&
//       error.status === 422
//     ) {
//       this.toastrService.error(error.error.Value.Message[0]);
//       return observableThrowError(error);
//     }
//   }

//   handle401Error(req: HttpRequest<any>, next: HttpHandler) {
//     if (!this.isRefreshingToken) {
//       this.isRefreshingToken = true;

//       // Reset here so that the following requests wait until the token
//       // comes back from the refreshToken call.
//       this.tokenSubject.next(null);

//       const authService = this.injector.get(AuthService);
//       authService.collectFailedRequest(req);

//       return authService.refreshToken().pipe(
//         switchMap((newToken: any) => {
//           if (newToken) {
//             this.tokenSubject.next(newToken.accessToken);
//             return next.handle(this.addToken(req, newToken.accessToken));
//           }
//           // If we don't get a new token, we are in trouble so logout.
//           return this.logoutUser();
//         }),
//         catchError(() => {
//           // If there is an exception calling 'refreshToken', bad news so logout.
//           return this.logoutUser();
//         }),
//         finalize(() => {
//           this.isRefreshingToken = false;
//         })
//       );
//     } else {
//       return this.tokenSubject.pipe(
//         filter(token => token != null),
//         take(1),
//         switchMap(token => {
//           return next.handle(this.addToken(req, token));
//         })
//       );
//     }
//   }
//   logoutUser() {
//     const authService = this.injector.get(AuthService);
//     authService.localLogout();
//     this.loaderService.isLoading.next(false);
//     this.router.navigateByUrl('/');
//     return observableThrowError('');
//   }
// }
